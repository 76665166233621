import React, { FormEvent } from 'react';
import AppContext from './AppContext';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { LovenseBasicSdk,LovenseSdkOptions,LovenseDevice,LovenseDeviceInfo,LovenseQrCodeInfo } from './lovenseSdkTypes';
import axios from 'axios';
import LovenseDeviceDisplay from './LovenseDeviceDisplay';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faBluetooth, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { ButtonGroup } from 'react-bootstrap';
import LovenseDeviceSelect from './Lovense';

export interface LovenseModalProps {
    closeCallback: () => void;
    onSelect: (device:LovenseDevice) => void;
    onChangeAllToysMode: (newMode:boolean) => void;
    language: string;
    producerID: string;
    pseudo: string;
    allToysMode: boolean;
}

interface LovenseModal {
    state: LovenseModalState;
    props: LovenseModalProps;
    closeCallback: () => void;
}

interface LovenseModalState {
    language: string;
    producerID: string;
    pseudo: string;
    devices: LovenseDevice[];
    initializing: boolean;
    fetching: boolean;
    lovenseAppConnected: boolean;
    logMessages: string[];
    toyOnline: boolean;
    qrCode: LovenseQrCodeInfo|null;
    deviceInfo: LovenseDeviceInfo|null;
    oldMode: boolean;
    allToysMode: boolean;
}

declare global {
    interface Window {
        lovenseSdk: LovenseBasicSdk;
        initializeLovense(authToken:string, uid:string): void;
    }
}

class LovenseModal extends React.Component {
    static contextType = AppContext;

    constructor(props:LovenseModalProps) {
        super(props);
        this.closeCallback = props.closeCallback;

        this.state = {
            language: props.language,
            producerID: props.producerID,
            pseudo: props.pseudo,
            devices: [],
            initializing: true,
            fetching: false,
            lovenseAppConnected: false,
            logMessages: [],
            toyOnline: false,
            qrCode: null,
            deviceInfo: null,
            oldMode: false,
            allToysMode: props.allToysMode
        }

        library.add(faSpinner,faArrowsRotate,faBluetooth);
    }

    componentDidMount() {
        console.log("LovenseModal componentDidMount -> " + typeof(window.initializeLovense));
        this.initializeLovenseApi();
    }

    async initializeLovenseApi() {
        console.log("LovenseModal initializeLovenseApi");

        const result = await axios.post(
            "https://api.lovense.com/api/basicApi/getToken",
            {
              token: "TSHQ4U+OdlXDIkMXtpkiXfhwGR4OqXVBpROBcz7ARVSN8nOYjC2aiBbSAfUXv9nO",
              uid: this.state.producerID,
              uname: this.state.pseudo
            }
        )

        console.log("LovenseModal initializeLovenseApi",result.data);

        if(result.data.code == 0) {
            console.log("Lovense authToken",result.data.data.authToken);
            window.initializeLovense(result.data.data.authToken,this.state.producerID);

            window.lovenseSdk.on('ready', this.lovenseSdkReady.bind(this));
            window.lovenseSdk.on('appStatusChange', this.appStatusChange.bind(this));
            window.lovenseSdk.on('toyInfoChange', this.toyInfoChange.bind(this));
            window.lovenseSdk.on('toyOnlineChange', this.toyOnlineChange.bind(this));
            window.lovenseSdk.on('deviceInfoChange', this.deviceInfoChange.bind(this));
        } else {
            console.log("Lovense error",result.data);
            alert("Lovense initialization failed: " + result.data.message);
        }
    }

    lovenseSdkReady = async(instance:LovenseBasicSdk) => {
        console.log("LovenseModal lovenseSdkReady");
        this.state.logMessages.push("lovenseSdkReady");
        this.setState({initializing: false});
        this.checkAppStatus();
    }

    appStatusChange(status:boolean) {
        console.log("LovenseModal appStatusChange",status);
        this.state.logMessages.push("appStatusChange: " + status);
        this.setState({lovenseAppConnected: status});
    }

    toyInfoChange(toyInfo:LovenseDevice[]) {
        this.state.logMessages.push("toyInfoChange: " + JSON.stringify(toyInfo));
        console.log("LovenseModal toyInfoChange",toyInfo);
        this.setState({devices: toyInfo});
    }

    toyOnlineChange(status:boolean) {
        this.state.logMessages.push("toyOnlineChange: " + status);
        console.log("LovenseModal toyOnlineChange",status);
        this.setState({toyOnline: status});
    }

    deviceInfoChange(device:LovenseDeviceInfo) {
        console.log("LovenseModal deviceInfoChange",device);
        this.setState({deviceInfo: device});
    }

    updateToys = async() => {
        this.setState({fetching: true});
        let myToys:LovenseDevice[] = await window.lovenseSdk.getOnlineToys();
        this.setState({fetching: false});
        console.log("LovenseModal updateToys",myToys);

        this.setState({devices: myToys});
    }

    getText(exp:string) {
        if(this.state.language === "de") {
            if(exp === "ControlAllToys") {
                return "Alle Toys gleichzeitig steuern";
            }
            if(exp === "ConnectLovenseSmartphone") {
                return "Du kannst Deine Lovense-Toys per iOS oder Android App verbinden, klicke dazu auf App verbinden und scanne den QR-Code in der Lovense-App auf Deinem Smartphone ein. Alternativ kannst Du Deine Toys auch mit Deinem PC verbinden, wenn Du ein Dongle und die Lovense Connect Softwar hast."
            }
            if(exp === "Select") {
                return "Wähle";
            }
            if(exp === "Connect Smartphone App") {
                return "iOS/Android App verbinden";
            }
            if(exp === "Lovense device detection") {
                return "Lovense Gerät ermitteln";
            }
            if(exp === "Discovering lovense devices via LAN") {
                return "Lovense Gerät per LAN-Anschluss ermitteln";
            }
            if(exp === "Discovering locally connected lovense devices") {
                return "Lokal angeschlossenes Lovense Gerät ermitteln";
            }
            if(exp === "Change device") {
                return "Gerät wechseln";
            }
            if(exp === "Locally Connected") {
                return "Lokal per Dongle";
            }
            if(exp === "Connected via LAN") {
                return "Suche im Netzwerk";
            }
            if(exp === "You need Lovense Connect for this to work") {
                return "Du benötigst die Lovense Connect Software und der Lush muss in der Lovense Connect Software als Connected angezeigt werden.";
            }
            if(exp === "Show Devices") {
                return "Geräte anzeigen";
            }
            if(exp === "Old methods to connect") {
                return "Falls Dein Lush nicht angezeigt wird, wenn Du auf den Button Geräte anzeigen klickst, kannst Du die Buttons unten versuchen um Dich zu verbinden."
            }
            if(exp === "Show old methods") {
                return "Mein Lush wird nicht angezeigt, wenn ich auf Geräte anzeigen klicke";
            }
            if(exp === "Use Smartphone App") {
                return "Smartphone App verwenden";
            }
            if(exp === "Old Mode") {
                return "Mit PC verbinden";
            }
            if(exp === "SetAllToysMode") {
                return "Alle Toys gleichzeitig steuern";
            }

            return exp;
        } else {
            if(exp === "ControlAllToys") {
                return "Control all toys at once";
            }
            if(exp === "ConnectLovenseSmartphone") {
                return "You can connect your Lovense toys via iOS or Android app, click on Connect App and scan the QR code in the Lovense app on your smartphone. Alternatively, you can also connect your toys to your PC if you have a dongle and the Lovense Connect software."
            }
            if(exp === "Connect Smartphone App") {
                return "Connect iOS/Android App";
            }
            if(exp === "You need Lovense Connect for this to work") {
                return "You need the Lovense Connect software for this to work, and the Lush must be connected in the Lovense Connect software.";
            }
            if(exp === "Old methods to connect") {
                return "If your Lush is not shown when you click on the button Show Devices, you can try the buttons below to connect."
            }
            if(exp === "Show old methods") {
                return "My Lush is not shown when clicking on Show Devices";
            }
            if(exp === "Use Smartphone App") {
                return "Use Smartphone App";
            }
            if(exp === "Old Mode") {
                return "Use PC Software";
            }
            if(exp === "SetAllToysMode") {
                return "Control all toys at once";
            }

            return exp;
        }
    }

    selectLovenseDevice(device:LovenseDevice) {
        console.log("LovenseModal selectLovenseDevice",device);
        this.props.onSelect(device);
    }

    checkAppStatus = async() => {
        if(!this.state.initializing) {
            let isConnected:boolean = false;
            isConnected = await window.lovenseSdk.getAppStatus();
            console.log("LovenseModal checkAppStatus",isConnected);
            this.setState({lovenseAppConnected: !isConnected});
        }
    }

    toggleQrCode = async() => {
        if(this.state.qrCode) {
            this.setState({qrCode: null});
        } else {
            let myQrCode:LovenseQrCodeInfo|null = await window.lovenseSdk.getQrcode();
            this.setState({qrCode: myQrCode});
        }
    }

    switchAllToys = (event:FormEvent<HTMLInputElement>) => {
        console.log("LovenseModal switchAllToys",event.currentTarget.checked);
        this.setState({allToysMode: event.currentTarget.checked});
    }

    clickAllToysMode = () => {
        this.props.onChangeAllToysMode(this.state.allToysMode);
    }

    getDeviceInfo = async() => {
        let myDeviceInfo:LovenseDeviceInfo = await window.lovenseSdk.getDeviceInfo();
        console.log("LovenseModal getDeviceInfo",myDeviceInfo);
        this.setState({deviceInfo: myDeviceInfo});
        this.state.logMessages.push("deviceInfo: " + JSON.stringify(myDeviceInfo));
    }

    toggleOldMode = () => {
        this.setState({oldMode: !this.state.oldMode});
    }

    onSelectOldDevice(domain:string,httpPort:number,httpsPort:number,toyID:string) {
        console.log("LovenseModal onSelectOldDevice",domain,httpPort,httpsPort,toyID);
        this.props.onSelect({id: toyID,name: domain,nickname: toyID,connected: true,toyType: "old",fVersion: httpsPort,hVersion: "old",battery: 0 });
    }

    render() {
        return(
            <Modal show={true} onHide={this.closeCallback.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>Lovense</Modal.Title>
                </Modal.Header>
          
                { !this.state.oldMode ? 
                <Modal.Body>
                    <p>{this.getText("ConnectLovenseSmartphone")}</p>
                    { this.state.initializing || this.state.fetching ? <div><FontAwesomeIcon icon={["fas", "spinner"]} spin /> {this.getText("loading")}</div> : null }
                    <div id="lovenseQrCode">
                        {this.state.qrCode ? <img src={this.state.qrCode.qrcodeUrl} /> : null}
                    </div>
                    <p>
                        <ButtonGroup>
                            <Button variant={this.state.lovenseAppConnected ? "secondary" : "primary"} size="sm" disabled={this.state.initializing} onClick={this.toggleQrCode.bind(this)}>{this.getText("Connect Smartphone App")}</Button>
                            <Button variant="secondary" size="sm" onClick={this.toggleOldMode.bind(this)}>{this.getText("Old Mode")}</Button>
                        </ButtonGroup>
                    </p>
                    <div id="lovenseDeviceList">
                        <p>
                            Toys&nbsp;&nbsp;
                            <Button variant="secondary" size="sm" disabled={this.state.initializing || this.state.fetching} onClick={this.updateToys.bind(this)}><FontAwesomeIcon icon={["fas","arrows-rotate"]}></FontAwesomeIcon></Button>
                        </p>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>{this.getText("Type")}</th>
                                    <th>{this.getText("Name")}</th>
                                    <th>{this.getText("Status")}</th>
                                    <th>{this.getText("Battery")}</th>
                                    <th>{this.getText("Actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.devices.map((obj:LovenseDevice,index:number) => <LovenseDeviceDisplay key={obj.id} language={this.state.language} selectable={!this.state.allToysMode && this.state.lovenseAppConnected && this.state.toyOnline} selectCallback={this.selectLovenseDevice.bind(this)} device={obj} />)}
                            </tbody>
                        </table>
                        <p>
                            <Form>
                                <Form.Check disabled={!this.state.lovenseAppConnected} checked={this.state.allToysMode} id='formSwitchAllToys' type='switch' label={this.getText("ControlAllToys")} onChange={this.switchAllToys.bind(this)} />
                            </Form>
                            { this.state.allToysMode ? 
                                <ButtonGroup>
                                    <Button variant="primary" size="sm" disabled={!this.state.lovenseAppConnected || !this.state.toyOnline} onClick={this.clickAllToysMode.bind(this)}>{this.getText("SetAllToysMode")}</Button>
                                </ButtonGroup>
                            : null}
                        </p>
                    </div>
                    <div id="lovenseDeviceInfo">
                        {this.state.deviceInfo ? <p>
                            <table className='w-100'>
                                <tr>
                                    <th>Platform</th>
                                    <th>AppType</th>
                                    <th>Version</th>
                                    <th>App</th>
                                </tr>
                                <tr>
                                    <td>{this.state.deviceInfo.platform}</td>
                                    <td>{this.state.deviceInfo.appType}</td>
                                    <td>{this.state.deviceInfo.appVersion}</td>
                                    <td>
                                        {this.state.lovenseAppConnected ? <span className='oncircle'></span> : <span className='offcircle'></span>}
                                    </td>
                                </tr>
                            </table>
                        </p> : null}
                    </div>
                    <p style={{fontFamily: "monospace",height: "240px",fontSize: "10px",overflowY: "scroll"}}>
                        {this.state.logMessages.map((obj:string,index:number) => <div key={index}>{obj}</div>)}
                    </p>
                </Modal.Body> :
                <Modal.Body>
                    <LovenseDeviceSelect choice="LAN" language={this.state.language} producerID={this.state.producerID} pseudo={this.state.pseudo} onSelect={this.onSelectOldDevice.bind(this)} />
                    <p>
                        <ButtonGroup>
                            <Button variant="secondary" size="sm" onClick={this.toggleOldMode.bind(this)}>{this.getText("Use Smartphone App")}</Button>
                        </ButtonGroup>
                    </p>
                </Modal.Body> }
            
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={this.closeCallback.bind(this)}>Close</Button>
                </Modal.Footer>
          </Modal>
        )
    }
}

export default LovenseModal;