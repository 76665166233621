import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ModularPlayer from './ModularPlayer';
import ModularEncoder from './ModularEncoder';
import ChatOptionsModal from './ChatOptionsModal';
import ChatOfflineModal from './ChatOfflineModal';
import UserNotesModal from './UserNotesModal';
import FeatureRequest from './FeatureRequest.js';
import ChatMessage from './ChatMessage.js';
import { LocalStorageWorker } from './StorageHelper';
import DildoPortSelect from './DildoControl.js';
import UserListEntry from './UserListEntry';
import AppContext from './AppContext';
import io from 'socket.io-client';
import Cam2CamVideo from './Cam2CamVideo';
import Cam2CamVideoSoup from './Cam2CamVideoSoup';
import Cam2CamVideoLively from './Cam2CamVideoLively';
import Cam2CamVideoSoupCentral from './Cam2CamVideoSoupCentral';
import UserMicAudio from './UserMicAudio.js';
import UserMicAudioSoup from './UserMicAudioSoup';
import UserMicAudioLively from './UserMicAudioLively';
import UserMicAudioSoupCentral from './UserMicAudioSoupCentral';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faText,faTextHeight,faArrowSquareUp,faArrowSquareDown,faArrowSquareLeft,faArrowSquareRight,faExchange,faEye,faCog,faSpinner,faHandPointRight,faUserPlus,faUserMinus } from '@fortawesome/pro-solid-svg-icons';
import {guppySoupClient} from './guppySoupTypes';
import {
    VideoClient,
    EncoderUiState,
    LivelyCallContext,
    PlayerUiState,
    types
  } from "@livelyvideo/video-client-web";
import { AxiosResponse } from 'axios';
import TestMicButtonWithErrorBoundary from '@livelyvideo/video-client-web/lib/ui/components/ModularComponents/Buttons/Encoder/TestMic/TestMic';
import { LoggerCore } from "@livelyvideo/log-client";
import { Device } from 'mediasoup-client';
import { types as soupTypes } from 'mediasoup-client';
import SignalingInterface, { SyncResponse } from './SignalingInterface'; // Our own signaling stuff.
import LovenseModal from './LovenseModal';
import { LovenseBasicSdk,LovenseSdkOptions,LovenseDevice } from './lovenseSdkTypes';

const globSoftwareVersion = "4.9.12";

interface ChatInterfaceProps {
    gotoOptions: () => void;
    exitChat: () => void;
}
interface usersOnSoupInfo {
    usersOnSoup1: number,
    usersOnSoup2: number,
    usersOnSoup3: number
}
interface PingDictionary {
    [index: string]:Date;
}
interface chatInterface {
    state: chatInterfaceState;
    props: ChatInterfaceProps;
    vc:VideoClient;
    socket: SocketIOClient.Socket|null;
    producerID: string;
    doReconnect: boolean;
    userPings: PingDictionary;
    dildoPort: any|null;
    chatTargetClient: userInfo|null;
    videoMirrored: boolean;
    Client: guppySoupClient|null;
    backupDevice: Device|null;
    cam2camDevice1: Device;
    cam2camDevice2: Device;
    sendTransport: soupTypes.Transport|null;
    recvTransport1: soupTypes.Transport|null;
    recvTransport2: soupTypes.Transport|null;
    backupPeerId: string;
    cam2camPeerId1: string;
    cam2camPeerId2: string;
    createCam2CamSoup1Planned: boolean;
    createCam2CamSoup2Planned: boolean;
    mySignaling: SignalingInterface;
    cam2camSignaling1: SignalingInterface;
    cam2camSignaling2: SignalingInterface;
    videoProducer: soupTypes.Producer|null;
    audioProducer: soupTypes.Producer|null;
    syncTimer:NodeJS.Timer;
    cam2camSyncTimer1:NodeJS.Timer;
    cam2camSyncTimer2:NodeJS.Timer;
    chatInputFocus(): void;
    sendChatText(): void;
    getText(exp:string): string;
    numCam2CamVideos: 0;
    showDebugMessages: boolean;
    videoCall: types.CallAPI | null;
    encoderUi: EncoderUiState | null;
    chatInput: React.RefObject<HTMLInputElement>|null;
    chatMessageContainerRef: React.RefObject<HTMLDivElement>|null;
    dildoBarRef: React.RefObject<HTMLDivElement>|null;
    lovenseBarRef: React.RefObject<HTMLDivElement>|null;
    gotoOptions(): void;
    exitChat(): void;
    testcounter: number;
    testuserguids: string[];
    globalUserCounter: number;
    chatMessageCounter: number;
    cam2camInfoCounter: number;
    userMicCounter: number;
    featureRequestCounter: number;
    cam2camInfoGuids: cam2CamInfoGuidParams[];
    userMicInfoGuids: userMicInfoGuidParams[];
    checkBroadcastTimer:NodeJS.Timer;
    problemWithBroadcastCounter:number;
    logger:LoggerCore;
    callIdLogCounter:number;
    broadcastLogID:number;
    checkSoupConnectionsTimer:NodeJS.Timer;
    checkSoupConnectionsTimerStarted:boolean;
    backupStreamPaused:boolean;
    consecutiveNoUsers:number;
    allowBroadcastRestart:boolean;
}
interface chatInterfaceState {
    stagingMode: boolean;
    mainBackgroundColor: string,
    mainTextColor: string,
    chatBackgroundColor: string,
    producerID: string,
    pseudo: string,
    chatTextColor: string,
    userListBackgroundColor: string,
    userListTextColor: string,
    sysMessageColor: string,
    featureMessageColor: string,
    enterColor: string,
    leaveColor: string,
    chatFromGirlColor: string,
    chatFromUserColor1: string,
    chatFromUserColor2: string,
    chatFromUserColor3: string,
    featureRequestColor: string,
    featureAcceptedColor: string,
    featureDeniedColor: string,
    encoderDevicesSelected: boolean,
    encoderDevicesSelectedOnce: boolean,
    videoPaneCols: number,
    userListHeight: number,
    chatFontSize: number,
    usersFontSize: number,
    chatTargetPseudo: string,
    currentChatMessage: string,
    chatMessages: chatMessageStateObject[],
    debugMessages: string[],
    sysMessages: string[],
    socketConnecting: boolean,
    pauseMode: boolean,
    socketConnected: boolean,
    chatConnected: boolean,
    canWrite: boolean,
    showDebug: boolean,
    featureRequests: any[],
    users: userInfo[],
    chatPrice: number,
    welcomeMsg: string,
    soundEnabled: boolean,
    dildoActive: boolean,
    dildoPortOpen: boolean,
    lovenseActive: boolean,
    privateSessionEnabled: boolean,
    multiPrivateEnabled: boolean,
    soundPrice: number,
    flashChatSessionID: string,
    dildoSelectOpen: boolean,
    lovenseSelectOpen: boolean,
    cam2CamInfos: cam2CamInfo[],
    cam2CamInfosSoup: cam2CamInfo[],
    cam2CamInfosSoupCentral1: cam2CamInfo[],
    cam2CamInfosSoupCentral2: cam2CamInfo[],
    cam2CamInfosLively: cam2CamInfo[],
    userMicInfos: userMicInfo[],
    userMicInfosSoup: userMicInfo[],
    userMicInfosLively:userMicInfo[],
    userMicInfosSoupCentral1: userMicInfo[],
    userMicInfosSoupCentral2: userMicInfo[],
    language: string,
    isElectron: boolean,
    showAutoTexts: boolean,
    lovenseEnabled: boolean,
    dildoEnabled: boolean,
    dildoPorts: any[],
    broadcasting: boolean,
    resolutionWidth: number,
    resolutionHeight: number,
    videoDeviceId: string,
    callId: string,
    dildoTestRunning: boolean,
    lovenseTestRunning: boolean,
    manifestUrl: string,
    showOwnPlayer: boolean,
    manifestCheckState: string,
    manifestFailCount: number,
    manifestChecking: boolean,
    manifestCheckSuccess: boolean,
    manifestCheckFailed: boolean,
    autoConnectOnStreamSuccess: boolean,
    voyeurInfoText: string,
    userNoteGuid: string,
    userNotePseudo: string,
    chatOptionsModal: boolean,
    chatOfflineModal: boolean,
    soup1Initialized: boolean,
    soup1Joined: boolean,
    soup2Initialized: boolean,
    soup2Joined: boolean,
    soup3Initialized: boolean,
    soup3Joined: boolean,
    soup1VideoProducing: boolean,
    soup1AudioProducing: boolean,
    backSoupTransportConnState: string,
    cam2camTransportConnState1: string,
    cam2camTransportConnState2: string,
    chatServer: string,
    restartEncoder: boolean,
    broadcastFailed: boolean,
    girlDisconnected: boolean,
    lovenseDevices: LovenseDevice[],
    primaryLovenseDevice: LovenseDevice|null,
    lovenseAllToysMode: boolean
}
interface chatMessageStateObject {
    id: number,
    fromPart: string,
    className: string,
    messageClass: string,
    msg: string,
    msgPart: string
}
interface userInfoFromChatserver {
    guid: string,
    pseudo: string,
    userType: string,
    isFromCMS: boolean,
    isInvisible: boolean
}
interface userInfo {
    id: number,
    guid: string,
    isInvisible: boolean,
    userType: string,
    pseudo: string,
    isFromCMS: boolean,
    lovense: boolean,
    sound: boolean,
    dildo: boolean,
    userMic: boolean,
    privateSession: boolean,
    cam2cam: boolean,
    multiPrivate: boolean,
    updateNotes: boolean
}
interface cam2CamInfo {
    id: number,
    guid: string,
    flashChatSessionID: string,
    pseudo: string,
    streamType: string,
    playerUI:PlayerUiState|null,
    orderIndex:number,
    soupServer: string
}
interface cam2CamInfoParams {
    guid: string,
    flashChatSessionID: string,
    pseudo: string
}
interface userMicInfoParams {
    guid: string,
    flashChatSessionID: string,
    pseudo: string
}
interface cam2camInfoSoupParams {
    peerId: string;
    soupServer: string;
    flashChatSessionID: string,
    pseudo: string
}
interface userMicInfoSoupParams {
    peerId: string;
    soupServer: string;
    flashChatSessionID: string,
    pseudo: string
}
interface userMicInfoGuidParams {
    guid: string,
    flashChatSessionID: string,
    pseudo: string
}
interface cam2CamInfoGuidParams {
    guid: string,
    flashChatSessionID: string,
    pseudo: string
}
interface cam2CamInfoLivelyParams {
    callid: string,
    flashChatSessionID: string,
    pseudo: string
}
interface userMicInfo {
    guid: string,
    id: number,
    flashChatSessionID: string,
    pseudo: string,
    playerUI:PlayerUiState|null,
    soupServer: string
}
declare global {
    interface Window { 
        Client: guppySoupClient;
        lovenseSdk: LovenseBasicSdk;
    }
}
interface chatMessage {
    from: string,
    to: string,
    msg: string
}
interface LoginData {
    producerID:string;
}
interface ChatOptionsData {
    // todo
}

var ipcRenderer:any = null;

class chatInterface extends React.Component {
    static contextType = AppContext;

    constructor(props:ChatInterfaceProps) {
        super(props);

        let stagingMode:boolean = false;

        if(this.context) {
            if(this.context.chatOptions) {
                stagingMode = this.context.chatOptions.stagingMode;
            }
        }

        let myProducerID:string = "";
        let myPseudo:string = "";

        if(this.context) {
            if(this.context.loginData) {
                myProducerID = this.context.loginData.producerID;
                myPseudo = this.context.loginData.pseudo;
            }
        }

        this.state = {
            stagingMode: stagingMode,
            mainBackgroundColor: "#000",
            mainTextColor: "#fff",
            chatBackgroundColor: "#222",
            producerID: myProducerID,
            pseudo: myPseudo,
            chatTextColor: "#fff",
            userListBackgroundColor: "#222",
            userListTextColor: "#fff",
            sysMessageColor: "lightsteelblue",
            featureMessageColor: "azure",
            enterColor: "greenyellow",
            leaveColor: "peachpuff",
            chatFromGirlColor: "lightsteelblue",
            chatFromUserColor1: "#CF5C36",
            chatFromUserColor2: "#EFC88B",
            chatFromUserColor3: "#EEE5E9",
            featureRequestColor: "lightpink",
            featureAcceptedColor: "lightgreen",
            featureDeniedColor: "lightred",
            encoderDevicesSelected: false,
            encoderDevicesSelectedOnce: false,
            videoPaneCols: 8,
            userListHeight: 40,
            chatFontSize: 16,
            usersFontSize: 16,
            chatTargetPseudo: '',
            currentChatMessage: '',
            chatMessages: [],
            debugMessages: [],
            sysMessages: [],
            socketConnecting: false,
            pauseMode: false,
            socketConnected: false,
            chatConnected: false,
            canWrite: false,
            showDebug: false,
            featureRequests: [],
            users: [],
            chatPrice: 0,
            welcomeMsg: '',
            soundEnabled: false,
            dildoActive: false,
            dildoPortOpen: false,
            lovenseActive: false,
            privateSessionEnabled: false,
            multiPrivateEnabled: false,
            soundPrice: 0,
            flashChatSessionID: '',
            dildoSelectOpen: false,
            lovenseSelectOpen: false,
            cam2CamInfos: [],
            cam2CamInfosSoup: [],
            cam2CamInfosSoupCentral1: [],
            cam2CamInfosSoupCentral2: [],
            cam2CamInfosLively: [],
            userMicInfos: [],
            userMicInfosSoup: [],
            userMicInfosSoupCentral1: [],
            userMicInfosSoupCentral2: [],
            userMicInfosLively: [],
            language: 'en',
            isElectron: false,
            showAutoTexts: false,
            lovenseEnabled: false,
            dildoEnabled: false,
            dildoPorts: [],
            broadcasting: false,
            videoDeviceId: '',
            callId: '',
            dildoTestRunning: false,
            lovenseTestRunning: false,
            resolutionWidth: 0,
            resolutionHeight: 0,
            manifestUrl: "",
            showOwnPlayer: false,
            manifestCheckState: "",
            manifestFailCount: 0,
            manifestChecking: false,
            manifestCheckSuccess: false,
            manifestCheckFailed: false,
            autoConnectOnStreamSuccess: false,
            voyeurInfoText: "",
            userNotePseudo: "",
            userNoteGuid: "",
            chatOptionsModal: false,
            chatOfflineModal: false,
            soup1Initialized: false,
            soup1Joined: false,
            soup2Initialized: false,
            soup2Joined: false,
            soup3Initialized: false,
            soup3Joined: false,
            soup1VideoProducing: false,
            soup1AudioProducing: false,
            backSoupTransportConnState: "null",
            cam2camTransportConnState1: "null",
            cam2camTransportConnState2: "null",
            chatServer: "",
            restartEncoder: false,
            broadcastFailed: false,
            girlDisconnected: false,
            lovenseDevices: [],
            primaryLovenseDevice: null,
            lovenseAllToysMode: false
        }

        library.add(faText,faTextHeight,faArrowSquareUp,faArrowSquareDown,faArrowSquareLeft,faArrowSquareRight,faExchange,faEye,faSpinner,faHandPointRight,faUserPlus,faUserMinus);

        this.socket = null;
        this.doReconnect = false;
        this.userPings = {};
        this.dildoPort = null;
        this.chatTargetClient = null;
        this.videoMirrored = false;

        this.Client = window.Client;

        this.chatInput = React.createRef();
        this.chatInputFocus = this.chatInputFocus.bind(this);
        this.sendChatText = this.sendChatText.bind(this);
        this.numCam2CamVideos = 0;

        this.chatMessageContainerRef = React.createRef();
        this.dildoBarRef = React.createRef();
        this.lovenseBarRef = React.createRef();

        this.gotoOptions = props.gotoOptions;
        this.exitChat = props.exitChat;

        this.testcounter = 0;
        this.testuserguids = [];

        this.globalUserCounter = 0;
        this.chatMessageCounter = 0;
        this.cam2camInfoCounter = 0;
        this.userMicCounter = 0;
        this.featureRequestCounter = 0;
        this.cam2camInfoGuids = new Array();
        this.userMicInfoGuids = new Array();
        this.problemWithBroadcastCounter = 0;
        this.logger = new LoggerCore("guppysender4");
        this.callIdLogCounter = 0;
        this.broadcastLogID = 0;
        this.checkSoupConnectionsTimerStarted = false;
        this.consecutiveNoUsers = 0;
        this.cam2camPeerId1 = "";
        this.cam2camPeerId2 = "";
        this.createCam2CamSoup1Planned = false;
        this.createCam2CamSoup2Planned = false;
        this.allowBroadcastRestart = false;
        this.backupDevice = null;

        document.title = "Live-Strip.com Sendersoftware " + globSoftwareVersion;
    }

    sendVideoDeviceId(newDeviceId:string) {
        this.setState({videoDeviceId: newDeviceId});
        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
        myLocalStorage.add("videoDeviceId",newDeviceId);
    }

    getUserInfos() {
        //this.chatMessage("get-userinfos","chat-sysmessage");

        if(this.socket) {
            if(this.socket.connected) {
                this.socket.emit("get-userinfos");
            }
        }
    }

    onVCPlayerAdded(event:types.VideoClientEvents["playerAdded"]) {
        //Notice that we are auto accepting and adding the player here. This is where you would want to add any accept/reject logic if you do not want it to auto accept.
        const newPlayer:PlayerUiState = new PlayerUiState(event.player);
        let peer:types.BasePeer = event.peer;
        let myPseudo:string = "";
        let myFlashChatSessionID:string = "";
        let streamFeature:string = "";

        /*if(event.peer !== null) {
            this.chatMessage("playerAdded event streamName: " + event.streamName + " | peerId: " + event.peer.peerId,"chat-sysmessage");
        } else {
            this.chatMessage("playerAdded event streamName: " + event.streamName,"chat-sysmessage");
        }*/

        for(let curInfo of this.cam2camInfoGuids) {
            //this.chatMessage("comparing to c2c guid: " + curInfo.guid + " | " + event.streamName,"chat-sysmessage");
            if(curInfo.guid === event.streamName) {
                myPseudo = curInfo.pseudo;
                myFlashChatSessionID = curInfo.flashChatSessionID;
                streamFeature = "cam2cam";
                break;
            }
        }

        if(myPseudo === "") {
            for(let curInfo of this.userMicInfoGuids) {
                //this.chatMessage("comparing to usermic guid: " + curInfo.guid + " | " + event.streamName,"chat-sysmessage");
                if(curInfo.guid === event.streamName) {
                    myPseudo = curInfo.pseudo;
                    myFlashChatSessionID = curInfo.flashChatSessionID;
                    streamFeature = "usermic";
                    break;
                }
            }
        }

        //this.chatMessage("playerAdded streamName: " + event.streamName + " | pseudo: " + myPseudo + " | streamType: " + streamType,"chat-sysmessage");
        this.sysMessage("playerAdded streamName: " + event.streamName + " | pseudo: " + myPseudo + " | streamFeature: " + streamFeature);

        if(streamFeature === "cam2cam") {
            let newCam2CamInfo:cam2CamInfo = {
                id:0,
                pseudo:myPseudo,
                guid:event.streamName,
                streamType:"lively",
                flashChatSessionID:myFlashChatSessionID,
                playerUI:newPlayer,
                orderIndex:this.getNextCam2CamOrderIndex(),
                soupServer: ""
            };
            this.addCam2CamInfoLively(newCam2CamInfo);
        } else if(streamFeature === "usermic") {
            let newUserMicInfo:userMicInfo = {
                id:0,
                pseudo:myPseudo,
                guid:event.streamName,
                flashChatSessionID:myFlashChatSessionID,
                playerUI:newPlayer,
                soupServer: ""
            };
            this.addUserMicInfoLively(newUserMicInfo);
        }
    }
    onVCPlayerRemoved(event:types.VideoClientEvents["playerRemoved"]) {
        let myPseudo:string = "";
        let myFlashChatSessionID:string = "";
        let streamFeature:string = "";

        for(let curInfo of this.cam2camInfoGuids) {
            if(curInfo.guid === event.streamName) {
                myPseudo = curInfo.pseudo;
                myFlashChatSessionID = curInfo.flashChatSessionID;
                streamFeature = "cam2cam";
                break;
            }
        }

        if(myPseudo === "") {
            for(let curInfo of this.userMicInfoGuids) {
                if(curInfo.guid === event.streamName) {
                    myPseudo = curInfo.pseudo;
                    myFlashChatSessionID = curInfo.flashChatSessionID;
                    streamFeature = "usermic";
                    break;
                }
            }
        }

        //this.chatMessage("playerRemoved streamName: " + event.streamName + " | pseudo: " + myPseudo + " | streamType: " + streamFeature,"chat-sysmessage");
        this.sysMessage("playerRemoved streamName: " + event.streamName + " | pseudo: " + myPseudo + " | streamType: " + streamFeature);

        if(streamFeature === "cam2cam") {
            this.removeCam2CamInfoLively(myFlashChatSessionID);
        } else {
            this.removeUserMicInfoLively(myFlashChatSessionID);
        }
    }
    onVCError(event:types.VideoClientEvents["error"]) {
        //this.chatMessage("VideoClient Error: " + event.message + "[" + event.code + "]","chat-sysmessage");
        this.logBroadcastAction("VideoClient Error: " + event.message + "[" + event.code + "]");
    }
    onVCCallClosed(event:types.VideoClientEvents["callClosed"]) {
        //this.chatMessage("VideoClient Call Closed","chat-sysmessage");
        this.logBroadcastAction("VideoClient Call Closed");
    }

    sendEncoderUI(encoderUi:EncoderUiState):void {
        this.encoderUi = encoderUi;
    }

    sendVideoClient(vc:VideoClient):void {
        this.vc = vc;
        //When a player is added ensure that we are setting this to our new playerUi
        this.vc.on("playerAdded", this.onVCPlayerAdded.bind(this));
        this.vc.on("playerRemoved", this.onVCPlayerRemoved.bind(this));
        this.vc.on("error", this.onVCError.bind(this));
        //this.vc.on("callClosed", this.onVCCallClosed.bind(this));
    }

    sendCallId(newCallId:string):void {
        console.log("received callId: " + newCallId);
        if(newCallId !== this.state.callId) {
            this.setState({callId: newCallId});
            this.setState({manifestUrl:""});
            this.setManifestState("Initializing Stream");
            this.setState({manifestChecking: true});
            this.setState({manifestCheckSuccess: false});
            this.setState({manifestCheckFailed: false});
            this.setState({manifestCheckCount: 0});
            //this.setState({autoConnectOnStreamSuccess: true});
            setTimeout(this.checkBroadcast.bind(this),2500);
            this.setCallIDOnServer(newCallId);
        }
    }

    askServerForAlreadyConnectedUsers() {
        if(this.socket) {
            if(this.socket.connected) {
                this.socket.emit("getAlreadyConnectedUsers");
            }
        }
    }

    setCallIDOnServer(callId:string) {
        if(this.socket) {
            if(this.socket.connected) {
                this.socket.emit("setCallID",callId);
                this.sendSysMessage("callId: " + callId);
            }
        }
    }

    sendSysMessage(msg:string) {
        if(this.socket) {
            if(this.socket.connected) {
                this.socket?.emit("message", {
                    msg: msg,
                    to: "system"
                });
            }
        }
    }

    sendChatText() {
        this.socket?.emit("message", {
            msg: this.state.currentChatMessage,
            to: this.state.chatTargetPseudo
        });
        this.setState({currentChatMessage: ''});
        this.chatInputFocus();
    }

    chatInputFocus() {
        if(this.chatInput) {
            if(this.chatInput.current) {
                this.chatInput.current.focus();
            }
        }
    }

    handleChatMessageKeyDown(event:any) {
        if(event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            if(this.state.currentChatMessage !== "") {
                this.sendChatText();
            } else {
                this.acceptFirstFeatureRequest();
            }
        }
        if(event.key === 'F1') {
            event.preventDefault();
            event.stopPropagation();
            this.acceptFirstFeatureRequest();
        }
        if(event.key === 'F2') {
            event.preventDefault();
            event.stopPropagation();
            this.denyFirstFeatureRequest();
        }
        if(event.key === 'Escape' && !this.state.showAutoTexts) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({
                chatTargetPseudo: ""
            });
            this.chatTargetClient = null;
        }
        if(event.key === 'ArrowUp' || event.key === 'Tab') {
            let array = [...this.state.users];
            let userCounter = -1;
            let selectedUserIndex = -1;

            for(let curUser of array) {
                if(curUser.userType !== "sender") {
                    userCounter++;
                    if(this.chatTargetClient !== null) {
                        if(curUser.guid === this.chatTargetClient.guid) {
                            selectedUserIndex = userCounter;
                        }
                    }
                }
            }

            if(userCounter === 0 && selectedUserIndex >= 0) {
                return;
            }
    
            if(selectedUserIndex === 0 || selectedUserIndex === -1) {
                // nimm den letzten User
                for(let curUser of array) {
                    if(curUser.userType !== "sender") {
                        this.chatTargetClient = curUser;
                    }
                }
                if(this.chatTargetClient !== null) {
                    this.setState({
                        chatTargetPseudo: this.chatTargetClient.pseudo
                    });
                }
            } else {
                userCounter = -1;
                for(let curUser of array) {
                    if(curUser.userType !== "sender") {
                        userCounter++;
                        if(userCounter === selectedUserIndex - 1) {
                            this.chatTargetClient = curUser;
                            break;
                        }
                    }
                }
                if(this.chatTargetClient !== null) {
                    this.setState({
                        chatTargetPseudo: this.chatTargetClient.pseudo
                    });
                }
            }
        }
        
        if(event.key === 'ArrowDown') {
            let array = [...this.state.users];
            let userCounter = -1;
            let selectedUserIndex = -1;

            for(let curUser of array) {
                if(curUser.userType !== "sender") {
                    userCounter++;
                    if(this.chatTargetClient !== null) {
                        if(curUser.guid === this.chatTargetClient.guid) {
                            selectedUserIndex = userCounter;
                        }
                    }
                }
            }

            if(userCounter === 0 && selectedUserIndex >= 0) {
                return;
            }
    
            if(selectedUserIndex === userCounter) {
                // nimm den ersten User
                for(let curUser of array) {
                    if(curUser.userType !== "sender") {
                        this.chatTargetClient = curUser;
                        break;
                    }
                }
                if(this.chatTargetClient !== null) {
                    this.setState({
                        chatTargetPseudo: this.chatTargetClient.pseudo
                    });
                }
            } else {
                userCounter = -1;
                for(let curUser of array) {
                    if(curUser.userType !== "sender") {
                        userCounter++;
                        if(userCounter === selectedUserIndex + 1) {
                            this.chatTargetClient = curUser;
                            break;
                        }
                    }
                }
                if(this.chatTargetClient !== null) {
                    this.setState({
                        chatTargetPseudo: this.chatTargetClient.pseudo
                    });
                }
            }
        }
        if(event.key === '#' && this.state.currentChatMessage === "") {
            event.preventDefault();
            event.stopPropagation();
            if(this.state.showAutoTexts) {
                this.setState({showAutoTexts: false});
            } else {
                this.setState({showAutoTexts: true});
            }
            return;
        }
        if(this.state.showAutoTexts) {
            if(event.key === '1') {
                event.preventDefault();
                event.stopPropagation();
                this.setState({currentChatMessage: this.context.chatOptions.autotext1});
                this.setState({showAutoTexts: false});
                return;
            }
            if(event.key === '2') {
                event.preventDefault();
                event.stopPropagation();
                this.setState({currentChatMessage: this.context.chatOptions.autotext2});
                this.setState({showAutoTexts: false});
                return;
            }
            if(event.key === '3') {
                event.preventDefault();
                event.stopPropagation();
                this.setState({currentChatMessage: this.context.chatOptions.autotext3});
                this.setState({showAutoTexts: false});
                return;
            }
            if(event.key === '4') {
                event.preventDefault();
                event.stopPropagation();
                this.setState({currentChatMessage: this.context.chatOptions.autotext4});
                this.setState({showAutoTexts: false});
                return;
            }
            if(event.key === '5') {
                event.preventDefault();
                event.stopPropagation();
                this.setState({currentChatMessage: this.context.chatOptions.autotext5});
                this.setState({showAutoTexts: false});
                return;
            }
            if(event.key === 'Escape') {
                event.preventDefault();
                event.stopPropagation();
                this.setState({showAutoTexts: false});
                return;
            }

            // wir versuchen den User mit dem Anfangscharacter zu finden
            event.preventDefault();
            event.stopPropagation();

            let array = [...this.state.users];
            let userCounter = -1;
            let selectedUserIndex = -1;

            for(let curUser of array) {
                if(curUser.userType !== "sender") {
                    userCounter++;
                    if(curUser.pseudo.substr(0,1).toLowerCase() === event.key.toString()) {
                        this.chatTargetClient = curUser;
                        break;
                    }
                }
            }

            if(this.chatTargetClient !== null) {
                this.setState({
                    chatTargetPseudo: this.chatTargetClient.pseudo
                });
                this.setState({showAutoTexts: false});
            }
        }
    }

    handleChatMessageChange(event:any) {
        this.setState({currentChatMessage: event.currentTarget.value});
    }

    componentDidMount() {
        if(this.context.loginData.pseudo.indexOf('Testsender') > -1) {
            this.setState({showDebug: true});
        }
        this.setState({language: this.context.language});
        if(typeof(window.require) !== "undefined") {
            this.setState({isElectron: true});
        } else {
            this.setState({isElectron: false});
        }
        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
        let myColorMode:string = myLocalStorage.get("colorMode");
        if(myColorMode == "gray") {
            this.switchToGrayMode();
        } else if(myColorMode == "dark") {
            this.switchToDarkMode();
        } else if(myColorMode == "white") {
            this.switchToWhiteMode();
        } else if(myColorMode == "sepia") {
            this.switchToSepiaMode();
        }
        let chatFontSize:string = myLocalStorage.get("chatFontSize");
        if(chatFontSize !== "" && chatFontSize !== null) {
            this.setState({chatFontSize: parseInt(chatFontSize)});
        }
        let userFontSize:string = myLocalStorage.get("userFontSize");
        if(userFontSize !== "" && userFontSize !== null) {
            this.setState({userFontSize: parseInt(userFontSize)});
        }
    }

    componentWillUnmount() {
        if(this.socket) {
            if(this.socket.connected) {
                this.socket.close();
            }
        }

        this.disconnectSoup();
        clearInterval(this.checkBroadcastTimer);

        this.cleanupSoupProducers();
    }

    disconnectSoup() {
        if(this.checkSoupConnectionsTimerStarted) {
            this.checkSoupConnectionsTimerStarted = false;
            clearInterval(this.checkSoupConnectionsTimer);
        }
        if(this.Client?.joined) {
            this.Client?.leaveRoom();
        }
        if(this.Client?.secondJoined) {
            this.Client?.leaveSecondRoom();
        }
        if(this.Client?.thirdJoined) {
            this.Client?.leaveThirdRoom();
        }
    }

    handlePauseButton() {
        if(this.state.chatConnected) {
            if(!this.state.pauseMode) {
                this.socket?.emit("startPause");
                this.logBroadcastAction("startPause");
                this.setState({pauseMode: true});
            } else {
                this.socket?.emit("stopPause");
                this.logBroadcastAction("stopPause");
                this.setState({pauseMode: false});
            }
        }
    }

    handleConnectButton() {
        if(this.state.socketConnected) {
            if(this.socket) {
                if(this.socket.connected) {
                    this.disconnectChatServer();
                }
            }

            setTimeout(this.gotoOptions.bind(this), 1000);

            return;
        }

        if (!this.state.broadcasting) {
            this.logBroadcastAction("startBroadcast");
            this.setState({ broadcasting: true,manifestUrl: "",manifestFailCount: 0,manifestCheckState: "Initializing Stream",manifestChecking: true,manifestCheckSuccess: false,manifestCheckFailed: false,manifestCheckCount: 0 });
            this.allowBroadcastRestart = false;
            setTimeout(this.checkBroadcast.bind(this), 2500);
        }

        this.connectChatServer();
    }

    handleSoundButton() {
        if(!this.state.soundEnabled) {
            this.socket?.emit("soundEnabled");
            this.setState({soundEnabled: true});
        } else {
            this.socket?.emit("soundDisabled");
            this.setState({soundEnabled: false});
        }
    }

    handleLovenseButton() {
        if(!this.state.lovenseEnabled) {
            if(this.state.lovenseSelectOpen) {
                this.setState({lovenseSelectOpen: false});
            } else {
                this.setState({lovenseSelectOpen: true});
            }
        } else {
            this.setState({lovenseEnabled: false});
            this.sendLovenseStatusToServer(false);
        }
    }

    handleOfflineButton() {
        this.setState({chatOfflineModal: !this.state.chatOfflineModal});
    }

    handleOptionsButton() {
        if(this.getNumUsers() > 0) {
            if(window.confirm(this.getText("In order to change options you will leave the current session, do you really want to do that?"))) {
                this.gotoOptions();
            }
        } else {
            this.gotoOptions();
        }
    }

    getNumUsers():number {
        let array = [...this.state.users];
        let userCounter = 0;

        for(let curUser of array) {
            if(curUser.userType !== "sender") {
                userCounter++;
            }
        }

        return userCounter;
    }

    getNumVoyeurUsers():number {
        let array = [...this.state.users];
        let voyeurCounter = 0;

        for(let curUser of array) {
            if(new String(curUser.userType).indexOf("voyeur") >= 0) {
                voyeurCounter++;
            }
        }

        return voyeurCounter;
    }

    handleSettingsButton() {
        if(this.context.encoderUi) {
            if(!this.state.encoderDevicesSelected) {
                let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
                let userListHeight:string = myLocalStorage.get("userListHeight");
                if(userListHeight !== "" && userListHeight !== null) {
                    this.setState({userListHeight: parseInt(userListHeight)});
                } else {
                    this.setState({userListHeight: 180});
                }
                this.setState({encoderDevicesSelected: true});
            } else {
                this.setState({userListHeight: 40});
                this.setState({encoderDevicesSelected: false});
            }
            this.context.encoderUi.viewSettings = !this.context.encoderUi.viewSettings;
        } else {
            this.chatMessage("encoderUi is null","syserr");
        }
    }

    playConnectSound() {
        let audio = new Audio("/signal0.mp3");
        audio.play();
    }

    playConnectSound2() {
        let audio = new Audio("/signal1.mp3");
        audio.play();
    }

    playChatMessageSound() {
        let audio = new Audio("/tick.mp3");
        audio.play();
    }

    playUserEnterSound() {
        let audio = new Audio("/signal4.mp3");
        audio.play();
    }

    playUserLeaveSound() {
        let audio = new Audio("/userLeave.mp3");
        audio.play();
    }

    playFeatureRequestSound() {
        let audio = new Audio("/signal5.mp3");
        audio.play();
    }

    openNotesCallback(pseudo:string,guid:string) {
        this.setState({userNotePseudo:pseudo,userNoteGuid:guid});
    }

    closeNotesCallback() {
        this.setState({userNotePseudo:"",userNoteGuid:""});
    }

    closeChatOptionsCallback() {
        this.setState({chatOptionsModal: false});
    }

    closeChatOfflineCallback() {
        this.setState({chatOfflineModal: false});
    }

    updateChatOptionsCallback() {
        // TODO; implement
    }

    updateChatOfflineCallback() {
        // TODO: probably nothing
    }

    updateNotesCallback() {
        if(this.state.userNoteGuid !== "") {
            let array = [...this.state.users];

            for(let curUser of array) {
                if(new String(curUser.guid).localeCompare(this.state.userNoteGuid) === 0) {
                    curUser.updateNotes = true;
                }
            }

            this.setState({users: array});
        }
    }

    selectUser(pseudo:string,guid:string) {
        if(guid === "") {
            this.setState({
                chatTargetPseudo: ""
            });
            this.chatTargetClient = null;
            return;
        }

        this.chatMessage(this.getText("UserSelected") + pseudo,"userselect");

        this.setState({
            chatTargetPseudo: pseudo
        });

        let array = [...this.state.users];

        for(let curUser of array) {
            if(curUser.guid === guid) {
                this.chatTargetClient = curUser;
                break;
            }
        }

        this.chatInputFocus();
    }

    uuidv4():string {
        return ('111-111-1111').replace(/[018]/g, () =>
               (crypto.getRandomValues(new Uint8Array(1))[0] & 15).toString(16));
    }

    initBackupSoup = async() => {
        this.backupPeerId = this.uuidv4();

        this.logBroadcastAction("Initializing backup stream",this.backupPeerId);

        // verbessertes SignalingInterface benutzen bei dem man den soupServer als Parameter übergeben kann
        this.mySignaling = new SignalingInterface(this.backupPeerId,"https://soup04.guppy.live:3000");

        // Create a device (use browser auto-detection).
        this.backupDevice = new Device();

        // Communicate with our server app to retrieve router RTP capabilities.
        let {routerRtpCapabilities} = await this.mySignaling.request('join-as-new-peer');

        // Load the device with the router RTP capabilities.
        await this.backupDevice.load({ routerRtpCapabilities });

        // Check whether we can produce video to the router.
        if (this.backupDevice.loaded)
        { 
            this.sysMessage('backup device loaded');
            this.syncTimer = setInterval(this.syncBackupSoup.bind(this), 3000);
        } else {
            console.warn('device not loaded');
            this.sysMessage('backup device not loaded');
            this.logBroadcastAction("Initializing backup stream failed, could not load device");
            return;
        }

        if(!this.backupDevice.canProduce("video")) {
            console.warn('Initializing backup stream failed, cannot produce video');
            this.sysMessage('Initializing backup stream failed, cannot produce video');
            this.logBroadcastAction("Initializing backup stream failed, cannot produce video");
            return;
        }

        const {transportOptions} = await this.mySignaling.request('create-transport', { 'direction':'send' });

        this.sysMessage('created transport on server: ' + transportOptions.id);

        // Create the local representation of our server-side transport.
        this.sendTransport = this.backupDevice.createSendTransport(transportOptions);

        // Set transport "connect" event handler.
        this.sendTransport.on('connect', this.onSendTransportConnect.bind(this));
        this.sendTransport.on('connectionstatechange', this.onSendTransportConnectionStateChange.bind(this));

        // Set transport "produce" event handler.
        this.sendTransport.on('produce',this.onSendTransportProduce.bind(this));

        // Produce video
        let stream;

        if(this.encoderUi == null) {
            this.sysMessage("Cannot setup back stream: encoderUi is null");
            console.error("Cannot setup back stream: encoderUi is null");
            this.logBroadcastAction("Initializing backup stream failed, encoderUi is null");
            return;
        }

        let myVideoTrack:any|null = null;
        let myAudioTrack:any|null = null;
        let myCtrl:types.MediaStreamControllerAPI = this.encoderUi.mediaStreamController as types.MediaStreamControllerAPI;

        if(myCtrl.source) {
            myVideoTrack = myCtrl.source.getVideoTracks()[0].clone();
            myAudioTrack = myCtrl.source.getAudioTracks()[0].clone();
        }

        this.backupStreamPaused = false;

        if(myVideoTrack !== null) {
            this.setState({encoderInitialized: true});
    
            this.sysMessage('trying to produce video as peer: ' + this.backupPeerId);
    
            try {
                this.videoProducer = await this.sendTransport.produce({ 
                    track: myVideoTrack,
                    appData: { mediaTag: 'cam-video' }
                });
                this.mySignaling.request('resume-producer', { producerId: this.videoProducer.id });
            } catch(e:any) {
                this.sysMessage("Exception producing video backup: " + e.toString());
                this.logBroadcastAction("Exception producing video backup: " + e.toString());
            }
        } else {
            this.sysMessage('no video tracks found for backup video');
            this.logBroadcastAction('no video tracks found for backup video');
        }

        if(myAudioTrack !== null) {
            this.setState({encoderInitialized: true});
    
            this.sysMessage('trying to produce audio as peer: ' + this.backupPeerId);
    
            try {
                this.audioProducer = await this.sendTransport.produce({ 
                    track: myAudioTrack,
                    appData: { mediaTag: 'cam-audio' }
                });
                this.mySignaling.request('resume-producer', { producerId: this.audioProducer.id });
            } catch(e:any) {
                this.sysMessage("Exception trying to produce audio backup: " + e.toString());
                this.logBroadcastAction("Exception trying to produce audio backup: " + e.toString());
            }
        } else {
            this.sysMessage('no audio tracks found');
            this.logBroadcastAction('no audio tracks found');
        }
    }

    initCam2CamSoup1 = async() => {
        this.cam2camPeerId1 = this.uuidv4();

        if(this.context.loginData.pseudo.indexOf("Testsender") > -1) {
            this.chatMessage("Initializing cam-2-cam soup 1 with peerid " + this.cam2camPeerId1,"chat-sysmessage");
        }

        this.logBroadcastAction("Initializing cam-2-cam soup 1 with peerid " + this.cam2camPeerId1,this.cam2camPeerId1);

        // verbessertes SignalingInterface benutzen bei dem man den soupServer als Parameter übergeben kann
        this.cam2camSignaling1 = new SignalingInterface(this.cam2camPeerId1,"https://soup01.guppy.live:3000");

        // Create a device (use browser auto-detection).
        this.cam2camDevice1 = new Device();

        // Communicate with our server app to retrieve router RTP capabilities.
        let {routerRtpCapabilities} = await this.cam2camSignaling1.request('join-as-new-peer');

        // Load the device with the router RTP capabilities.
        await this.cam2camDevice1.load({ routerRtpCapabilities });

        // Check whether we can produce video to the router.
        if (this.cam2camDevice1.loaded)
        { 
            this.sysMessage('cam2cam Device 1 loaded');
            this.cam2camSyncTimer1 = setInterval(this.syncCam2CamSoup1.bind(this), 2000);
        } else {
            console.warn('device 1 not loaded');
            this.sysMessage('cam-2-cam device 1 not loaded');
            this.logBroadcastAction("Initializing cam-2-cam device 1 failed, could not load device");
            return;
        }

        const {transportOptions} = await this.cam2camSignaling1.request('create-transport', { 'direction':'recv' });

        this.sysMessage('created cam-2-cam 1 recv transport on server: ' + transportOptions.id);

        // Create the local representation of our server-side transport.
        this.recvTransport1 = this.cam2camDevice1.createRecvTransport(transportOptions);

        // Set transport "connect" event handler.
        this.recvTransport1.on('connect', this.onRecvTransportConnect1.bind(this));
        this.recvTransport1.on('connectionstatechange', this.onRecvTransportConnectionStateChange1.bind(this));
    }

    initCam2CamSoup2 = async() => {
        this.cam2camPeerId2 = this.uuidv4();

        if(this.context.loginData.pseudo.indexOf("Testsender") > -1) {
            this.chatMessage("Initializing cam-2-cam soup 2 with peerid " + this.cam2camPeerId2,"chat-sysmessage");
        }

        this.logBroadcastAction("Initializing cam-2-cam soup 2 with peerid " + this.cam2camPeerId2,this.cam2camPeerId2);

        // verbessertes SignalingInterface benutzen bei dem man den soupServer als Parameter übergeben kann
        this.cam2camSignaling2 = new SignalingInterface(this.cam2camPeerId2,"https://soup05.guppy.live:3000");

        // Create a device (use browser auto-detection).
        this.cam2camDevice2 = new Device();

        // Communicate with our server app to retrieve router RTP capabilities.
        let {routerRtpCapabilities} = await this.cam2camSignaling2.request('join-as-new-peer');

        // Load the device with the router RTP capabilities.
        await this.cam2camDevice2.load({ routerRtpCapabilities });

        // Check whether we can produce video to the router.
        if (this.cam2camDevice2.loaded)
        { 
            this.sysMessage('cam2cam Device 2 loaded');
            this.cam2camSyncTimer2 = setInterval(this.syncCam2CamSoup2.bind(this), 2000);
        } else {
            console.warn('device 2 not loaded');
            this.sysMessage('cam-2-cam device 2 not loaded');
            this.logBroadcastAction("Initializing cam-2-cam device 2 failed, could not load device");
            return;
        }

        const {transportOptions} = await this.cam2camSignaling2.request('create-transport', { 'direction':'recv' });

        this.sysMessage('created cam-2-cam recv transport on server: ' + transportOptions.id);

        // Create the local representation of our server-side transport.
        this.recvTransport2 = this.cam2camDevice2.createRecvTransport(transportOptions);

        // Set transport "connect" event handler.
        this.recvTransport2.on('connect', this.onRecvTransportConnect2.bind(this));
        this.recvTransport2.on('connectionstatechange', this.onRecvTransportConnectionStateChange2.bind(this));
    }

    createCam2CamSoup1 = async() => {
        this.createCam2CamSoup1Planned = false;

        const {transportOptions} = await this.cam2camSignaling1.request('create-transport', { 'direction':'recv' });

        this.logBroadcastAction("Cam-2-Cam recv transport 1 created " + transportOptions.id);
        this.sysMessage('created cam-2-cam 1 recv transport on server: ' + transportOptions.id);

        // Create the local representation of our server-side transport.
        this.recvTransport1 = this.cam2camDevice1.createRecvTransport(transportOptions);

        // Set transport "connect" event handler.
        this.recvTransport1.on('connect', this.onRecvTransportConnect1.bind(this));
        this.recvTransport1.on('connectionstatechange', this.onRecvTransportConnectionStateChange1.bind(this));
    }

    closeCam2CamSoup1 = async() => {
        if(this.recvTransport1) {
            this.logBroadcastAction("Cam-2-Cam recv transport 1 closing " + this.recvTransport1.id);
            await this.cam2camSignaling1.request('close-transport', { 'transportId':this.recvTransport1.id });
            this.recvTransport1.close();
        }
    }

    createCam2CamSoup2 = async() => {
        this.createCam2CamSoup2Planned = false;

        const {transportOptions} = await this.cam2camSignaling2.request('create-transport', { 'direction':'recv' });

        this.logBroadcastAction("Cam-2-Cam recv transport 2 created " + transportOptions.id);
        this.sysMessage('created cam-2-cam recv transport on server: ' + transportOptions.id);

        // Create the local representation of our server-side transport.
        this.recvTransport2 = this.cam2camDevice2.createRecvTransport(transportOptions);

        // Set transport "connect" event handler.
        this.recvTransport2.on('connect', this.onRecvTransportConnect2.bind(this));
        this.recvTransport2.on('connectionstatechange', this.onRecvTransportConnectionStateChange2.bind(this));
    }

    closeCam2CamSoup2 = async() => {
        if(this.recvTransport2) {
            this.logBroadcastAction("Cam-2-Cam recv transport 2 closing " + this.recvTransport2.id);
            await this.cam2camSignaling2.request('close-transport', { 'transportId':this.recvTransport2.id });
            this.recvTransport2.close();
        }
    }

    pauseBackupProducer() {
        this.backupStreamPaused = true;

        try {
            if(this.videoProducer) {
                this.videoProducer.pause();
                this.mySignaling.request('pause-producer', { producerId: this.videoProducer.id });
            }
        } catch(e:any) {
            this.sysMessage("Exception pausing video backup: " + e.toString());
            this.logBroadcastAction("Exception pausing video backup: " + e.toString());
        }

        try {
            if(this.audioProducer) {
                this.audioProducer.pause();
                this.mySignaling.request('pause-producer', { producerId: this.audioProducer.id });
            }
        } catch(e:any) {
            this.sysMessage("Exception pausing audio backup: " + e.toString());
            this.logBroadcastAction("Exception pausing audio backup: " + e.toString());
        }
    }

    resumeBackupProducer() {
        this.backupStreamPaused = false;

        try {
            if(this.videoProducer) {
                this.videoProducer.resume();
                this.mySignaling.request('resume-producer', { producerId: this.videoProducer.id });
            }
        } catch(e:any) {
            this.sysMessage("Exception resuming video backup: " + e.toString());
            this.logBroadcastAction("Exception resuming video backup: " + e.toString());
        }

        try {
            if(this.audioProducer) {
                this.audioProducer.resume();
                this.mySignaling.request('resume-producer', { producerId: this.audioProducer.id });
            }
        } catch(e:any) {
            this.sysMessage("Exception resuming audio backup: " + e.toString());
            this.logBroadcastAction("Exception resuming audio backup: " + e.toString());
        }
    }

    syncBackupSoup = async() => {
        const mySyncResponse:SyncResponse = await this.mySignaling.requestSync();
        if (mySyncResponse) {
            if(mySyncResponse.error) {
                this.sysMessage("Error syncing backup soup: " + mySyncResponse.error.toString());
                this.logBroadcastAction("Error syncing backup soup: " + mySyncResponse.error.toString());
            } else {
                // brauchen wir glaub ich nicht wirklich
                //this.setState({backupSoupPeers: mySyncResponse.peers});
            }
        }
    }

    syncCam2CamSoup1 = async() => {
        const mySyncResponse:SyncResponse = await this.cam2camSignaling1.requestSync();
        if (mySyncResponse) {
            if(mySyncResponse.error) {
                this.sysMessage("Error syncing cam-2-cam soup 1: " + mySyncResponse.error.toString());
                this.logBroadcastAction("Error syncing cam-2-cam soup 1: " + mySyncResponse.error.toString());
            } else {
                // brauchen wir glaub ich nicht wirklich
                //this.setState({backupSoupPeers: mySyncResponse.peers});
            }
        }
    }

    syncCam2CamSoup2 = async() => {
        const mySyncResponse:SyncResponse = await this.cam2camSignaling2.requestSync();
        if (mySyncResponse) {
            if(mySyncResponse.error) {
                this.sysMessage("Error syncing cam-2-cam soup 2: " + mySyncResponse.error.toString());
                this.logBroadcastAction("Error syncing cam-2-cam soup 2: " + mySyncResponse.error.toString());
            } else {
                // brauchen wir glaub ich nicht wirklich
                //this.setState({backupSoupPeers: mySyncResponse.peers});
            }
        }
    }

    onRecvTransportConnect1 = async ({ dtlsParameters }: any, callback: () => void, errback: (arg0: unknown) => void) =>{
        this.sysMessage('cam-2-cam recvTransport 1 connected');
        this.logBroadcastAction('cam-2-cam recv recvTransport 1 connected');

        // Here we must communicate our local parameters to our remote transport.
        try
        {
            if(this.recvTransport1) {
                await this.cam2camSignaling1.request('connect-transport', 
                {
                    transportId: this.recvTransport1.id,
                    dtlsParameters
                });
    
                // Done in the server, tell our transport.
                callback();
            } else {
                this.sysMessage('error setting up server-side transport 1 for cam-2-cam');
                this.logBroadcastAction('error setting up server-side transport 1 for cam-2-cam');
            }
        }
        catch (error)
        {
            // Something was wrong in server side.
            errback(error);
        }
    }

    onRecvTransportConnectionStateChange1 = async (state:string) => {
        this.setState({cam2camTransportConnState1: state});
        if (state === 'closed' || state === 'failed' || state === 'disconnected') {
            this.sysMessage('Cam-2-Cam recvTransport 1 ' + state);
            this.logBroadcastAction('Cam-2-Cam recvTransport 1 ' + state);
        }
    }

    onRecvTransportConnect2 = async ({ dtlsParameters }: any, callback: () => void, errback: (arg0: unknown) => void) =>{
        this.sysMessage('cam-2-cam recvTransport 2 connected');
        this.logBroadcastAction('cam-2-cam recv recvTransport 2 connected');

        // Here we must communicate our local parameters to our remote transport.
        try
        {
            if(this.recvTransport2) {
                await this.cam2camSignaling2.request('connect-transport', 
                {
                    transportId: this.recvTransport2.id,
                    dtlsParameters
                });
    
                // Done in the server, tell our transport.
                callback();
            } else {
                this.sysMessage('error setting up server-side transport 2 for cam-2-cam');
                this.logBroadcastAction('error setting up server-side transport 2 for cam-2-cam');
            }
        }
        catch (error)
        {
            // Something was wrong in server side.
            errback(error);
        }
    }

    onRecvTransportConnectionStateChange2 = async (state:string) => {
        this.setState({cam2camTransportConnState2: state});
        if (state === 'closed' || state === 'failed' || state === 'disconnected') {
            this.sysMessage('Cam-2-Cam recvTransport2 ' + state);
            this.logBroadcastAction('Cam-2-Cam recvTransport2 ' + state);
        }
    }

    onSendTransportConnect = async ({ dtlsParameters }: any, callback: () => void, errback: (arg0: unknown) => void) =>{
        this.sysMessage('backup sendTransport connected');
        this.logBroadcastAction('backup sendTransport connected');

        // Here we must communicate our local parameters to our remote transport.
        try
        {
            if(this.sendTransport) {
                await this.mySignaling.request('connect-transport', 
                {
                    transportId: this.sendTransport.id,
                    dtlsParameters
                });
    
                // Done in the server, tell our transport.
                callback();
            } else {
                this.sysMessage('error setting up server-side transport for backup stream');
                this.logBroadcastAction('error setting up server-side transport for backup stream');
            }
        }
        catch (error)
        {
            // Something was wrong in server side.
            errback(error);
        }
    }

    onSendTransportConnectionStateChange = async (state:string) => {
        this.setState({backSoupTransportConnState: state});
        if (state === 'closed' || state === 'failed' || state === 'disconnected') {
            this.sysMessage('Backup stream sendTransport ' + state);
            this.logBroadcastAction('Backup stream sendTransport ' + state);
        }
    }

    onSendTransportProduce = async ({ kind, rtpParameters, appData }:any, callback:any, errback:any) => {

        // this is called before the produce actually starts, we use it to inform the server that we want to send a mediatrack
        try
        {
            let paused = false;

            if(this.sendTransport) {
                this.sysMessage('going to produce ' + appData.mediaTag + ' on transport ' + this.sendTransport.id);

                let { error, id } = await this.mySignaling.request('send-track', 
                    { 
                        transportId : this.sendTransport.id,
                        kind,
                        rtpParameters,
                        paused,
                        appData
                    });
    
                if (error) {
                    this.sysMessage('error setting up server-side producer for backup ' + JSON.stringify(error));
                    this.logBroadcastAction('error setting up server-side producer for backup ' + JSON.stringify(error));
                    errback();
                    return;
                } else {
                    this.sysMessage('sending backup ' + this.backupPeerId);
                    this.logBroadcastAction('sending backup stream ' + this.backupPeerId);
                    this.reportWebRtcPeerId(this.backupPeerId);
                    if(appData !== null) {
                        if(typeof appData.mediaTag !== "undefined") {
                            if(appData.mediaTag == "cam-video") {
                                this.setState({soup1VideoProducing: true});
                            } else if(appData.mediaTag == "cam-audio") {
                                this.setState({soup1AudioProducing: true});
                            }
                        }
                    }
                }
    
                // Done in the server, pass the response to our transport.
                callback({ id });
            } else {
                this.sysMessage('error setting up server-side transport for backup');
                this.logBroadcastAction('error setting up server-side transport for backup');
                errback();
                return;
            }
        } catch (error) {
            this.sysMessage('error when informing server about produce: ' + JSON.stringify(error));
            this.logBroadcastAction('error when informing server about produce: ' + JSON.stringify(error));
            // Something was wrong in server side.
            errback(error);
        }
    }

    cleanupSoupProducers = async() => {
        this.sysMessage("Closing backup stream");
        this.logBroadcastAction("Closing backup stream");
        clearInterval(this.syncTimer);

        try {
            if(this.sendTransport) {
                this.sendTransport.close();
            }
        } catch(e:any) {
            this.sysMessage("Error closing sendTransport for backup: " + e);
            console.log("Error closing sendTransport for backup: " + e);
        }

        try {
            await this.mySignaling.request('leave');
        } catch(e:any) {
            this.sysMessage("Error leaving backup as soup peer for backup: " + e);
            console.log("Error leaving backup as soup peer for backup: " + e);
        }
    }

    reportWebRtcPeerId = async(peerId:string) => {
        const axios = require('axios').default;
    
        try {
            if(this.context) {
                let jsonurl:string;
                let SoftwareVersion = globSoftwareVersion;
    
                if(this.state.isElectron) {
                    SoftwareVersion += "s";
                } else {
                    SoftwareVersion += "b";
                }

                let producerID = this.context.loginData.producerID;
    
                jsonurl = "https://streamauth.guppy.live/SetWebRTCTargetID.aspx?WebRTCTargetID=" + encodeURIComponent(peerId) + "&ProducerID=" + encodeURIComponent(producerID) + "&appVersion=" + encodeURIComponent(SoftwareVersion);
    
                const response = await axios.get(jsonurl);
    
                return response.data.result;
            }
        } catch (error) {
            console.error('reportWebRtcPeerId ERROR:');
            console.error(error);
        }

        return "";
    }

    handleBroadcastButton() {
        if(this.state.broadcasting) {
            this.cleanupSoupProducers();
        }
        this.setState({broadcasting: !this.state.broadcasting});
    }

    handleDebugButton() {
        let mystr:String = "";

        mystr = "";
        for(var j = 0;j < this.state.cam2CamInfos.length;j++) {
            mystr += "[" + this.state.cam2CamInfos[j].pseudo + "]";
        }

        this.chatMessage("Cam-2-Cam classic list has " + this.state.cam2CamInfos.length + " items " + mystr,"");

        mystr = "";
        for(var j = 0;j < this.state.cam2CamInfosSoup.length;j++) {
            mystr += "[" + this.state.cam2CamInfosSoup[j].pseudo + "]";
        }

        this.chatMessage("Cam-2-Cam soup list has " + this.state.cam2CamInfosSoup.length + " items " + mystr,"");

        mystr = "";
        for(var j = 0;j < this.state.cam2CamInfosSoupCentral1.length;j++) {
            mystr += "[" + this.state.cam2CamInfosSoupCentral1[j].pseudo + "]";
        }

        this.chatMessage("Cam-2-Cam soup list has " + this.state.cam2CamInfosSoupCentral1.length + " items " + mystr,"");

        mystr = "";
        for(var j = 0;j < this.state.cam2CamInfosSoupCentral2.length;j++) {
            mystr += "[" + this.state.cam2CamInfosSoupCentral2[j].pseudo + "]";
        }

        this.chatMessage("Cam-2-Cam soup list has " + this.state.cam2CamInfosSoupCentral2.length + " items " + mystr,"");

        mystr = "";
        for(var j = 0;j < this.state.cam2CamInfosLively.length;j++) {
            mystr += "[" + this.state.cam2CamInfosLively[j].pseudo + "]";
        }

        this.chatMessage("Cam-2-Cam Lively list has " + this.state.cam2CamInfosLively.length + " items " + mystr,"");

        mystr = "";
        for(var j = 0;j < this.state.userMicInfos.length;j++) {
            mystr += "[" + this.state.userMicInfos[j].pseudo + "|fsid:" + this.state.userMicInfos[j].flashChatSessionID + "]";
        }

        this.chatMessage("UserMic classic list has " + this.state.userMicInfosLively.length + " items " + mystr,"");

        mystr = "";
        for(var j = 0;j < this.state.userMicInfosSoup.length;j++) {
            mystr += "[" + this.state.userMicInfosSoup[j].pseudo + "|fsid:" + this.state.userMicInfosSoup[j].flashChatSessionID + "]";
        }

        this.chatMessage("UserMic soup list has " + this.state.userMicInfosSoup.length + " items " + mystr,"");

        mystr = "";
        for(var j = 0;j < this.state.userMicInfosSoupCentral1.length;j++) {
            mystr += "[" + this.state.userMicInfosSoupCentral1[j].pseudo + "|fsid:" + this.state.userMicInfosSoupCentral1[j].flashChatSessionID + "]";
        }

        this.chatMessage("UserMic soup list has " + this.state.userMicInfosSoupCentral1.length + " items " + mystr,"");

        mystr = "";
        for(var j = 0;j < this.state.userMicInfosSoupCentral2.length;j++) {
            mystr += "[" + this.state.userMicInfosSoupCentral2[j].pseudo + "|fsid:" + this.state.userMicInfosSoupCentral2[j].flashChatSessionID + "]";
        }

        this.chatMessage("UserMic soup list has " + this.state.userMicInfosSoupCentral2.length + " items " + mystr,"");

        mystr = "";
        for(var j = 0;j < this.state.userMicInfosLively.length;j++) {
            mystr += "[" + this.state.userMicInfosLively[j].pseudo + "|fsid:" + this.state.userMicInfosLively[j].flashChatSessionID + "]";
        }

        this.chatMessage("UserMic Lively list has " + this.state.userMicInfosLively.length + " items " + mystr,"");
    }

    handleLanguageButton() {
        if(this.state.language === "de") {
            this.chatMessage("Switch language to english...","chat-sysmessage");
            this.setState({language: "en"});
        } else {
            this.chatMessage("Sprache auf Deutsch gewechselt...","chat-sysmessage");
            this.setState({language: "de"});
        }
    }

    chatFontPlus() {
        this.setState({chatFontSize: this.state.chatFontSize + 1});
        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
        myLocalStorage.add("chatFontSize",this.state.chatFontSize.toString());
    }
    
    chatFontMinus() {
        if(this.state.chatFontSize > 6) {
            this.setState({chatFontSize: this.state.chatFontSize - 1});
            let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
            myLocalStorage.add("chatFontSize",this.state.chatFontSize.toString());
        }
    }

    usersFontPlus() {
        this.setState({usersFontSize: this.state.usersFontSize + 1});
        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
        myLocalStorage.add("usersFontSize",this.state.usersFontSize.toString());
    }

    usersFontMinus() {
        if(this.state.usersFontSize > 6) {
            this.setState({usersFontSize: this.state.usersFontSize - 1});
            let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
            myLocalStorage.add("usersFontSize",this.state.usersFontSize.toString());
        }
    }

    usersTestPlus() {
        this.testcounter++;

        let newguid:string = this.Client?.uuidv4() as string;

        this.testuserguids.push(newguid);

        this.globalUserCounter++;

        let newUserInfo:userInfo = {
            id: this.globalUserCounter,
            guid: newguid,
            isInvisible: false,
            userType: "user",
            pseudo: "test" + this.testcounter,
            isFromCMS: false,
            lovense: false,
            sound: false,
            dildo: false,
            userMic: false,
            privateSession: false,
            cam2cam: false,
            updateNotes: true,
            multiPrivate: false
        }

        this.addUser(newUserInfo);
    }

    usersTestMinus() {
        let guidtoremove:any = this.testuserguids.pop();

        if(guidtoremove !== null) {
            if(guidtoremove !== "") {
                this.removeUserFromList(guidtoremove);
            }
        }
    }

    videoMoveUp() {
        if(this.state.userListHeight < 900) {
            this.setState({userListHeight: this.state.userListHeight + 10});
            let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
            myLocalStorage.add("userListHeight",this.state.userListHeight.toString());
        }
    }

    videoMoveDown() {
        if(this.state.userListHeight > 0) {
            this.setState({userListHeight: this.state.userListHeight - 10});
            if(this.state.encoderDevicesSelected) {
                let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
                myLocalStorage.add("userListHeight",this.state.userListHeight.toString());
            }
        }
    }

    videoMoveLeft() {
        if(this.state.videoPaneCols > 0) {
            this.setState({videoPaneCols: this.state.videoPaneCols - 1});
            if(this.state.encoderDevicesSelected) {
                let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
                myLocalStorage.add("videoPaneCols",this.state.videoPaneCols.toString());
            }
        }
    }

    videoMoveRight() {
        if(this.state.videoPaneCols < 12) {
            this.setState({videoPaneCols: this.state.videoPaneCols + 1});
            let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
            myLocalStorage.add("videoPaneCols",this.state.videoPaneCols.toString());
        }
    }

    switchToWhiteMode() {
        this.setState({
            mainBackgroundColor: "#fff",
            mainTextColor: "#000",
            chatBackgroundColor: "#eee",
            chatTextColor: "#000",
            userListBackgroundColor: "#eee",
            userListTextColor: "#000",
            sysMessageColor: "blue",
            featureMessageColor: "darkgray",
            enterColor: "green",
            leaveColor: "chocolate",
            chatFromGirlColor: "crimson",
            chatFromUserColor1: "darkolivegreen",
            chatFromUserColor2: "darkred",
            chatFromUserColor3: "darkmagenta",
            featureRequestColor: "indigo",
            featureAcceptedColor: "mediumseagreen",
            featureDeniedColor: "darkred"
        });
        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
        myLocalStorage.add("colorMode","white");
    }

    switchToGrayMode() {
        this.setState({
            mainBackgroundColor: "#ddd",
            mainTextColor: "#000",
            chatBackgroundColor: "#ccc",
            chatTextColor: "#000",
            userListBackgroundColor: "#ccc",
            userListTextColor: "#000",
            sysMessageColor: "blue",
            featureMessageColor: "darkgray",
            enterColor: "green",
            leaveColor: "chocolate",
            chatFromGirlColor: "crimson",
            chatFromUserColor1: "darkolivegreen",
            chatFromUserColor2: "darkred",
            chatFromUserColor3: "darkmagenta",
            featureRequestColor: "indigo",
            featureAcceptedColor: "mediumseagreen",
            featureDeniedColor: "darkred"
        });
        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
        myLocalStorage.add("colorMode","gray");
    }

    switchToSepiaMode() {
        this.setState({
            mainBackgroundColor: "#BCAC80",
            mainTextColor: "#000",
            chatBackgroundColor: "#D0C4A0",
            chatTextColor: "#000",
            userListBackgroundColor: "#D0C4A0",
            userListTextColor: "#000",
            sysMessageColor: "blue",
            featureMessageColor: "darkgray",
            enterColor: "green",
            leaveColor: "chocolate",
            chatFromGirlColor: "crimson",
            chatFromUserColor1: "darkolivegreen",
            chatFromUserColor2: "darkred",
            chatFromUserColor3: "darkmagenta",
            featureRequestColor: "indigo",
            featureAcceptedColor: "mediumseagreen",
            featureDeniedColor: "darkred"
        });
        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
        myLocalStorage.add("colorMode","sepia");
    }

    switchToDarkMode() {
        this.setState({
            mainBackgroundColor: "#000",
            mainTextColor: "#fff",
            chatBackgroundColor: "#222",
            chatTextColor: "#fff",
            userListBackgroundColor: "#222",
            userListTextColor: "#fff",
            sysMessageColor: "lightsteelblue",
            featureMessageColor: "azure",
            enterColor: "greenyellow",
            leaveColor: "peachpuff",
            chatFromGirlColor: "lightskyblue",
            chatFromUserColor1: "lightskyblue",
            chatFromUserColor2: "sand",
            chatFromUserColor3: "beige",
            featureRequestColor: "lightpink",
            featureAcceptedColor: "lightgreen",
            featureDeniedColor: "lightred"
        });
        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
        myLocalStorage.add("colorMode","dark");
    }

    handleMirrorButton() {
        if(this.context.encoderUi) {
            if(!this.videoMirrored) {
                if(this.context.encoderUi) {
                    if(this.context.encoderUi.videoWrapperElement) {
                        if(this.context.encoderUi.videoWrapperElement.current) {
                            this.context.encoderUi.videoWrapperElement.current.style.transform = 'rotateY(180deg)';
                        }
                    } else {
                        this.chatMessage("Error: videoWrapperElement is null","chat-sysmessage");
                    }
                } else {
                    this.chatMessage("Error: Could not find encoderUi","chat-sysmessage");
                }
                this.videoMirrored = true;
                let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
                myLocalStorage.add("videoMirrored","1");
            } else {
                if(this.context.encoderUi.videoWrapperElement) {
                    if(this.context.encoderUi.videoWrapperElement.current) {
                        this.context.encoderUi.videoWrapperElement.current.style.transform = 'rotateY(0deg)';
                    }
                }
                this.videoMirrored = false;
                let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
                myLocalStorage.add("videoMirrored","0");
            }
        } else {
            this.chatMessage("Error: Could not find encoderUi","chat-sysmessage");
        }
    }

    handleOnCameraSelect() {
        if(this.context.loginData.pseudo.indexOf("Testsender") > -1) {
            this.chatMessage("Camera/Mic selected","chat-encodermessage");
        }
        this.setState({encoderDevicesSelected: true});
        this.setState({encoderDevicesSelectedOnce: true});
        this.logBroadcastAction("Camera/Mic selected");
        this.setState({ broadcasting: true});
        
        let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
        let userListHeight:string = myLocalStorage.get("userListHeight");
        if(userListHeight !== "" && userListHeight !== null) {
            this.setState({userListHeight: parseInt(userListHeight)});
        } else {
            this.setState({userListHeight: 180});
        }
        let videoPaneCols:string = myLocalStorage.get("videoPaneCols");
        if(videoPaneCols !== "" && videoPaneCols !== null) {
            this.setState({videoPaneCols: parseInt(videoPaneCols)});
        }
    }

    chatMessage(msg:string,className:string) {
        //this.chatMessageCounter++;

        if(typeof(className) === "undefined") {
            this.setState((prevState:chatInterfaceState) => ({chatMessages: [...prevState.chatMessages, {
                id: this.chatMessageCounter++,
                msg: msg,
                className: "chat-sysmessage",
                fromPart: '',
                messageClass: ''
            }]}));
        } else {
            this.setState((prevState:chatInterfaceState) => ({chatMessages: [...prevState.chatMessages, {
                id: this.chatMessageCounter++,
                msg: msg,
                className: className,
                fromPart: '',
                messageClass: ''
            }]}));
        }

        this.scrollDown();
    }

    chatMessageDebug(msg:string) {
        this.chatMessage(msg,"debugmsg");
    }

    scrollDown() {
        if(this.chatMessageContainerRef) {
            if(this.chatMessageContainerRef.current) {
                this.chatMessageContainerRef.current.scrollTop = this.chatMessageContainerRef.current.scrollHeight;
                //this.chatMessageContainerRef.current.scrollIntoView({ behavior: 'smooth' })
                //document.getElementById("chatMessageContainer").scrollTop = document.getElementById("chatMessageContainer").scrollHeight;
            }
        }
    }

    chatMessageTwoPart(msg:string,className:string,fromPart:string,messageClass:string) {
        this.chatMessageCounter++;

        this.setState((prevState:chatInterfaceState) => ({chatMessages: [...prevState.chatMessages, {
            id: this.chatMessageCounter,
            msg: msg,
            className: className,
            fromPart: fromPart,
            messageClass: messageClass
        }]}));

        this.scrollDown();
    }

    sysMessage(msg:string) {
        this.setState((prevState:chatInterfaceState) => ({sysMessages: [msg,...prevState.sysMessages]}));
    }

    sysMessageDebug(msg:string) {
        this.setState((prevState:chatInterfaceState) => ({sysMessages: [msg,...prevState.sysMessages]}));
    }

    debuglog(msg:string) {
        this.setState((prevState:chatInterfaceState) => ({debugMessages: [msg,...prevState.debugMessages]}));
    }

    onSocketConnect() {
        if(this.state.language == "de") {
            this.chatMessage("Verbindung zum Chatserver hergestellt, Chatraum wird eingerichtet...","chat-sysmessage");
        } else {
            this.chatMessage("Connected to chatServer, initalizing chatroom...","chat-sysmessage");
        }
        this.setState({socketConnected: true});
        this.setState({socketConnecting: false});

        let loginData = this.context.loginData;
        let chatOptions = this.context.chatOptions;

        let browserType = "guppysendertool4";
        let SoftwareVersion = globSoftwareVersion;

        if(this.state.isElectron) {
            SoftwareVersion += "s";
        } else {
            SoftwareVersion += "b";
        }

        try {
            this.socket?.emit("set-client-info",{
                pseudo: loginData.pseudo,
                userID: loginData.producerID,
                userType: "sender",
                userSystemID: "_sender",
                sessionSystemID: "_sender",
                browserType: browserType,
                price: chatOptions.price,
                clientMainSys: "guppy",
                userLanguage: this.context.language,
                appVersion: SoftwareVersion,
                soundPrice: chatOptions.soundPrice,
                cam2camPrice: chatOptions.cam2camPrice,
                userMicPrice: chatOptions.userMicPrice,
                dildoPrice: chatOptions.dildoPrice,
                lovenseSessionPrice: chatOptions.lovenseSessionPrice,
                privateSessionPrice: chatOptions.privateSessionPrice,
                multiPrivatePrice: chatOptions.multiPrivatePrice
            });

            this.playConnectSound();
        } catch(e:any) {
            this.chatMessage("error sending set-client-info " + e.toString(),"chat-errormessage");
        }
    }

    onSocketConnectError(myerr:any) {
        //this.chatMessage("Connect Error " + JSON.stringify(myerr),"chat-errormessage");
        //this.setState({socketConnecting: false});
    }

    onSocketConnectTimeout() {
        this.chatMessage("Connect Timeout","chat-errormessage");
        this.setState({socketConnecting: false});
    }

    onSocketDisconnect = async() => {
        this.chatMessage("Disconnected from chatServer","chat-errormessage");
        this.setState({socketConnected: false});
        this.setState({socketConnecting: false});
        this.setState({chatConnected: false});
        this.setState({girlDisconnected: true});
        this.setState({users: []});
        this.setState({cam2CamInfos: []});
        this.setState({cam2CamInfosSoup: []});
        this.setState({cam2CamInfosSoupCentral1: []});
        this.setState({cam2CamInfosSoupCentral2: []});
        this.setState({cam2CamInfosLively: []});
        this.setState({userMicInfos: []});
        this.setState({userMicInfosSoup: []});
        this.setState({userMicInfosSoupCentral1: []});
        this.setState({userMicInfosSoupCentral2: []});
        this.setState({userMicInfosLively: []});
        this.setState({manifestCheckState: "Disconnected"});
        this.setState({manifestUrl: ""});
        this.setState({callId: ""});

        let Client:guppySoupClient|null = this.Client;

        if(Client) {
            if(Client.joined) {
                await Client.leaveRoom();
                this.setState({soup1VideoProducing: false});
                this.setState({soup1AudioProducing: false});
            }
        }
    }

    setManifestState(newState:string) {
        this.setState({manifestCheckState: newState});
        if(this.socket) {
            if(this.socket.connected) {
                this.socket.emit("setManifestState",newState);
            }
        }
    }

    girlDisconnectedCallback() {
        console.log("girlDisconnectedCallback from encoder");
        this.logBroadcastAction("girlDisconnected");
        this.setState({girlDisconnected: false});
        this.setState({broadcasting:false});
        this.cleanupSoupProducers();
    }

    onChatUserInfos(data:any) {
        let stateArray = [...this.state.users];
        let myPseudo = "unknown";

        for(let curUser of stateArray) {
            if(curUser.userType != "sender") {
                let guidFound:boolean = false;

                myPseudo = curUser.pseudo;

                for(let i:number = 0;i < data.length;i++) {
                    let curInfo:userInfoFromChatserver = data[i];
                    if(curInfo.guid == curUser.guid) {
                        guidFound = true;
                    }
                }

                if(!guidFound) {
                    //this.chatMessage("not found in received userList: " + curUser.pseudo + "[" + curUser.userType + "]","chat-sysmessage");

                    if(this.chatTargetClient !== null) {
                        if(this.chatTargetClient.guid === curUser.guid) {
                            this.setState({
                                chatTargetPseudo: ""
                            });
                            this.chatTargetClient = null;
                        }
                    }
            
                    if(myPseudo !== "undefined" && typeof(myPseudo) !== "undefined") {
                        if(!this.context.chatOptions.noUserLeaveSound) {
                            this.playUserLeaveSound();
                        }
                        this.chatMessage(myPseudo + " " + this.getText("hat den Chat verlassen") + "...","chat-leave");
                        this.cleanupUserFeatures(myPseudo);
                    }

                    this.removeUserFromList(curUser.guid);
                } else {
                    //this.chatMessage("found in received userList: " + curUser.pseudo + "[" + curUser.userType + "]","chat-sysmessage");
                }
            }
        }

        setTimeout(this.getUserInfos.bind(this),30000);
    }

    onChatConnectFeedback(data:any) {
        if(data.accessGranted) {
            this.setState({chatConnected: true});

            this.setState({
                chatPrice: data.intPrice,
                welcomeMsg: data.newWelcomeMessage,
                allowVoyeur: data.allowVoyeur,
                soundPrice: data.soundPrice,
                flashChatSessionID: data.flashChatSessionID
            });
            
            if(this.state.language == "de") {
                this.chatMessage(this.getText("Chatraum eingerichtet, Du bist online") + "...","chat-sysmessage");
            } else {
                this.chatMessage(this.getText("Chatroom established, your are connected") + "...","chat-sysmessage");
            }

            this.playConnectSound2();

            this.setState({canWrite: true});

            let loginData = this.context.loginData;
            let chatOptionsData = this.context.chatOptions;

            this.setState({users: [{
                pseudo: loginData.pseudo,
                userID: loginData.producerID,
                userType: "sender",
                guid: data.flashChatSessionID,
                updateNotes: false
            }]},this.askServerForAlreadyConnectedUsers.bind(this));

            if (!this.state.broadcasting) {
                this.setState({manifestUrl: "",callId: "",manifestFailCount: 0,manifestCheckState: "Connecting",manifestCheckCount:0,manifestCheckFailed:false});
                this.allowBroadcastRestart = false;
                setTimeout(this.setBroadcastingTrue.bind(this),5000);
                setTimeout(this.checkBroadcast.bind(this), 15000);
            } else {
                this.setCallIDOnServer(this.state.callId);
            }

            this.chatInputFocus();
            
            if(chatOptionsData.autoEnableSound) {
                setTimeout(this.enableSoundAuto.bind(this),2000);
            }

            setTimeout(this.getUserInfos.bind(this),30000);

            if(this.state.dildoEnabled) {
                this.sendDildoStatusToServer(true);
            }

            if(this.state.lovenseEnabled) {
                this.sendLovenseStatusToServer(true);
            }

            this.checkBroadcastTimer = setInterval(this.checkBroadcastWhenConnected.bind(this), 8000);

            // JP: 14.11.2024 - initBackupSoup startet nur bei Lively-Ausfall
            //this.initBackupSoup();
            if(this.cam2camPeerId1 === "") {
                this.initCam2CamSoup1();
            }
            if(this.cam2camPeerId2 === "") {
                this.initCam2CamSoup2();
            }
        } else {
            this.setState({chatConnected: false});
            this.chatMessage(this.getText("Verbindung zum Chatserver abgelehnt"),"chat-errormessage");
            this.chatMessage(data.errorMessage,"chat-errormessage");
            this.socket?.disconnect();
        }
    }

    onChatStartBroadcast() {
        this.setState({broadcasting: true});
    }

    onChatChangeResolution(newwidth:number,newheight:number) {
        this.setState({resolutionWidth: newwidth,resolutionHeight: newheight});
    }

    onChatStopBroadcast() {
        this.setState({broadcasting: false});
        this.cleanupSoupProducers();
    }

    onChatPrivateShowStarted() {
        this.chatMessage("Private Show wurde gestartet","chat-featuremessage");
        this.setState({privateShow: true});
    }

    onChatPrivateShowStopped() {
        this.chatMessage("Private Show wurde beendet","chat-featuremessage");
        this.setState({privateShow: false});
    }

    onChatFeatureStatus(data:any) {
        this.setState({soundEnabled: data.soundEnabled});
        this.setState({lovenseEnabled: data.lovenseEnabled});
        this.setState({dildoEnabled: data.dildoEnabled});
    }

    onChatTrinkgeld(coins:number,userPseudo:string) {
        if(this.context.language === "de") {
            this.chatMessage(userPseudo + " hat Dir ein Trinkgeld von " + coins + " Coins gesendet","chat-bonusmessage");
        } else {
            this.chatMessage(userPseudo + " has sent you a tip of " + coins + " Coins","chat-bonusmessage");
        }
    }

    connectChatServer() {
        this.chatMessage(this.getText("GettingChatData") + this.context.loginData.pseudo,"chat-sysmessage");
        this.setState({socketConnecting: true});

        let SoftwareVersion = globSoftwareVersion;

        if(this.state.isElectron) {
            SoftwareVersion += "s";
        } else {
            SoftwareVersion += "b";
        }

        let producerID = this.context.loginData.producerID;

        let jsonurl = "https://guppy.site/SenderToolWebRTC.aspx?Action=LoggedIn&ProducerID=" + encodeURIComponent(producerID) + "&SoftwareVersion=" + encodeURIComponent(SoftwareVersion) + "&Price=" + encodeURIComponent(this.context.chatOptions.price);
        this.debuglog(jsonurl);

        const axios = require('axios').default;
        const cryptoRandomString = require('crypto-random-string');

        axios.get(jsonurl).then((response:AxiosResponse) => {
            let data = response.data;

            let soupServer1 = "https://soup04.guppy.live:3000";
            let soupServer2 = "https://soup05.guppy.live:3000";
            let soupServer3 = "https://soup06.guppy.live:3000";

            if(data.soupServer !== "" && typeof(data.soupServer) !== "undefined") {
                soupServer1 = data.soupServer;
                if(typeof(data.soupServer2) !== "undefined") {
                    soupServer2 = data.soupServer2;
                }
                if(typeof(data.soupServer3) !== "undefined") {
                    soupServer3 = data.soupServer3;
                }

                if(this.Client) {
                    this.Client.soupServer = soupServer1;
                    this.Client.secondSoupServer = soupServer2;
                    this.Client.thirdSoupServer = soupServer3;

                    if(this.context) {
                        this.context.enableSoup1 = true;
                        this.context.enableSoup2 = true;
                    }

                    if(this.Client.myPeerId === "") {
                        this.Client.myPeerId = this.Client.uuidv4();
                    }
                    if(this.Client.secondPeerID === "") {
                        this.Client.secondPeerID = this.Client.uuidv4();
                    }
                    if(this.Client.thirdPeerID === "") {
                        this.Client.thirdPeerID = this.Client.uuidv4();
                    }

                    //this.chatMessage("Setting up mediasoup with clientID " + this.Client.myPeerId);
                    if(this.context?.enableSoup1) {
                        this.Client.main();
                        this.setState({soup1Initialized: true});
                    }
                    if(this.context?.enableSoup2) {
                        this.Client.createSecondDevice(soupServer2,this.Client.secondPeerID);
                        this.setState({soup2Initialized: true});
                    }
                    if(this.context?.enableSoup3) {
                        this.Client.createThirdDevice(soupServer3,this.Client.thirdPeerID);
                        this.setState({soup3Initialized: true});
                    }

                    this.initSoup();
                }
            }

            let chatServerString:String = new String(data.chatServer);

            chatServerString = chatServerString.replace("https://","");
            chatServerString = chatServerString.replace(".guppy.live","");
            chatServerString = chatServerString.replace(":3000","");
            chatServerString = chatServerString.replace("chat","");

            this.setState({chatServer: chatServerString});

            //this.chatMessage("Setting up socket.io...");
            this.socket = io(data.chatServer);
            if(this.socket === null) {
                this.chatMessage("socket.io not available!","errormsg");
                return;
            }
            this.socket.on("connect",this.onSocketConnect.bind(this));
            this.socket.on("connect_error",this.onSocketConnectError.bind(this));
            this.socket.on("connect_timeout",this.onSocketConnectTimeout.bind(this));
            this.socket.on("disconnect",this.onSocketDisconnect.bind(this));

            this.socket.on("userinfos",this.onChatUserInfos.bind(this));
            this.socket.on("connectFeedback",this.onChatConnectFeedback.bind(this));
            this.socket.on("startBroadcast",this.onChatStartBroadcast.bind(this));
            this.socket.on("stopBroadcast",this.onChatStopBroadcast.bind(this));
            this.socket.on("changeResolution",this.onChatChangeResolution.bind(this));
            this.socket.on("privateShowStarted",this.onChatPrivateShowStarted.bind(this));
            this.socket.on("privateShowStopped",this.onChatPrivateShowStopped.bind(this));

            this.socket.on("trinkgeld",this.onChatTrinkgeld.bind(this));

            this.socket.on("featureStatus",this.onChatFeatureStatus.bind(this));
            this.socket.on("featureRequest",this.onChatFeatureRequest.bind(this));
            this.socket.on("featureRequest2",this.onChatFeatureRequest2.bind(this));
            this.socket.on("featureStop",this.onChatFeatureStop.bind(this));
            this.socket.on("featureStop2",this.onChatFeatureStop2.bind(this));
            this.socket.on("numUsersWithFeature",this.onChatNumUsersWithFeature.bind(this));

            this.socket.on("dildospeed",this.onChatDildospeed.bind(this));
            this.socket.on("lovensespeed",this.onChatLovensespeed.bind(this));
            this.socket.on("numUsersOnSoup",this.onChatNumUsersOnSoup.bind(this));
            this.socket.on("usermic-guid",this.onChatUserMicGUID.bind(this));
            this.socket.on("c2c-guid",this.onChatC2CGUID.bind(this));
            this.socket.on("c2c-callid",this.onChatC2CCallID.bind(this));
            this.socket.on("c2c-streamname",this.onChatC2CStreamName.bind(this));
            this.socket.on("usermic-streamname",this.onChatUserMicStreamname.bind(this));
            this.socket.on("cam2cam-soup",this.onChatCam2CamSoup.bind(this));
            this.socket.on("usermic-soup",this.onChatUserMicSoup.bind(this));
            this.socket.on("stop-usermic",this.onChatStopUserMic.bind(this));
            this.socket.on("userEnter",this.onChatUserEnter.bind(this));
            this.socket.on("userPing",this.onChatUserPing.bind(this));
            this.socket.on("userLeave",this.onChatUserLeave.bind(this));
            this.socket.on("message",this.onChatMessage.bind(this));

            //this.chatMessage("Connecting to " + data.chatServer,"chat-sysmessage");
            this.socket.open();
        })
        .catch((error:Error) => {
            this.setState({errorMessage: error.toString()});
            this.setState({socketConnecting: false});
        });
    }

    onChatFeatureRequest(feature:string,userPseudo:string,guid:string) {
        if(feature === "dildo" && this.state.dildoActive) {
            this.denyFeature(feature,userPseudo,guid);
            return;
        }
        if(feature === "privateSession" && this.state.privateSessionEnabled) {
            this.denyFeature(feature,userPseudo,guid);
            return;
        }
        if(feature === "lovense" && this.state.lovenseActive) {
            this.denyFeature(feature,userPseudo,guid);
            return;
        }
        this.chatMessage(userPseudo + " " + this.getText("fragt nach") + " " + this.getHumanStringForFeature(feature),"chat-request");
        this.buildRequestButton(feature,userPseudo,guid);
        if(!this.context.chatOptions.noFeatureRequestSound) {
            this.playFeatureRequestSound();
        }
    }

    onChatFeatureRequest2(feature:string,userPseudo:string,guid:string,isInvisible:boolean) {
        if(isInvisible) {
            let producerID = this.context.loginData.producerID;

            if(this.socket !== null) {
                if(feature === "sound") {
                    this.socket?.emit("featureResponse",{
                        feature: feature,
                        userPseudo: userPseudo,
                        answer: "yes",
                        guid: guid,
                        producerID: producerID
                    });
                } else {
                    this.socket?.emit("featureResponse",{
                        feature: feature,
                        userPseudo: userPseudo,
                        answer: "no",
                        guid: guid,
                        producerID: producerID
                    });
                }
            }
        } else {
            this.chatMessage(userPseudo + " " + this.getText("fragt nach") + " " + this.getHumanStringForFeature(feature),"chat-request");
            this.buildRequestButton(feature,userPseudo,guid);
            if(!this.context.chatOptions.noFeatureRequestSound) {
                this.playFeatureRequestSound();
            }
        }
    }

    onChatFeatureStop(feature:string,userPseudo:string,guid:string) {
        this.handleFeatureStopFromUser(feature,userPseudo,guid,false);
    }

    // guid ist die flashChatSessionID des Users
    onChatFeatureStop2(feature:string,userPseudo:string,guid:string,isInvisible:boolean) {
        this.handleFeatureStopFromUser(feature,userPseudo,guid,isInvisible);
    }

    onChatDildospeed(speed:number,userPseudo:string) {
        /*if(userPseudo !== "" && typeof(userPseudo) !== "undefined") {
            this.chatMessageDebug(userPseudo + " -> dildospeed - " + speed);
        }*/
        if(this.state.dildoPortOpen) {
            this.setDildoSpeed(speed);
        } else {
            this.chatMessage(userPseudo + " " + this.getText("wollte den Dildo kontrollieren, aber der ist nicht aktiviert") + "...","chat-featuremessage");
        }
    }

    onChatNumUsersOnSoup(myUsersOnSoupInfo:usersOnSoupInfo) {
        // not implemented
    }

    onChatLovensespeed(speed:number,userPseudo:string) {
        /*if(userPseudo !== "" && typeof(userPseudo) !== "undefined") {
            this.chatMessageDebug(userPseudo + " -> lovensespeed - " + speed);
        }*/
        if(this.state.lovenseEnabled) {
            this.setLovenseSpeed(this.state.primaryLovenseDevice,speed);
        } else {
            this.chatMessage(userPseudo + " " + this.getText("wollte Lovense kontrollieren, aber das ist nicht aktiviert") + "...","chat-featuremessage");
        }
    }

    onChatNumUsersWithFeature(num:number,feature:string) {
        if(this.context.loginData.pseudo.indexOf("Testsender") > -1) {
            this.sysMessage("numUsersWithFeature " + num + " | feature: " + feature);
        }

        if(feature === "lovense") {
            if(num > 0) {
                this.setState({lovenseActive: true});
            } else {
                this.setState({lovenseActive: false});
                this.setLovenseSpeed(this.state.primaryLovenseDevice,0);
            }
        } else if(feature === "dildo") {
            if(num > 0) {
                this.setState({dildoActive: true});
            } else {
                this.setState({dildoActive: false});
                this.setDildoSpeed(0);
            }
        } else if(feature === "sound") {
            if(num > 0) {
                this.setState({soundActive: true});
                this.handleSoundActiveChange();
            } else {
                this.setState({soundActive: false});
                this.handleSoundActiveChange();
            }
        } else if(feature === "privateSession") {
            if(num > 0) {
                this.setState({privateSessionEnabled: true});
            } else {
                this.setState({privateSessionEnabled: false});
            }
        } else if(feature === "multiPrivate") {
            if(num > 0) {
                this.setState({multiPrivateEnabled: true});
            } else {
                this.setState({multiPrivateEnabled: false});
            }
        }
    }

    onChatC2CGUID(myc2cinfo:cam2CamInfoParams) {
        // myc2cinfo.guid ist der Streamname
        this.sysMessage("Cam-2-Cam : " + myc2cinfo.pseudo + " | guid: " + myc2cinfo.guid + " | flashChatSessionID: " + myc2cinfo.flashChatSessionID);
        this.addCam2CamInfoGuid(myc2cinfo);
    }

    onChatC2CCallID(myc2cinfo:cam2CamInfoLivelyParams) {
        // myc2cinfo.guid ist der Streamname
        this.chatMessage("Cam-2-Cam : " + myc2cinfo.pseudo,"chat-sysmessage");
        this.sysMessage("Cam-2-Cam : " + myc2cinfo.pseudo + " | callid: " + myc2cinfo.callid + " | flashChatSessionID: " + myc2cinfo.flashChatSessionID);
        let newCam2CamInfo:cam2CamInfo = {
            id:0,
            pseudo:myc2cinfo.pseudo,
            guid:myc2cinfo.callid,
            streamType:"lively",
            flashChatSessionID:myc2cinfo.flashChatSessionID,
            playerUI:null,
            orderIndex: this.getNextCam2CamOrderIndex(),
            soupServer: ""
        };
        this.addCam2CamInfoLively(newCam2CamInfo);
    }

    onChatC2CStreamName(myc2cinfo:cam2CamInfoParams) {
        /*this.sysMessage("c2c pseudo : " + myc2cinfo.pseudo + " | streamname: " + myc2cinfo.guid + " | flashChatSessionID: " + myc2cinfo.flashChatSessionID + " | streamType: soup");
        let newCam2CamInfo:cam2CamInfo = {
            id:0,
            pseudo:myc2cinfo.pseudo,
            guid:myc2cinfo.guid,
            streamType:"soup",
            flashChatSessionID:myc2cinfo.flashChatSessionID,
            playerUI:null,
            orderIndex: this.getNextCam2CamOrderIndex(),
            soupServer: ""
        };
        this.addCam2CamInfo(newCam2CamInfo);*/

        let streamType:string = "central2";
        let soupServer:string = "https://soup05.guppy.live:3000";

        if(this.cam2camPeerId2 === "") {
            this.initCam2CamSoup2();
        }

        let newCam2CamInfo:cam2CamInfo = {
            id: 0,
            pseudo: myc2cinfo.pseudo,
            guid :myc2cinfo.guid,
            flashChatSessionID: myc2cinfo.flashChatSessionID,
            streamType: streamType,
            playerUI: null,
            orderIndex: this.getNextCam2CamOrderIndex(),
            soupServer: soupServer
        };

        if(this.context.loginData.pseudo.indexOf("Testsender") > -1) {
            this.chatMessage("Adding cam-2-cam from " + myc2cinfo.pseudo + " with type " + streamType + " and server " + soupServer + " peerId " + myc2cinfo.guid,"chat-sysmessage");
        }
        
        this.addCam2CamInfoSoupCentral2(newCam2CamInfo);

        this.logBroadcastAction("Adding cam-2-cam from " + myc2cinfo.pseudo + " with type " + streamType + " and server " + soupServer + " peerId " + myc2cinfo.guid);
    }

    onChatUserMicGUID(userMicInfo:userMicInfoGuidParams) {
        // userMicInfo.guid ist der Streamname
        this.sysMessage("c2c pseudo : " + userMicInfo.pseudo + " | guid: " + userMicInfo.guid + " | flashChatSessionID: " + userMicInfo.flashChatSessionID);
        this.addUserMicInfoGuid(userMicInfo);
    }

    onChatCam2CamSoup(myc2cinfo:cam2camInfoSoupParams) {
        let streamType:string = "newsoup";

        if(myc2cinfo.soupServer.indexOf("soup01") >= 0) {
            streamType = "central1";
            if(this.cam2camPeerId1 === "") {
                this.initCam2CamSoup1();
            }
        }
        if(myc2cinfo.soupServer.indexOf("soup05") >= 0) {
            streamType = "central2";
            if(this.cam2camPeerId2 === "") {
                this.initCam2CamSoup2();
            }
        }

        let newCam2CamInfo:cam2CamInfo = {
            id: 0,
            pseudo: myc2cinfo.pseudo,
            guid :myc2cinfo.peerId,
            flashChatSessionID: myc2cinfo.flashChatSessionID,
            streamType: streamType,
            playerUI: null,
            orderIndex: this.getNextCam2CamOrderIndex(),
            soupServer: myc2cinfo.soupServer
        };

        if(this.context.loginData.pseudo.indexOf("Testsender") > -1) {
            this.chatMessage("Adding cam-2-cam from " + myc2cinfo.pseudo + " with type " + streamType + " and server " + myc2cinfo.soupServer + " peerId " + myc2cinfo.peerId,"chat-sysmessage");
        }
        
        if(streamType === "newsoup") {
            this.addCam2CamInfoSoup(newCam2CamInfo);
        } else {
            if(streamType === "central1") {
                this.addCam2CamInfoSoupCentral1(newCam2CamInfo);
                if(this.cam2camPeerId1 === "") {
                    this.initCam2CamSoup1();
                }
            } else if(streamType === "central2") {
                this.addCam2CamInfoSoupCentral2(newCam2CamInfo);
                if(this.cam2camPeerId2 === "") {
                    this.initCam2CamSoup2();
                }
            }
        }

        this.logBroadcastAction("Adding cam-2-cam from " + myc2cinfo.pseudo + " with type " + streamType + " and server " + myc2cinfo.soupServer + " peerId " + myc2cinfo.peerId);
    }

    onChatUserMicSoup(myUserMicInfo:userMicInfoSoupParams) {
        /*let newUserMicInfo:userMicInfo = {
            id: 0,
            pseudo: myUserMicInfo.pseudo,
            guid :myUserMicInfo.peerId,
            flashChatSessionID: myUserMicInfo.flashChatSessionID,
            playerUI: null,
            soupServer: myUserMicInfo.soupServer
        };
        this.addUserMicInfoSoup(newUserMicInfo);*/

        let streamType:string = "newsoup";

        if(myUserMicInfo.soupServer.indexOf("soup01") >= 0) {
            streamType = "central1";
            if(this.cam2camPeerId1 === "") {
                this.initCam2CamSoup1();
            }
        }
        if(myUserMicInfo.soupServer.indexOf("soup05") >= 0) {
            streamType = "central2";
            if(this.cam2camPeerId2 === "") {
                this.initCam2CamSoup2();
            }
        }

        let newUserMicInfo:userMicInfo = {
            id:0,
            pseudo:myUserMicInfo.pseudo,
            guid:myUserMicInfo.peerId,
            flashChatSessionID:myUserMicInfo.flashChatSessionID,
            playerUI:null,
            soupServer: ""
        };

        if(this.context.loginData.pseudo.indexOf("Testsender") > -1) {
            this.chatMessage("Adding usermic from " + myUserMicInfo.pseudo + " with type " + streamType + " and server " + myUserMicInfo.soupServer + " peerId " + myUserMicInfo.peerId,"chat-sysmessage");
        }
        
        if(streamType === "newsoup") {
            this.addUserMicInfoSoup(newUserMicInfo);
        } else {
            if(streamType === "central1") {
                this.addUserMicInfoSoupCentral1(newUserMicInfo);
                if(this.cam2camPeerId1 === "") {
                    this.initCam2CamSoup1();
                }
            } else if(streamType === "central2") {
                this.addUserMicInfoSoupCentral2(newUserMicInfo);
                if(this.cam2camPeerId2 === "") {
                    this.initCam2CamSoup2();
                }
            }
        }

        this.logBroadcastAction("Adding usermic from " + myUserMicInfo.pseudo + " with type " + streamType + " and server " + myUserMicInfo.soupServer + " peerId " + myUserMicInfo.peerId);
    }

    onChatUserMicStreamname(myUserMicInfo:userMicInfoParams) {
        /*if(new String(myUserMicInfo.guid).indexOf('|') > 0) {
            let myArr:string[] = new String(myUserMicInfo.guid).split("|");

            let newUserMicInfo2:userMicInfo = {
                id: 0,
                pseudo: myUserMicInfo.pseudo,
                guid: myArr[0],
                flashChatSessionID: myUserMicInfo.flashChatSessionID,
                playerUI: null,
                soupServer: myArr[1]
            };
            this.addUserMicInfoSoup(newUserMicInfo2);
            return;
        }

        let newUserMicInfo:userMicInfo = {
            id:0,
            pseudo:myUserMicInfo.pseudo,
            guid:myUserMicInfo.guid,
            flashChatSessionID:myUserMicInfo.flashChatSessionID,
            playerUI:null,
            soupServer: ""
        };
        this.addUserMicInfo(newUserMicInfo);*/

        let soupServer:string = "https://soup05.guppy.live:3000";
        let userPeerId:string = myUserMicInfo.guid;

        if(new String(myUserMicInfo.guid).indexOf('|') > 0) {
            let myArr:string[] = new String(myUserMicInfo.guid).split("|");
            userPeerId = myArr[0];
            soupServer = myArr[1];
        }

        let streamType:string = "central2";

        if(soupServer.indexOf("soup01") >= 0) {
            streamType = "central1";
            if(this.cam2camPeerId1 === "") {
                this.initCam2CamSoup1();
            }
        }
        if(soupServer.indexOf("soup05") >= 0) {
            streamType = "central2";
            if(this.cam2camPeerId2 === "") {
                this.initCam2CamSoup2();
            }
        }

        let newUserMicInfo:userMicInfo = {
            id:0,
            pseudo:myUserMicInfo.pseudo,
            guid:userPeerId,
            flashChatSessionID:myUserMicInfo.flashChatSessionID,
            playerUI:null,
            soupServer: ""
        };

        if(this.context.loginData.pseudo.indexOf("Testsender") > -1) {
            this.chatMessage("Adding usermic from " + myUserMicInfo.pseudo + " with type " + streamType + " and peerId " + userPeerId + " on server " + soupServer,"chat-sysmessage");
        }
        
        if(streamType === "central1") {
            this.addUserMicInfoSoupCentral1(newUserMicInfo);
            if(this.cam2camPeerId1 === "") {
                this.initCam2CamSoup1();
            }
        } else if(streamType === "central2") {
            this.addUserMicInfoSoupCentral2(newUserMicInfo);
            if(this.cam2camPeerId2 === "") {
                this.initCam2CamSoup2();
            }
        }

        this.logBroadcastAction("Adding usermic from " + myUserMicInfo.pseudo + " with type " + streamType + " and peerId " + userPeerId + " on server " + soupServer);
    }

    onChatStopUserMic(myUserMicInfo:userMicInfoParams) {
        this.chatMessage(myUserMicInfo.pseudo + " " + this.getText("stops") + " UserMic","chat-featuremessage");
        this.sysMessage("stop-usermic : " + myUserMicInfo.pseudo + " | streamname: " + myUserMicInfo.guid + " | flashChatSessionID: " + myUserMicInfo.flashChatSessionID);
        this.removeUserMicInfo(myUserMicInfo.flashChatSessionID);
    }

    onChatUserEnter(userInfo:userInfo) {
        if(!userInfo.isInvisible) {
            if(userInfo.userType === "voyeur" || userInfo.userType === "chatvoyeur" || userInfo.userType === "voyeurcns24") {
                let userPseudo = "Voyeur";

                if(!this.context.chatOptions.noUserEnterSound) {
                    this.playUserEnterSound();
                }
                this.chatMessage(userPseudo + " " + this.getText("betritt den Chat") + "...","chat-enter");

                this.addUser(userInfo);
                this.logBroadcastAction("UserEnter " + userPseudo + " " + userInfo.userType);
            } else {
                let userPseudo = userInfo.pseudo;

                if(!this.context.chatOptions.noUserEnterSound) {
                    this.playUserEnterSound();
                }
                this.chatMessage(userPseudo + " " + this.getText("betritt den Chat") + "...","chat-enter");
        
                if(this.context.chatOptions.price > 0) {
                    this.sendWelcomeMessage(userInfo.pseudo);
                }

                userInfo.updateNotes = true;
                this.addUser(userInfo);
                this.logBroadcastAction("UserEnter " + userPseudo + " " + userInfo.userType);
            }
        }

        if(this.backupStreamPaused) {
            this.resumeBackupProducer();
        }
    }

    onChatUserPing(guid:string) {
        this.userPings[guid] = new Date();
    }

    onChatUserLeave(guid:string) {
        let myPseudo = "unknown[" + guid + "]";
        let pseudoFound = false;

        this.state.users.forEach(obj => {
            if(guid === obj.guid) {
                myPseudo = obj.pseudo;
                pseudoFound = true;
            }
        });

        if(this.chatTargetClient !== null) {
            if(this.chatTargetClient.guid === guid) {
                this.setState({
                    chatTargetPseudo: ""
                });
                this.chatTargetClient = null;
            }
        }

        if(pseudoFound && myPseudo !== "undefined" && typeof(myPseudo) !== "undefined") {
            if(!this.context.chatOptions.noUserLeaveSound) {
                this.playUserLeaveSound();
            }
            this.chatMessage(myPseudo + " " + this.getText("hat den Chat verlassen") + "...","chat-leave");
            this.cleanupUserFeatures(myPseudo);
            this.logBroadcastAction("UserLeave " + myPseudo);
        }

        this.removeUserFromList(guid);
    }

    onChatMessage(msg:chatMessage) {
        if(msg.to === "system") {
            return;
        }

        //sysMessage("chatmessage: " + msg.from + " : " + msg.msg);
        if(msg.from === this.context.loginData.pseudo) {
            if(msg.to === "") {
                this.chatMessageTwoPart(msg.msg,"",msg.from + " " + this.getText("to") + " " + this.getText("Alle"),"chat-from-girl");
            } else {
                this.chatMessageTwoPart(msg.msg,"",msg.from + " " + this.getText("to") + " " + msg.to,"chat-from-girl");
            }
        } else if(msg.from === "Debug") {
            if(this.showDebugMessages) {
                let myUser:userInfo = this.findUser(msg.from);

                if(myUser) {
                    this.chatMessageTwoPart(msg.msg,"debugmsg",msg.from,"chat-from-user" + ((myUser.id % 3) + 1));
                } else {
                    this.chatMessageTwoPart(msg.msg,"debugmsg",msg.from,"chat-from-user1");
                }
            }
        } else {
            let myUser:userInfo = this.findUser(msg.from);

            if(myUser) {
                this.chatMessageTwoPart(msg.msg,"",msg.from,"chat-from-user" + ((myUser.id % 3) + 1));
            } else {
                this.chatMessageTwoPart(msg.msg,"",msg.from,"chat-from-user1");
            }
        }

        if(!this.context.chatOptions.noChatMessageSound) {
            this.playChatMessageSound();
        }
    }

    async initSoup() {
        let Client = this.Client;

        if(Client === null) {
            this.chatMessage("soupClient is null!","chat-errormessage");
            return;
        }

        let soupServerString:String = new String(Client.soupServer);

        soupServerString = soupServerString.replace("https://","");
        soupServerString = soupServerString.replace(":3000","");
        soupServerString = soupServerString.replace(".guppy.live","");
        soupServerString = soupServerString.replace("soup","");

        if(Client.joined) {
            this.sysMessage("MediaStream already connected " + soupServerString + " | peerID: " + Client.myPeerId);
            this.setState({soup1Joined: true});
        } else {
            if(this.context.enableSoup1) {
                await Client.joinRoom();
                this.sysMessage("Connected to MediaStream server 1 " + soupServerString + " | peerID: " + Client.myPeerId);
                this.setState({soup1Joined: true});
            } else {
                this.sysMessage("Soup1 not enabled");
                this.setState({soup1Joined: false});
            }
        }

        soupServerString = new String(Client.secondSoupServer);

        soupServerString = soupServerString.replace("https://","");
        soupServerString = soupServerString.replace(":3000","");
        soupServerString = soupServerString.replace(".guppy.live","");
        soupServerString = soupServerString.replace("soup","");

        if(Client.secondJoined) {
            this.sysMessage("MediaStream already connected " + soupServerString + " | peerID: " + Client.secondPeerID);
            this.setState({soup2Joined: true});
        } else {
            if(this.context.enableSoup2) {
                await Client.joinSecondRoom();
                this.sysMessage("Connected to MediaStream server 2 " + soupServerString + " | peerID: " + Client.secondPeerID);
                this.setState({soup2Joined: true});
            } else {
                this.sysMessage("Soup2 not enabled");
                this.setState({soup2Joined: false});
            }
        }

        soupServerString = new String(Client.thirdSoupServer);

        soupServerString = soupServerString.replace("https://","");
        soupServerString = soupServerString.replace(":3000","");
        soupServerString = soupServerString.replace(".guppy.live","");
        soupServerString = soupServerString.replace("soup","");

        if(Client.thirdJoined) {
            this.sysMessage("MediaStream already connected " + soupServerString + " | peerID: " + Client.thirdPeerID);
            this.setState({soup3Joined: true});
        } else {
            if(this.context.enableSoup3) {
                await Client.joinThirdRoom();
                this.sysMessage("Connected to MediaStream server 3 " + soupServerString + " | peerID: " + Client.thirdPeerID);
                this.setState({soup3Joined: true});
            } else {
                //this.sysMessage("Soup3 not enabled");
                this.setState({soup3Joined: false});
            }
        }

        if(!this.checkSoupConnectionsTimerStarted) {
            this.checkSoupConnectionsTimerStarted = true;
            this.checkSoupConnectionsTimer = setInterval(this.checkSoupConnections.bind(this),10000);
        }

        //this.socket.emit("set-webrtc-info",webrtcRoom,myWebrtcPeerID);
        //this.chatMessage("Mediasoup status 1/2/3 " + this.Client.device.loaded + "/" + this.Client.secondDevice.loaded + "/" + this.Client.thirdDevice.loaded);
    }



    async checkSoupConnections() {
        if(!this.state.chatConnected) {
            return;
        }

        let doRestartSoupProducers:boolean = false;

        // JP 14.11.2024 - check if the backup stream is still active only if backupDevice is not null
        if(this.backupDevice !== null) {
            if(this.sendTransport !== null) {
                this.setState({backSoupTransportConnState: this.sendTransport.connectionState});

                if(this.sendTransport.connectionState === "failed" || this.sendTransport.connectionState === "disconnected" || this.sendTransport.connectionState === "closed") {
                    this.sysMessage("Backup sendTransport " + this.sendTransport.connectionState);
                    this.logBroadcastAction("Backup sendTransport " + this.sendTransport.connectionState);
                    this.setState({soup1VideoProducing: false});
                    this.setState({soup1AudioProducing: false});

                    doRestartSoupProducers = true;
                }
            } else {
                this.setState({backSoupTransportConnState: "null"});
                doRestartSoupProducers = true;
            }
        }

        if(this.videoProducer === null) {
            this.setState({soup1VideoProducing: false});
            doRestartSoupProducers = true;
        }

        if(this.audioProducer === null) {
            this.setState({soup1AudioProducing: false});
        }

        if(doRestartSoupProducers) {
            this.logBroadcastAction("Restarting video backup...");
            this.initBackupSoup();
        } else {
            if(this.state.users.length > 0) {
                this.consecutiveNoUsers = 0;
            } else {
                this.consecutiveNoUsers++;
                if(!this.backupStreamPaused && this.consecutiveNoUsers >= 3) {
                    this.pauseBackupProducer();
                }
            }
        }

        let Client = this.Client;

        // das sind die 3 soup Connections die wir für's Cam-2-Cam und UserMic brauchen
        if(Client === null) {
            this.sysMessage("soupClient is null");
            return;
        }

        if(Client.secondJoined) {
            this.setState({soup2Joined: true});
        } else {
            if(this.context.enableSoup2) {
                await Client.joinSecondRoom();
                this.setState({soup2Joined: true});
                this.sysMessage("Reconnected to MediaStream server 2 " + Client.secondSoupServer + " | peerID: " + Client.secondPeerID);
                this.logBroadcastAction("Reconnected to MediaStream server 2 " + Client.secondSoupServer + " | peerID: " + Client.secondPeerID);
            } else {
                this.setState({soup2Joined: false});
            }
        }

        if(Client.thirdJoined) {
            this.setState({soup3Joined: true});
        } else {
            if(this.context.enableSoup3) {
                await Client.joinThirdRoom();
                this.setState({soup3Joined: true});
                this.sysMessage("Reconnected to MediaStream server 3 " + Client.thirdSoupServer + " | peerID: " + Client.thirdPeerID);
                this.logBroadcastAction("Reconnected to MediaStream server 3 " + Client.thirdSoupServer + " | peerID: " + Client.thirdPeerID);
            } else {
                this.setState({soup3Joined: false});
            }
        }
    }

    addUser(userInfo:userInfo) {
        let guidFound = false;
        let array = [...this.state.users];

        for(let curUser of array) {
            if(curUser.guid === userInfo.guid) {
                guidFound = true;
                break;
            }
        }

        if(!guidFound) {
            this.globalUserCounter++;
            userInfo.id = this.globalUserCounter;
            this.setState({users: [...this.state.users,userInfo]});
            this.updateVoyeurInfoText();
        }

        this.userPings[userInfo.guid] = new Date();
    }

    updateVoyeurInfoText():void {
        let numVoyeur:number = this.getNumVoyeurUsers();

        if(numVoyeur > 0) {
            if(this.context.language == "de") {
                if(numVoyeur === 1) {
                    this.setState({voyeurInfoText:"Es befindet sich " + numVoyeur + " Voyeur im Chat"});
                } else {
                    this.setState({voyeurInfoText:"Es befinden sich " + numVoyeur + " Voyeure im Chat"});
                }
            } else {
                if(numVoyeur === 1) {
                    this.setState({voyeurInfoText:"There is an active voyeur in your chatroom"});
                } else {
                    this.setState({voyeurInfoText:"There are " + numVoyeur + " active voyeurs in your chatroom"});
                }
            }
        } else {
            this.setState({voyeurInfoText:""});
        }
    }

    findUser(pseudo:string):userInfo {
        let array = [...this.state.users];

        for(let curUser of array) {
            if(curUser.pseudo.toLocaleLowerCase() === pseudo.toLocaleLowerCase()) {
                return curUser;
            }
        }

        return null as unknown as userInfo;
    }

    removeUserFromList(guid:string) {
        /*let rmIndex = -1;
        let counter = -1;
        let rmPseudo = "";
        let array = [...this.state.users];

        for(let curUser of array) {
            counter++;
            if(curUser.guid === guid) {
                rmPseudo = curUser.pseudo;
                rmIndex = counter;
                break;
            }
        }

        if(rmIndex > -1) {
            this.chatMessage("Remove " + array[rmIndex].pseudo,"");
            array.splice(rmIndex,1);
            this.chatMessage("New userlist...","");
            for(let curUser of array) {
                this.chatMessage(curUser.pseudo,"");
            }
            this.chatMessage("End new userlist...","");
            this.setState((prevState:chatInterfaceState) => ({users: array}));
        }*/

        this.setState((prevState:chatInterfaceState) => {
            const list = prevState.users.filter(item => item.guid !== guid);
       
            return {
                users: list
            };
        });

        if(this.chatTargetClient !== null) {
            if(this.chatTargetClient.guid === guid) {
                this.setState({
                    chatTargetPseudo: ""
                });
                this.chatTargetClient = null;
            }
        }

        this.updateVoyeurInfoText();
        this.removeFeatureRequestsForUser(guid);
    }

    removeFeatureRequestsForUser(guid:string) {
        this.setState((prevState:chatInterfaceState) => {
            const list = prevState.featureRequests.filter(item => item.guid !== guid);
       
            return {
                featureRequests: list
            };
        });
    }

    getNextCam2CamOrderIndex():number {
        return this.state.cam2CamInfos.length + this.state.cam2CamInfosLively.length + this.state.cam2CamInfosSoup.length + this.state.cam2CamInfosSoupCentral1.length + this.state.cam2CamInfosSoupCentral2.length;
    }

    addCam2CamInfo(myc2cinfo:cam2CamInfo) {
        this.cam2camInfoCounter++;
        myc2cinfo.id = this.cam2camInfoCounter;
        this.setState({cam2CamInfos: [...this.state.cam2CamInfos,myc2cinfo]});
    }

    removeCam2CamInfo(flashChatSessionID:string) {
        this.setState((prevState:chatInterfaceState) => {
            const list = prevState.cam2CamInfos.filter(item => item.flashChatSessionID !== flashChatSessionID);
       
            return {
                cam2CamInfos: list
            };
        });
        setTimeout(this.reorderCam2CamInfos.bind(this),1000);
    }

    addCam2CamInfoSoup(myc2cinfo:cam2CamInfo) {
        this.cam2camInfoCounter++;
        myc2cinfo.id = this.cam2camInfoCounter;
        this.setState({cam2CamInfosSoup: [...this.state.cam2CamInfosSoup,myc2cinfo]});
    }

    removeCam2CamInfoSoup(flashChatSessionID:string) {
        this.setState((prevState:chatInterfaceState) => {
            const list = prevState.cam2CamInfosSoup.filter(item => item.flashChatSessionID !== flashChatSessionID);
       
            return {
                cam2CamInfosSoup: list
            };
        });
        setTimeout(this.reorderCam2CamInfos.bind(this),1000);
    }

    addCam2CamInfoSoupCentral1(myc2cinfo:cam2CamInfo) {
        this.cam2camInfoCounter++;
        myc2cinfo.id = this.cam2camInfoCounter;
        this.setState({cam2CamInfosSoupCentral1: [...this.state.cam2CamInfosSoupCentral1,myc2cinfo]});
    }

    removeCam2CamInfoSoupCentral1(flashChatSessionID:string) {
        this.setState((prevState:chatInterfaceState) => {
            const list = prevState.cam2CamInfosSoupCentral1.filter(item => item.flashChatSessionID !== flashChatSessionID);
       
            return {
                cam2CamInfosSoupCentral1: list
            };
        });
        setTimeout(this.reorderCam2CamInfos.bind(this),1000);
    }

    addCam2CamInfoSoupCentral2(myc2cinfo:cam2CamInfo) {
        this.cam2camInfoCounter++;
        myc2cinfo.id = this.cam2camInfoCounter;
        this.setState({cam2CamInfosSoupCentral2: [...this.state.cam2CamInfosSoupCentral2,myc2cinfo]});
    }

    removeCam2CamInfoSoupCentral2(flashChatSessionID:string) {
        this.setState((prevState:chatInterfaceState) => {
            const list = prevState.cam2CamInfosSoupCentral2.filter(item => item.flashChatSessionID !== flashChatSessionID);
       
            return {
                cam2CamInfosSoupCentral2: list
            };
        });
        setTimeout(this.reorderCam2CamInfos.bind(this),1000);
    }

    reorderCam2CamInfos() {
        this.setState((prevState:chatInterfaceState) => {
            let myOrderIndex:number = 0;
            let list = prevState.cam2CamInfos;
            list.forEach(item => {
                item.orderIndex = myOrderIndex;
                myOrderIndex++;
            });
            let list2 = prevState.cam2CamInfosLively;
            list2.forEach(item => {
                item.orderIndex = myOrderIndex;
                myOrderIndex++;
            });
            let list3 = prevState.cam2CamInfosSoup;
            list3.forEach(item => {
                item.orderIndex = myOrderIndex;
                myOrderIndex++;
            });
            let list4 = prevState.cam2CamInfosSoupCentral1;
            list4.forEach(item => {
                item.orderIndex = myOrderIndex;
                myOrderIndex++;
            });
            let list5 = prevState.cam2CamInfosSoupCentral2;
            list4.forEach(item => {
                item.orderIndex = myOrderIndex;
                myOrderIndex++;
            });
       
            return {
                cam2CamInfos: list,
                cam2CamInfosLively: list2,
                cam2CamInfosSoup: list3,
                cam2CamInfosSoupCentral1: list4,
                cam2CamInfosSoupCentral2: list5
            };
        });
    }

    addUserMicInfoGuid(myc2cinfo:cam2CamInfoGuidParams) {
        this.userMicInfoGuids.push(myc2cinfo);
    }

    removeUserMicInfoGuid(guid:string) {
        this.userMicInfoGuids = this.userMicInfoGuids.filter(item => item.guid !== guid);
    }

    addCam2CamInfoGuid(myc2cinfo:cam2CamInfoGuidParams) {
        this.cam2camInfoGuids.push(myc2cinfo);
    }

    removeCam2CamInfoGuid(guid:string) {
        this.cam2camInfoGuids = this.cam2camInfoGuids.filter(item => item.guid !== guid);
    }

    addCam2CamInfoLively(myc2cinfo:cam2CamInfo) {
        this.cam2camInfoCounter++;
        myc2cinfo.id = this.cam2camInfoCounter;
        this.setState({cam2CamInfosLively: [...this.state.cam2CamInfosLively,myc2cinfo]});
    }

    removeCam2CamInfoLively(flashChatSessionID:string) {
        this.setState((prevState:chatInterfaceState) => {
            const list = prevState.cam2CamInfosLively.filter(item => item.flashChatSessionID !== flashChatSessionID);
       
            return {
                cam2CamInfosLively: list
            };
        });
    }

    addUserMicInfo(myUserMicInfo:userMicInfo) {
        this.userMicCounter++;
        myUserMicInfo.id = this.userMicCounter;
        this.setState({userMicInfos: [...this.state.userMicInfos,myUserMicInfo]});
    }

    removeUserMicInfo(flashChatSessionID:string) {
        this.setState((prevState:chatInterfaceState) => {
            const list = prevState.userMicInfos.filter(item => item.flashChatSessionID !== flashChatSessionID);
            const list2 = prevState.userMicInfosLively.filter(item => item.flashChatSessionID !== flashChatSessionID);
            const list3 = prevState.userMicInfosSoup.filter(item => item.flashChatSessionID !== flashChatSessionID);
            const list4 = prevState.userMicInfosSoupCentral1.filter(item => item.flashChatSessionID !== flashChatSessionID);
            const list5 = prevState.userMicInfosSoupCentral2.filter(item => item.flashChatSessionID !== flashChatSessionID);
       
            return {
                userMicInfos: list,
                userMicInfosLively: list2,
                userMicInfosSoup: list3,
                userMicInfosSoupCentral1: list4,
                userMicInfosSoupCentral2: list5
            };
        });

        /*let rmIndex = -1;
        let counter = -1;
        let rmPseudo = "";
        let array = [...this.state.userMicInfos];

        for(let curObject of array) {
            counter++;
            if(curObject.flashChatSessionID === flashChatSessionID) {
                rmPseudo = curObject.pseudo;
                rmIndex = counter;
                break;
            }
        }

        if(rmIndex > -1) {
            array.splice(rmIndex,1);
            this.setState({userMicInfos: array});
        }*/
    }

    addUserMicInfoSoup(myMicInfo:userMicInfo) {
        this.userMicCounter++;
        myMicInfo.id = this.userMicCounter;
        this.setState({userMicInfosSoup: [...this.state.userMicInfosSoup,myMicInfo]});
    }

    removeUserMicInfoSoup(flashChatSessionID:string) {
        this.setState((prevState:chatInterfaceState) => {
            const list = prevState.userMicInfosSoup.filter(item => item.flashChatSessionID !== flashChatSessionID);
       
            return {
                userMicInfosSoup: list
            };
        });
    }

    addUserMicInfoSoupCentral1(myMicInfo:userMicInfo) {
        this.userMicCounter++;
        myMicInfo.id = this.userMicCounter;
        this.setState({userMicInfosSoupCentral1: [...this.state.userMicInfosSoupCentral1,myMicInfo]});
    }

    removeUserMicInfoSoupCentral1(flashChatSessionID:string) {
        this.setState((prevState:chatInterfaceState) => {
            const list = prevState.userMicInfosSoupCentral1.filter(item => item.flashChatSessionID !== flashChatSessionID);
       
            return {
                userMicInfosSoupCentral1: list
            };
        });
    }

    addUserMicInfoSoupCentral2(myMicInfo:userMicInfo) {
        this.userMicCounter++;
        myMicInfo.id = this.userMicCounter;
        this.setState({userMicInfosSoupCentral2: [...this.state.userMicInfosSoupCentral2,myMicInfo]});
    }

    removeUserMicInfoSoupCentral2(flashChatSessionID:string) {
        this.setState((prevState:chatInterfaceState) => {
            const list = prevState.userMicInfosSoupCentral2.filter(item => item.flashChatSessionID !== flashChatSessionID);
       
            return {
                userMicInfosSoupCentral2: list
            };
        });
    }

    addUserMicInfoLively(myMicInfo:userMicInfo) {
        this.userMicCounter++;
        myMicInfo.id = this.userMicCounter;
        this.setState({userMicInfosLively: [...this.state.userMicInfosLively,myMicInfo]});
    }

    removeUserMicInfoLively(flashChatSessionID:string) {
        this.setState((prevState:chatInterfaceState) => {
            const list = prevState.userMicInfosLively.filter(item => item.flashChatSessionID !== flashChatSessionID);
       
            return {
                userMicInfosLively: list
            };
        });
    }

    setBroadcastingTrue() {
        this.setState({ broadcasting: true });
        this.logBroadcastAction("Enabling broadcast");
        setTimeout(this.checkBroadcast.bind(this),2500);
    }

    async checkBroadcast() {
        if(this.state.manifestUrl === "") {
            this.setState({manifestCheckState: "Fetching Token"});

            let pseudo = this.context.loginData.pseudo;

            let newManifestUrl:string = await this.fetchToken("https://streamauth.guppy.live/FetchPlayerToken.aspx?SessionID=" + encodeURIComponent(this.state.callId) + "&Pseudo=" + encodeURIComponent(pseudo));

            if(newManifestUrl !== "") {
                await this.logBroadcastAction("Check Broadcast with new Manifest URL " + newManifestUrl);
                await this.tryToFetchManifest(newManifestUrl);
            } else {
                await this.logBroadcastAction("Check Broadcast could not get token for new manifestURL");
                this.setState({manifestCheckState: "Token Error"});
                this.setState({manifestCheckFailed: true});
                this.setState({manifestChecking: false});
                setTimeout(this.checkBroadcast.bind(this),8000);
            }
        }
    }

    async showOwnPlayer() {
        if(!this.state.showOwnPlayer) {
            this.setState({showOwnPlayer:true});
            if(this.state.manifestUrl === "") {
                this.chatMessageDebug("getting manifest: " + this.state.callId);
        
                let pseudo = this.context.loginData.pseudo;

                let myurl:string = "https://streamauth.guppy.live/FetchPlayerToken.aspx?SessionID=" + encodeURIComponent(this.state.callId) + "&Pseudo=" + encodeURIComponent(pseudo);

                if(this.state.stagingMode) {
                    myurl += "&staging=1";
                }
        
                let newManifestUrl:string = await this.fetchToken(myurl);
                this.logBroadcastAction("Test Player Manifest URL " + newManifestUrl);

                if(newManifestUrl !== "") {
                    await this.tryToFetchManifest(newManifestUrl);
                }
            }
        } else {
            this.setState({showOwnPlayer:false});
        }
    }

    fetchToken = async(url:string) => {
        const axios = require('axios').default;
    
        try {
            const response = await axios.get(url);
            let callId:String = this.state.callId;

            let manifestUrl:string;

            if(this.state.stagingMode) {
                manifestUrl = "https://manifest2.guppy-staging.devspace.lsea3.generflow.com/live/" + callId + ".json?accessToken=" + encodeURIComponent(response.data.token) + "&time=" + encodeURIComponent(new Date().getMilliseconds());
            } else {
                manifestUrl = "https://guppy-prod-euw1d-manifest2.generflow.com/live/" + callId + ".json?accessToken=" + encodeURIComponent(response.data.token) + "&time=" + encodeURIComponent(new Date().getMilliseconds());
            }

            this.setState({manifestUrl:manifestUrl});
            return manifestUrl;
        } catch (error) {
            console.error('ERROR:');
            console.error(error);
            this.setManifestState("Token Error");
            this.setState({manifestFailCount: this.state.manifestFailCount + 1});
            if(this.state.manifestFailCount <= 10) {
                this.setState({ manifestUrl: "" });
                setTimeout(this.checkBroadcast.bind(this), 2500);
            } else {
                this.setState({manifestCheckState: "Token Error Permanent"});
                this.setState({manifestCheckFailed: true});
                this.setState({manifestChecking: false});
                setTimeout(this.checkBroadcast.bind(this), 8000);
            }
            return "";
        }
    }

    checkBroadcastWhenConnected = async() => {
        if(!this.state.broadcasting || this.state.stagingMode) {
            return;
        }
    
        try {
            if(this.state.manifestUrl !== "") {
                const response = await fetch(this.state.manifestUrl);
                const data = await response.json();
                console.log("checkBroadcastWhenConnected " + JSON.stringify(data));
                console.log("callID successfully checked " + data.formats.webrtc.origin.callId);
                let serverCallId = this.state.callId;
                if(typeof(data.formats.webrtc.origin.callId) !== "undefined") {
                    if(data.formats.webrtc.origin.callId !== this.state.callId) {
                        serverCallId = data.formats.webrtc.origin.callId;
                        this.logBroadcastAction("Setting callID on server " + data.formats.webrtc.origin.callId);
                        this.setCallIDOnServer(data.formats.webrtc.origin.callId);
                        this.setState({callId: data.formats.webrtc.origin.callId});
                    }
                }
                this.setManifestState("Manifest Success");
                if(this.socket) {
                    if(this.socket.connected) {
                        this.socket.emit("checkBroadcastState","ok");
                    }
                }
                this.allowBroadcastRestart = true;
                this.setState({manifestCheckSuccess: true});
                this.setState({manifestChecking: false});
                this.problemWithBroadcastCounter = 0;
                this.callIdLogCounter++;
                await this.logBroadcastAction("Manifest available " + serverCallId + " " + this.callIdLogCounter);
                await this.sendBroadcastState("started");
                if(this.callIdLogCounter % 10 === 0) {
                    let newManifestUrl:string = await this.fetchToken("https://streamauth.guppy.live/FetchPlayerToken.aspx?SessionID=" + encodeURIComponent(serverCallId) + "&Pseudo=" + encodeURIComponent(this.context.loginData.pseudo));
                    await this.logBroadcastAction("New Manifest URL " + newManifestUrl);
                }
            } else {
                if(this.state.callId !== "") {
                    let newManifestUrl:string = await this.fetchToken("https://streamauth.guppy.live/FetchPlayerToken.aspx?SessionID=" + encodeURIComponent(this.state.callId) + "&Pseudo=" + encodeURIComponent(this.context.loginData.pseudo));
                    await this.logBroadcastAction("New Manifest URL " + newManifestUrl);
                } else {
                    await this.logBroadcastAction("No callID available");
                }
            }
        } catch (error) {
            if(this.state.broadcasting) {
                this.problemWithBroadcastCounter++;
                console.log("Problem with broadcast reporting to server, counter: " + this.problemWithBroadcastCounter,error);
                await this.logBroadcastAction("ProblemWithBroadcast " + this.problemWithBroadcastCounter + " " + error);
                this.sendSysMessage("ProblemWithBroadcast " + this.problemWithBroadcastCounter + " " + error);

                if(this.backupDevice === null) {
                    this.initBackupSoup();
                }
                
                if(this.socket) {
                    if(this.socket.connected) {
                        if(!this.allowBroadcastRestart) {
                            await this.logBroadcastAction("RestartBroadcastNotAllowed " + this.problemWithBroadcastCounter);
                            if(this.problemWithBroadcastCounter > 10) {
                                this.allowBroadcastRestart = true;
                            }
                        }

                        if(this.allowBroadcastRestart) {
                            await this.logBroadcastAction("Restarting Broadcast");
                            this.allowBroadcastRestart = false;
                            this.problemWithBroadcastCounter = 0;
                            this.setState({broadcasting:false,manifestUrl:"",manifestCheckFailed:false,manifestChecking:false,manifestCheckSuccess:false});
                            setTimeout(this.setBroadcastingTrue.bind(this),5000);
                            setTimeout(this.reallowBroadcastRestart.bind(this),30000);
                        } else {
                            console.log("checkBroadcastWhenConnected not restarting broadcast");
                        }
                    }
                }
            }
        }
    }

    reallowBroadcastRestart() {
        this.allowBroadcastRestart = true;
    }

    createBroadcastLog = async() => {
        try {
            let SoftwareVersion = globSoftwareVersion;
    
            if(this.state.isElectron) {
                SoftwareVersion += "s";
            } else {
                SoftwareVersion += "b";
            }

            let url:string = "https://streamauth.guppy.live/LivegirlBroadcastLog.aspx?ProducerID=" + encodeURIComponent(this.context.loginData.producerID) + "&CallID=" + encodeURIComponent(this.state.callId) + "&Action=create&AppVersion=" + encodeURIComponent(SoftwareVersion);
            const response = await fetch(url);
            const data = await response.json();
            console.log("createBroadcastLog " + data.logID);
            this.broadcastLogID = data.logID;
        } catch (error:any) {
            console.warn("createBroadcastLog error " + JSON.stringify(error));
        }

        return 0;
    }

    logBroadcastActionProxy(action:string) {
        this.logBroadcastAction(action);
    }

    sendBroadcastState = async(param:string) => {
        try {
            let SoftwareVersion = globSoftwareVersion;
    
            if(this.state.isElectron) {
                SoftwareVersion += "s";
            } else {
                SoftwareVersion += "b";
            }

            let url:string = "https://streamauth.guppy.live/SendBroadcastState.aspx?ProducerID=" + encodeURIComponent(this.context.loginData.producerID);
            url += "&CallID=" + encodeURIComponent(this.state.callId);
            url += "&AppVersion=" + encodeURIComponent(SoftwareVersion);
            url += "&state=" + encodeURIComponent(param);
            if(this.state.stagingMode) {
                url += "&staging=1";
            }

            const response = await fetch(url);
            const data = await response.json();
            console.log("sendBroadcastState " + param + " " + data.result);
        } catch (error:any) {
            console.warn("sendBroadcastState error " + JSON.stringify(error));
        }
    }    

    logBroadcastAction = async(action:string,param:string = "") => {
        try {
            let SoftwareVersion = globSoftwareVersion;
    
            if(this.state.isElectron) {
                SoftwareVersion += "s";
            } else {
                SoftwareVersion += "b";
            }

            let url:string = "https://streamauth.guppy.live/LivegirlBroadcastLog.aspx?LogID=" + this.broadcastLogID + "&ProducerID=" + encodeURIComponent(this.context.loginData.producerID) + "&CallID=" + encodeURIComponent(this.state.callId) + "&Action=" + encodeURIComponent(action) + "&AppVersion=" + encodeURIComponent(SoftwareVersion) + "&param=" + encodeURIComponent(param);
            const response = await fetch(url);
            const data = await response.json();
            console.log("logBroadcastAction " + action + " " + data.result);
        } catch (error:any) {
            console.warn("logBroadcastAction error " + JSON.stringify(error));
        }
    }

    tryToFetchManifest = async(url:string) => {
        const axios = require('axios').default;
    
        try {
            await this.logBroadcastAction("Fetching Manifest");
            this.setState({manifestCheckState: "Fetching Manifest"});
            const response = await axios.get(url);
            await this.logBroadcastAction("Manifest Success");
            this.setManifestState("Manifest Success");
            if(this.socket) {
                if(this.socket.connected) {
                    this.socket.emit("checkBroadcastState","ok");
                }
            }
            this.setState({manifestCheckSuccess: true});
            this.setState({manifestChecking: false});
            if(this.state.autoConnectOnStreamSuccess) {
                this.handleConnectButton();
            }
        } catch (error) {
            this.setState({manifestCheckState:"Waiting for Stream..."});
            this.setState({manifestFailCount: this.state.manifestFailCount + 1});
            if(this.state.manifestFailCount <= 10) {
                this.setState({manifestUrl: ""});
                setTimeout(this.checkBroadcast.bind(this),2500);
            } else {
                await this.logBroadcastAction("Manifest Error");
                this.setManifestState("Stream not found");
                this.setState({manifestCheckSuccess: false});
                this.setState({manifestCheckFailed: true});
                this.setState({manifestChecking: false});
                setTimeout(this.checkBroadcast.bind(this), 8000);
            }
        }
    }

    enableSoundAuto() {
        this.enableSound();
    }

    enableSound() {
        this.setState({soundEnabled: true});
        this.socket?.emit("soundEnabled");
    }

    disableSound() {
        this.setState({soundEnabled: false});
        this.socket?.emit("soundDisabled");
    }

    handleSoundActiveChange() {
        // TODO: handle Stuff
    }

    selectLovenseDevice(device:LovenseDevice) {
        this.debuglog("Lovense device selected with ID " + device.id);
        this.setState({lovenseAllToysMode: false});
        this.setState({primaryLovenseDevice: device});
        this.setState({lovenseSelectOpen: false});
        this.setState({lovenseEnabled: true});
        this.sendLovenseStatusToServer(true);
    }

    changeAllToysMode(newMode:boolean) {
        this.debuglog("Lovense device changeAllToysMode " + newMode);
        if(newMode) {
            this.setState({lovenseAllToysMode: true});
            this.setState({primaryLovenseDevice: null});
            this.setState({lovenseEnabled: true});
            this.setState({lovenseSelectOpen: false});
            this.sendLovenseStatusToServer(true);
        } else {
            this.setState({lovenseAllToysMode: false});
            if(this.state.primaryLovenseDevice === null) {
                this.setState({lovenseEnabled: false});
                this.sendLovenseStatusToServer(false);
            }
        }
    }

    closeLovenseModal() {
        this.setState({lovenseSelectOpen: false});
    }

    setLovenseSpeed(device:LovenseDevice|null,speed:number) {
        //this.chatMessageDebug("setLovenseSpeed: " + speed);

        if(device === null) {
            if(window.lovenseSdk) {
                if(this.state.lovenseAllToysMode) {
                    window.lovenseSdk.sendToyCommand({
                        vibrate: speed
                    });
                }
            }  

            return;
        }

        if(device.hVersion === "old") {
            var APIUrl = "https://" + device.name + ":" + device.fVersion + "/Vibrate?v=" + speed + "&t=" + encodeURIComponent(device.id);
            console.log("setLovenseSpeed -> " + APIUrl);
        
            this.setState({lovenseSpeed: speed});

            const axios = require('axios').default;

            axios.get(APIUrl).then((response:AxiosResponse) => {
                console.log("setLovenseSpeed -> OK: " + response);
            }).catch(function(e:Error) {
                console.log("setLovenseSpeed -> ERROR: " + e.toString);
            });
        } else {
            if(window.lovenseSdk) {
                if(this.state.lovenseAllToysMode) {
                    window.lovenseSdk.sendToyCommand({
                        vibrate: speed
                    });
                } else {
                    window.lovenseSdk.sendToyCommand({
                        vibrate: speed,
                        toyId: device.id
                    });
                }
            }   
        }

        var barheight = Math.round((speed / 15) * 100);
        if(this.lovenseBarRef !== null) {
            if(this.lovenseBarRef.current !== null) {
                this.lovenseBarRef.current.style.height = barheight + "%";
            }
        }
    }

    sendLovenseStatusToServer(newState:boolean) {
        if(this.socket) {
            if(this.socket.connected) {
                if(newState) {
                    this.socket.emit("lovenseEnabled");
                } else {
                    this.socket.emit("lovenseDisabled");
                }
            }
        }
    }

    doDildoPortsList() {
        //this.chatMessage("GettingPorts...","chat-featuremessage");
        this.logBroadcastAction("Dildo-Control: Getting ports...");

        if(typeof(window.require) !== "undefined") {
            const { ipcRenderer } = window.require('electron');

            ipcRenderer.send("dildoports", null);

            ipcRenderer.on("dildoopen", this.onIPCDildoOpen.bind(this));
            ipcRenderer.on("dildoclose", this.onIPCDildoClose.bind(this));

            ipcRenderer.on("dildoports", this.onIPCDildoPorts.bind(this));
            ipcRenderer.on("chatmessage", this.onIPCChatMessage.bind(this));
            ipcRenderer.on("dildospeed", this.onIPCDildoSpeed.bind(this));
        } else {
            this.chatMessage("Only works in the desktop app...","chat-featuremessage");
        }
    }

    onIPCDildoOpen(event:Event) {
        //this.chatMessage("DildoPort open","chat-featuremessage");
        this.logBroadcastAction("Dildo-Control: open");
        this.setState({dildoPortOpen: true});
        this.setState({dildoEnabled: true});
        this.sendDildoStatusToServer(true);
    }
    onIPCDildoClose(event:Event) {
        //this.chatMessage("DildoPort close","chat-featuremessage");
        this.logBroadcastAction("Dildo-Control: close");
        this.setState({dildoPortOpen: false});
    }
    onIPCDildoPorts(event:Event, ports:any[]) {
        //this.chatMessage(ports.length + " Ports listed...","chat-featuremessage");
        this.logBroadcastAction("Dildo-Control: " + ports.length + " Ports listed...");
        this.setState({dildoPorts: ports});
        this.setState({dildoSelectOpen: true});
    }
    onIPCChatMessage(event:Event, msg:string) {
        //this.chatMessage(msg,"chat-sysmessage");
        this.logBroadcastAction("Dildo-Control: " + msg);
    }
    onIPCDildoSpeed(event:Event, speed:number) {
        this.chatMessage("Dildospeed feedback: " + speed,"chat-featuremessage");
    }

    handleLovenseTestButton() {
        if(this.state.lovenseTestRunning) {
            this.setState({lovenseTestRunning: false});
            this.setLovenseSpeed(this.state.primaryLovenseDevice,0);
        } else {
            this.setState({lovenseTestRunning: true});
            this.setLovenseSpeed(this.state.primaryLovenseDevice,15);
        }
    }

    handleDildoTestButton() {
        if(this.state.dildoTestRunning) {
            this.setState({dildoTestRunning: false});
            this.setDildoSpeed(0);
        } else {
            this.setState({dildoTestRunning: true});
            this.setDildoSpeed(15);
        }
    }

    handleDildoButton() {
        if(this.state.dildoEnabled) {
            this.setState({dildoEnabled: false});
            this.sendDildoStatusToServer(false);
            this.closeDildoPort();
            return;
        } else {
            if(this.state.dildoSelectOpen) {
                this.setState({dildoSelectOpen: false});
            } else {
                this.doDildoPortsList();
            }
        }
    }

    selectDildoPort(portName:string) {
        if(typeof(portName) === "undefined") {
            return;
        }
        if(portName === "") {
            return;
        }

        this.openDildoPort(portName);
        this.setState({dildoSelectOpen: false});
    }

    openDildoPort(port:string) {
        this.chatMessage("Trying to open port " + port,"chat-sysmessage");

        const { ipcRenderer } = window.require('electron');

        if(ipcRenderer) {
            ipcRenderer.send("dildoopen", port);
        }
    }

    sendDildoStatusToServer(newState:boolean) {
        if(this.socket) {
            if(this.socket.connected) {
                if(newState) {
                    this.socket.emit("dildoEnabled");
                } else {
                    this.socket.emit("dildoDisabled");
                }
            }
        }
    }

    closeDildoPort() {
        const { ipcRenderer } = window.require('electron');
        
        if(ipcRenderer) {
            ipcRenderer.send("dildoclose", null);
        }
    }

    setDildoSpeed(speed:number) {
        const { ipcRenderer } = window.require('electron');

        if(ipcRenderer) {
            ipcRenderer.send("dildospeed", speed);
        }

        var barheight = Math.round((speed / 15) * 100);
        if(this.dildoBarRef !== null) {
            if(this.dildoBarRef.current !== null) {
                this.dildoBarRef.current.style.height = barheight + "%";
            }
        }
    }

    disconnectChatServer() {
        this.logBroadcastAction("disconnectChatServer");
        this.doReconnect = false;
        if(this.socket) {
            if(this.socket.connected) {
                this.sysMessage("disconnecting socket");
                this.socket.close();
            } else {
                this.sysMessage("socket not connected");
            }
        } else {
            this.sysMessage("socket not initialized");
        }
    }

    getHumanStringForFeature(feature:string) {
        if(feature === "sound") {
            return "Sound";
        } else if(feature === "dildo") {
            return "Dildo-Control";
        } else if(feature === "multiPrivate") {
            return "Premium-Show";
        } else if(feature === "privateSession") {
            return "Separee";
        } else if(feature === "cam2cam") {
            return "Cam-2-Cam";
        } else if(feature === "usermic" || feature === "userMic") {
            return "UserMic";
        }

        return feature;
    }

    handleFeatureStopFromUser(feature:string,userPseudo:string,guid:string,isInvisible:boolean) {
        if(!isInvisible) {
            if(this.state.language === "de") {
                this.chatMessage(userPseudo + " hat " + this.getHumanStringForFeature(feature) + " beendet...","chat-featuremessage");
            } else {
                this.chatMessage(userPseudo + " has stopped " + this.getHumanStringForFeature(feature) + "...","chat-featuremessage");
            }
            this.sysMessage("featureStop " + userPseudo + " | feature: " + feature + " | guid: " + guid);
            // TODO: check if we need to hide the feature request if we have one
        }
    
        if(feature === "dildo") {
            this.setDildoSpeed(0);
        } else if(feature === "lovense") {
            this.setLovenseSpeed(this.state.primaryLovenseDevice,0);
        } else if(feature === "usermic" || feature === "userMic") {
            this.removeUserMicInfo(guid);
            this.removeUserMicInfoLively(guid);
            this.removeUserMicInfoSoup(guid);
            this.removeUserMicInfoSoupCentral1(guid);
            this.removeUserMicInfoSoupCentral2(guid);
        } else if(feature === "cam2cam") {
            this.removeCam2CamInfo(guid);
            this.removeCam2CamInfoLively(guid);
            this.removeCam2CamInfoSoup(guid);
            this.removeCam2CamInfoSoupCentral1(guid);
            this.removeCam2CamInfoSoupCentral2(guid);
        }

        this.setState((prevState:chatInterfaceState) => {
            const list = prevState.users.map((curUser, j) => {
                if (curUser.guid === guid) {
                    if(feature === "lovense") {
                        curUser.lovense = false;
                    } else if(feature === "dildo") {
                        curUser.dildo = false;
                    } else if(feature === "sound") {
                        curUser.sound = false;
                    } else if(feature === "cam2cam") {
                        curUser.cam2cam = false;
                    } else if(feature === "usermic" || feature === "userMic") {
                        curUser.userMic = false;
                    } else if(feature === "privateSession") {
                        curUser.privateSession = false;
                    } else if(feature === "multiPrivate") {
                        curUser.multiPrivate = false;
                    }

                    return curUser;
                } else {
                    return curUser;
                }
            });
       
            return {
                users: list
            };
        });

        /*let rmIndex = -1;
        let counter = -1;
        let rmPseudo = "";
        let array = [...this.state.users];

        for(let curUser of array) {
            counter++;
            if(curUser.guid === guid) {
                if(feature === "lovense") {
                    curUser.lovense = false;
                } else if(feature === "dildo") {
                    curUser.dildo = false;
                } else if(feature === "sound") {
                    curUser.sound = false;
                } else if(feature === "cam2cam") {
                    curUser.cam2cam = false;
                } else if(feature === "userMic") {
                    curUser.userMic = false;
                } else if(feature === "privateSession") {
                    curUser.privateSession = false;
                } else if(feature === "multiPrivate") {
                    curUser.multiPrivate = false;
                }
                rmPseudo = curUser.pseudo;
                rmIndex = counter;
                break;
            }
        }

        if(rmIndex > -1) {
            this.setState((prevState:chatInterfaceState) => ({users: array}));
            this.updateVoyeurInfoText();
        }*/
    }

    buildRequestButton(feature:string,userPseudo:string,guid:string) {
        // die guid ist in diesem Fall die flashChatSessionID
        if(feature === "cam2cam" && this.context.chatOptions.autoCam2CamAccept) {
            this.acceptFeature(feature,userPseudo,guid);
        } else if(feature === "sound" && this.context.chatOptions.autoSoundAccept) {
            this.acceptFeature(feature,userPseudo,guid);
        } else if((feature === "dildo" || feature === "lovense") && this.context.chatOptions.autoDildoAccept) {
            this.acceptFeature(feature,userPseudo,guid);
        } else if((feature === "usermic" || feature === "userMic") && this.context.chatOptions.autoUserMicAccept) {
            this.acceptFeature(feature,userPseudo,guid);
        } else {
            this.featureRequestCounter++;
            this.setState((prevState:chatInterfaceState) => ({featureRequests: [...prevState.featureRequests,{
                id: this.featureRequestCounter,
                feature: feature,
                userPseudo: userPseudo,
                guid: guid
            }]}));
        }
    }

    acceptFirstFeatureRequest() {
        let selReq:any = null;
        let foundReq:boolean = false;
        let array = [...this.state.featureRequests];

        for(let req of array) {
            selReq = req;
            foundReq = true;
            break;
        }

        if(foundReq) {
            if(selReq !== null) {
                this.acceptFeature(selReq.feature,selReq.userPseudo,selReq.guid);
            }
        }
    }

    denyFirstFeatureRequest() {
        let selReq:any = null;
        let foundReq:boolean = false;
        let array = [...this.state.featureRequests];

        for(let req of array) {
            selReq = req;
            foundReq = true;
            break;
        }

        if(foundReq) {
            if(selReq !== null) {
                this.denyFeature(selReq.feature,selReq.userPseudo,selReq.guid);
            }
        }
    }

    removeFeatureRequest(guid:string,feature:string) {
        //this.chatMessage("removeFeatureRequest " + guid + " | " + feature,"chatdebug");

        let rmIndex = -1;
        let counter = -1;
        let array = [...this.state.featureRequests];

        for(let curRequest of array) {
            counter++;
            if(curRequest.guid === guid) {
                rmIndex = counter;
                break;
            }
        }

        if(rmIndex > -1) {
            //this.chatMessage("Remove index " + rmIndex + " " + array[rmIndex].feature + "[" + array[rmIndex].guid + "]","");
            array.splice(rmIndex,1);
            //this.chatMessage("New requestList...","");
            //for(let curRequest of array) {
            //    this.chatMessage(curRequest.feature + "[" + curRequest.guid + "]","");
            //}
            //this.chatMessage("End new requestList...","");
            this.setState((prevState:chatInterfaceState) => ({featureRequests: array}));
        }

        /*this.setState((prevState:chatInterfaceState) => {
            const list = prevState.featureRequests.filter(item => item.guid !== guid && item.feature !== feature);
       
            return {
                featureRequests: list
            };
        });*/
    }

    sendWelcomeMessage(userPseudo:string) {
        if(this.socket) {
            if(this.socket.connected) {
                this.socket.emit("message", {
                    msg: this.context.chatOptions.welcomeMessage,
                    to: userPseudo,
                    special: "welcome"
                });
            }
        }
    }

    takeAwayFeature(feature:string,guid:string,message:string) {
        let socket = this.socket;
        if(socket) {
            if(socket.connected) {
                this.chatMessage("TakeAwayFeature " + feature + " -> " + message + " | guid: " + guid,"chat-featuremessage");
                socket.emit("sendFeatureDeny",this.producerID,guid,feature,message);
            }
        }
    }

    closeCam2Cam(userPseudo:string,streamname:string,flashChatSessionID:string) {
        this.chatMessage("closeCam2cam: " + userPseudo,"chat-featuremessage");
        this.takeAwayFeature("cam2cam",flashChatSessionID,"Cam-2-Cam stopped by girl");
        this.removeCam2CamInfo(flashChatSessionID);
        this.removeCam2CamInfoLively(flashChatSessionID);
    }

    closeUserMic(userPseudo:string,streamname:string,flashChatSessionID:string) {
        this.chatMessage("closeUserMic: " + userPseudo,"chat-featuremessage");
        this.takeAwayFeature("usermic",flashChatSessionID,"Usermic stopped by girl");
        this.removeUserMicInfo(flashChatSessionID);
    }

    acceptFeature(feature:string,userPseudo:string,guid:string) {
        // die guid ist in diesem Fall die flashChatSessionID
        this.sysMessage("acceptFeature feature: '" + feature + "' | userPseudo: '" + userPseudo + "' | guid: '" + guid + "'");
        this.chatMessage(this.getHumanStringForFeature(feature) + " an " + userPseudo + " " + this.getText("accepted") + "...","chat-accepted");
        this.socket?.emit("featureResponse",{
            feature: feature,
            userPseudo: userPseudo,
            answer: "yes",
            guid: guid,
            producerID: this.producerID
        });

        this.removeFeatureRequest(guid,feature);

        let rmIndex = -1;
        let counter = -1;
        let rmPseudo = "";
        let array:userInfo[] = [...this.state.users];

        for(let curUser of array) {
            counter++;
            if(curUser.guid === guid) {
                if(feature === "lovense") {
                    curUser.lovense = true;
                } else if(feature === "dildo") {
                    curUser.dildo = true;
                } else if(feature === "sound") {
                    curUser.sound = true;
                } else if(feature === "privateSession") {
                    curUser.privateSession = true;
                } else if(feature === "cam2cam") {
                    curUser.cam2cam = true;
                } else if(feature === "usermic" || feature === "userMic") {
                    curUser.userMic = true;
                } else if(feature === "multiPrivate") {
                    curUser.multiPrivate = true;
                }
                rmPseudo = curUser.pseudo;
                rmIndex = counter;
                break;
            }
        }

        if(rmIndex > -1) {
            //array.splice(rmIndex,1);
            this.setState({users: array});
            this.updateVoyeurInfoText();
        }
    }

    denyFeature(feature:string,userPseudo:string,guid:string) {
        // die guid ist in diesem Fall die flashChatSessionID
        this.chatMessage(this.getHumanStringForFeature(feature) + " an " + userPseudo + " " + this.getText("denied") + "...","chat-deny");
        if(this.socket) {
            this.socket.emit("featureResponse",{
                feature: feature,
                userPseudo: userPseudo,
                answer: "no",
                guid: guid,
                producerID: this.producerID
            });
        }

        this.removeFeatureRequest(guid,feature);
        
        // TODO: update users array in state

        let rmIndex = -1;
        let counter = -1;
        let rmPseudo = "";
        let array = [...this.state.users];

        for(let curUser of array) {
            counter++;
            if(curUser.guid === guid) {
                if(feature === "lovense") {
                    curUser.lovense = false;
                } else if(feature === "dildo") {
                    curUser.dildo = false;
                } else if(feature === "sound") {
                    curUser.sound = false;
                } else if(feature === "privateSession") {
                    curUser.privateSession = false;
                } else if(feature === "cam2cam") {
                    curUser.cam2cam = false;
                } else if(feature === "usermic" || feature === "userMic") {
                    curUser.userMic = false;
                } else if(feature === "multiPrivate") {
                    curUser.multiPrivate = false;
                }
                rmPseudo = curUser.pseudo;
                rmIndex = counter;
                break;
            }
        }

        if(rmIndex > -1) {
            //array.splice(rmIndex,1);
            this.setState({users: array});
            this.updateVoyeurInfoText();
        }
    }

    cleanupUserFeatures(userPseudo:string) {
        for(let myCam2CamInfo of this.state.cam2CamInfos) {
            if(myCam2CamInfo.pseudo === userPseudo) {
                this.chatMessage("Stopping cam-2-cam " + myCam2CamInfo.pseudo,"chat-featuremessage");
                this.removeCam2CamInfo(myCam2CamInfo.flashChatSessionID);
            }
        }

        for(let myCam2CamInfo of this.state.cam2CamInfosSoup) {
            if(myCam2CamInfo.pseudo === userPseudo) {
                this.chatMessage("Stopping cam-2-cam " + myCam2CamInfo.pseudo,"chat-featuremessage");
                this.removeCam2CamInfoSoup(myCam2CamInfo.flashChatSessionID);
            }
        }

        for(let myCam2CamInfo of this.state.cam2CamInfosSoupCentral1) {
            if(myCam2CamInfo.pseudo === userPseudo) {
                this.chatMessage("Stopping cam-2-cam " + myCam2CamInfo.pseudo,"chat-featuremessage");
                this.removeCam2CamInfoSoupCentral1(myCam2CamInfo.flashChatSessionID);
            }
        }

        for(let myCam2CamInfo of this.state.cam2CamInfosSoupCentral2) {
            if(myCam2CamInfo.pseudo === userPseudo) {
                this.chatMessage("Stopping cam-2-cam " + myCam2CamInfo.pseudo,"chat-featuremessage");
                this.removeCam2CamInfoSoupCentral2(myCam2CamInfo.flashChatSessionID);
            }
        }

        for(let myCam2CamInfo of this.state.cam2CamInfosLively) {
            if(myCam2CamInfo.pseudo === userPseudo) {
                this.chatMessage("Stopping cam-2-cam " + myCam2CamInfo.pseudo,"chat-featuremessage");
                this.removeCam2CamInfoLively(myCam2CamInfo.flashChatSessionID);
            }
        }
    
        for(let userMicObject of this.state.userMicInfos) {
            if(userMicObject.pseudo === userPseudo) {
                this.chatMessage("Stopping usermic " + userMicObject.pseudo,"chat-featuremessage");
                this.removeUserMicInfo(userMicObject.flashChatSessionID);
            }
        }

        for(let userMicObject of this.state.userMicInfosSoup) {
            if(userMicObject.pseudo === userPseudo) {
                this.chatMessage("Stopping usermic " + userMicObject.pseudo,"chat-featuremessage");
                this.removeUserMicInfoSoup(userMicObject.flashChatSessionID);
            }
        }

        for(let userMicObject of this.state.userMicInfosSoupCentral1) {
            if(userMicObject.pseudo === userPseudo) {
                this.chatMessage("Stopping usermic " + userMicObject.pseudo,"chat-featuremessage");
                this.removeUserMicInfoSoup(userMicObject.flashChatSessionID);
            }
        }

        for(let userMicObject of this.state.userMicInfosSoupCentral2) {
            if(userMicObject.pseudo === userPseudo) {
                this.chatMessage("Stopping usermic " + userMicObject.pseudo,"chat-featuremessage");
                this.removeUserMicInfoSoup(userMicObject.flashChatSessionID);
            }
        }

        for(let userMicObject of this.state.userMicInfosLively) {
            if(userMicObject.pseudo === userPseudo) {
                this.chatMessage("Stopping usermic " + userMicObject.pseudo,"chat-featuremessage");
                this.removeUserMicInfoLively(userMicObject.flashChatSessionID);
            }
        }
    }

    getColorFromClassName(className:string):string {
        switch(className) {
            case "chat-encodermessage":
                return "rgba(255,255,255,0.5)";
            case "chat-sysmessage":
                return this.state.sysMessageColor;
            case "chat-featuremessage":
                return this.state.featureMessageColor;
            case "chat-enter":
                return this.state.enterColor;
            case "chat-leave":
                return this.state.leaveColor;
            case "chat-from-girl":
                return this.state.chatFromGirlColor;
            case "chat-from-user1":
                return this.state.chatFromUserColor1;
            case "chat-from-user2":
                return this.state.chatFromUserColor2;
            case "chat-from-user3":
                return this.state.chatFromUserColor3;
            case "chat-request":
                return this.state.featureRequestColor;
            case "chat-accepted":
                return this.state.featureAcceptedColor;
            case "chat-denied":
                return this.state.featureDeniedColor;
        }

        return this.state.chatTextColor;
    }

    restartEncoder():void {
        this.setState({restartEncoder: true,manifestCheckState: "",manifestCheckSuccess: false,manifestCheckFailed: false,manifestChecking: false,manifestFailCount: 0,manifestUrl: "",userListHeight: 40,encoderDevicesSelected: false});
    }

    restartEncoderCallback():void {
        this.setState({restartEncoder: false});
    }

    restartBroadcastCallback():void {
        this.setState({broadcastFailed: false});
    }   

    getText(exp:string):string {
        if(this.state.language === "de") {
            if(exp === "SocketConnecting") {
                return "Verbinde zum Chatserver...";
            }
            if(exp === "stops" || exp === "stopped") {
                return "beendet";
            }
            if(exp === "ProblemWithBroadcast") {
                return "Leider gibt es ein Problem mit Deinem Stream";
            }
            if(exp === "SelectEncoderDevice") {
                return "Bestätige Kamera/Mikrofon";
            }
            if(exp === "OfflineMessage") {
                return "Offline-Nachricht";
            }
            if(exp === "SettingUpEncoder") {
                return "Kameraauswahl wird initialisiert..."
            }
            if(exp === "UserListHints") {
                return "Tipp: Gib # und den Anfangsbuchstaben eines Users ein, um ihn anzuschreiben. Mit Pfeil hoch/runter kannst Du auch durch die User wechseln. Mit Escape schreibst Du an alle.";
            }
            if(exp === "YouAreNotConnectedClickStartChat") {
                return "Du bist nicht verbunden, klicke den \"Chat starten\" Button um online zu gehen";
            }
            if(exp === "YouAreOnline") {
                return "Du bist online";
            }
            if(exp === "UserSelected") {
                return "User ausgewählt ";
            }
            if(exp === "YouAreOffline") {
                return "Du bist offline";
            }
            if(exp === "Deactivate") {
                return "Deaktivieren";
            }
            if(exp === "StartSession") {
                return "Chat starten";
            }
            if(exp === "EnableSound") {
                return "Sound einschalten";
            }
            if(exp === "DisableSound") {
                return "Sound deaktivieren";
            }
            if(exp === "EnableLovense") {
                return "Lovense einschalten";
            }
            if(exp === "EnableDildoControl") {
                return "DildoControl einschalten";
            }
            if(exp === "DisableDildoControl") {
                return "DildoControl ausschalten";
            }
            if(exp === "Select") {
                return "Auswählen";
            }
            if(exp === "DildoPortSelectInfo") {
                return "Bitte wähle unten den COM-Port aus, an dem Deine Dildo-Control angeschlossen ist";
            }
            if(exp === "deactivated") {
                return "deaktiviert";
            }
            if(exp === "Aktiviere Dildo-Control") {
                return "Aktiviere Dildo-Control";
            }
            if(exp === "Deaktiviere Dildo-Control") {
                return "Deaktiviere Dildo-Control";
            }
            if(exp === "Settings") {
                return "Einstellungen";
            }
            if(exp === "Mirror") {
                return "Bild spiegeln";
            }
            if(exp === "initWebCam") {
                return "Initialisiere <i class='video icon'></i>";
            }
            if(exp === "denied") {
                return "abgelehnt";
            }
            if(exp === "accepted") {
                return "angenommen";
            }
            if(exp === "Accept") {
                return "Annehmen";
            }
            if(exp === "Deny") {
                return "Ablehnen";
            }
            if(exp === "Connection established") {
                return "Verbindung hergestellt";
            }
            if(exp === "to") {
                return "an";
            }
            if(exp === "Enter your comment here") {
                return "Trage Deinen Kommentar hier ein";
            }
            if(exp === "Submit") {
                return "Abschicken";
            }
            if(exp === "GettingChatData") {
                return "Ermittle Chat-Daten";
            }
            if(exp === "Select") {
                return "Wähle";
            }
            if(exp === "Welcome") {
                return "Willkommen";
            }
            if(exp === "Deactivate") {
                return "Deaktivieren";
            }
            if(exp === "DildoPortSelectInfo") {
                return "Bitte wähle unten den COM-Port aus, an dem Deine Dildo-Control angeschlossen ist";
            }
            if(exp === "deactivated") {
                return "deaktiviert";
            }
            if(exp === "Aktiviere Dildo-Control") {
                return "Aktiviere Dildo-Control";
            }
            if(exp === "Deaktiviere Dildo-Control") {
                return "Deaktiviere Dildo-Control";
            }
            if(exp === "initWebCam") {
                return "Initialisiere <i class='video icon'></i>";
            }
            if(exp === "denied") {
                return "abgelehnt";
            }
            if(exp === "accepted") {
                return "angenommen";
            }
            if(exp === "Accept") {
                return "Annehmen";
            }
            if(exp === "Deny") {
                return "Ablehnen";
            }
            if(exp === "Connection established") {
                return "Verbindung hergestellt";
            }
            if(exp === "to") {
                return "an";
            }
            if(exp === "Lovense device detection") {
                return "Lovense Gerät ermitteln";
            }
            if(exp === "Discovering lovense devices via LAN") {
                return "Lovense Gerät per LAN-Anschluss ermitteln";
            }
            if(exp === "Discovering locally connected lovense devices") {
                return "Lokal angeschlossenes Lovense Gerät ermitteln";
            }
            if(exp === "Change device") {
                return "Gerät wechseln";
            }
            if(exp === "Locally Connected") {
                return "Lokal per Dongle";
            }
            if(exp === "Connected via LAN") {
                return "Suche im Netzwerk";
            }
            if(exp === "Enter your comment here") {
                return "Trage Deinen Kommentar hier ein";
            }
            if(exp === "Submit") {
                return "Abschicken";
            }
            if(exp === "ChangeLanguage") {
                return "Switch to English";
            }
            if(exp === "To") {
                return "an";
            }
            if(exp === "ToAll") {
                return "An alle";
            }
            if(exp === "Chatmessage") {
                return "Chatnachricht";
            }
            if(exp === "PleaseSelectYourDevices") {
                return "Bitte wähle oben Kamera/Mikrofon aus...";
            }
            if(exp === "Options") {
                return "Optionen";
            }
            if(exp === "In order to change options you will leave the current session, do you really want to do that?") {
                return "Um die Optionen zu ändern musst Du kurz die aktuelle Session beenden, möchtest Du das tun?";
            }
            if(exp === "YourBroadcastIsBeingInitialized") {
                return "Dein Livestream wird eingerichtet...bitte warte einen kurzen Moment...";
            }
            if(exp === "YourBroadcastHasFailed") {
                return "Sorry, wir konnten Deinen Livestream nicht einrichten, bitte starte die App neu oder lade die Browserseite neu und versuche es noch einmal...";
            }
            if(exp === "TryAgain") {
                return "Nochmal versuchen";
            }
        } else {
            if(exp === "SocketConnecting") {
                return "Connecting to Chatserver...";
            }
            if(exp === "ProblemWithBroadcast") {
                return "Sorry, there is a problem with your livestream";
            }
            if(exp === "SelectEncoderDevice") {
                return "Confirm Camera/Microphone Selection";
            }
            if(exp === "SettingUpEncoder") {
                return "Setting up devices..."
            }
            if(exp === "UserListHints") {
                return "Hint: Enter # and the first letter of a user to write to him. You can also use arrow up/down to change target user. Press Escape to revert to writing to all.";
            }
            if(exp === "YouAreNotConnectedClickStartChat") {
                return "You are not connected, click the Start Chat button to start your chat session";
            }
            if(exp === "UserSelected") {
                return "User selected ";
            }
            if(exp === "Wirklich Kommentar löschen?") {
                return "Really delete comment?";
            }
            if(exp === "Userkommentare") {
                return "User Comments";
            }
            if(exp === "DildoPortSelectInfo") {
                return "Please select the COM-Port to which your Dildo-Control is attached";
            }
            if(exp === "Kamera initialisieren") {
                return "Initialize Camera";
            }
            if(exp === "Du kannst Dich jetzt mit dem Chatserver verbinden und Deine Show starten") {
                return "";
            }
            if(exp === "Chat starten") {
                return "Start Chat";
            }
            if(exp === "Chat beenden") {
                return "Disconnect Chat";
            }
            if(exp === "Aktiviere Sound") {
                return "Activate Sound";
            }
            if(exp === "Deaktiviere Sound") {
                return "Deactivate Sound";
            }
            if(exp === "Aktiviere Sound") {
                return "Activate Sound";
            }
            if(exp === "Deaktiviere Sound") {
                return "Deactivate Sound";
            }
            if(exp === "Aktiviere Lovense") {
                return "Activate Lovense";
            }
            if(exp === "Deaktiviere Lovense") {
                return "Deactivate Lovense";
            }
            if(exp === "Aktiviere Dildo-Control") {
                return "Activate Dildo-Control";
            }
            if(exp === "Deaktiviere Dildo-Control") {
                return "Deactivate Dildo-Control";
            }
            if(exp === "Dildo-Port auswählen") {
                return "Select Dildo-Port";
            }
            if(exp === "Einstellungen") {
                return "Settings";
            }
            if(exp === "Zugriff auf die WebCam fehlgeschlagen") {
                return "Camera access failed";
            }
            if(exp === "initWebCam") {
                return "Init <i class='video icon'></i>";
            }
            if(exp === "fragt nach") {
                return "requests";
            }
            if(exp === "Keine Verbindung zum Chatserver") {
                return "No connection to Chatserver";
            }
            if(exp === "Verbindung zum Chatserver nicht initialisiert") {
                return "Connection to Chatserver not intialized";
            }
            if(exp === "Verbindung zum Chatserver akzeptiert") {
                return "Connection to Chatserver accepted";
            }
            if(exp === "Du bist online") {
                return "You are online";
            }
            if(exp === "Verbindung zum Chatserver abgelehnt") {
                return "Connection to Chatserver denied";
            }
            if(exp === "To") {
                return "to";
            }
            if(exp === "ToAll") {
                return "To all";
            }
            if(exp === "Chatmessage") {
                return "Chatmessage";
            }
            if(exp === "wollte den Dildo kontrollieren, aber der ist nicht aktiviert") {
                return "wanted to start Dildo-Control, but that is not activated";
            }
            if(exp === "wollte Lovense kontrollieren, aber das ist nicht aktiviert") {
                return "wanted to control Lovense, but that is not activated";
            }
            if(exp === "Sound wird kostenlos angeboten") {
                return "Sound will be free for users";
            }
            if(exp === "Sound kostenlos") {
                return "Sound free";
            }
            if(exp === "betritt den Chat") {
                return "enters the room";
            }
            if(exp === "hat den Chat verlassen") {
                return "has left the room";
            }
            if(exp === "Bitte initialisiere Deine Kamera") {
                return "Please initialize your WebCam";
            }
            if(exp === "Kamera initialisieren") {
                return "Initialize WebCam";
            }
            if(exp === "Leider konnte keine angeschlossene Webcam gefunden werden") {
                return "Sorry, no webcam found connected to your PC";
            }
            if(exp === "Suche erneut nach WebCams") {
                return "Search for WebCams again";
            }
            if(exp === "Fehler beim Zugriff auf die WebCam") {
                return "Error accessing webcam";
            }
            if(exp === "ChangeLanguage") {
                return "Change Language";
            }
            if(exp === "YouAreOnline") {
                return "You are online";
            }
            if(exp === "YouAreOffline") {
                return "You are offline";
            }
            if(exp === "SocketConnecting") {
                return "Establishing connection...";
            }
            if(exp === "YourChatIsBeingInitialized") {
                return "Your chatroom is being initialized...";
            }
            if(exp === "Wirklich Kommentar löschen?") {
                return "Really delete comment?";
            }
            if(exp === "Userkommentare") {
                return "User Comments";
            }
            if(exp === "EnableSound") {
                return "Enable Sound";
            }
            if(exp === "DisableSound") {
                return "Disable Sound";
            }
            if(exp === "Settings") {
                return "Settings";
            }
            if(exp === "EnableLovense") {
                return "Enable Lovense";
            }
            if(exp === "EnableDildoControl") {
                return "Enable DildoControl";
            }
            if(exp === "DisableDildoControl") {
                return "Disable DildoControl";
            }
            if(exp === "DildoPortSelectInfo") {
                return "Please select the COM-Port to which your Dildo-Control is attached";
            }
            if(exp === "Kamera initialisieren") {
                return "Initialize Camera";
            }
            if(exp === "Du kannst Dich jetzt mit dem Chatserver verbinden und Deine Show starten") {
                return "";
            }
            if(exp === "StartSession") {
                return "Start Chat";
            }
            if(exp === "Chat beenden") {
                return "Disconnect Chat";
            }
            if(exp === "Aktiviere Sound") {
                return "Activate Sound";
            }
            if(exp === "Deaktiviere Sound") {
                return "Deactivate Sound";
            }
            if(exp === "Aktiviere Sound") {
                return "Activate Sound";
            }
            if(exp === "Deaktiviere Sound") {
                return "Deactivate Sound";
            }
            if(exp === "Aktiviere Lovense") {
                return "Activate Lovense";
            }
            if(exp === "Deaktiviere Lovense") {
                return "Deactivate Lovense";
            }
            if(exp === "Aktiviere Dildo-Control") {
                return "Activate Dildo-Control";
            }
            if(exp === "Deaktiviere Dildo-Control") {
                return "Deactivate Dildo-Control";
            }
            if(exp === "Dildo-Port auswählen") {
                return "Select Dildo-Port";
            }
            if(exp === "Einstellungen") {
                return "Settings";
            }
            if(exp === "Zugriff auf die WebCam fehlgeschlagen") {
                return "Camera access failed";
            }
            if(exp === "initWebCam") {
                return "Init <i class='video icon'></i>";
            }
            if(exp === "fragt nach") {
                return "requests";
            }
            if(exp === "Keine Verbindung zum Chatserver") {
                return "No connection to Chatserver";
            }
            if(exp === "Verbindung zum Chatserver nicht initialisiert") {
                return "Connection to Chatserver not intialized";
            }
            if(exp === "Verbindung zum Chatserver akzeptiert") {
                return "Connection to Chatserver accepted";
            }
            if(exp === "Du bist online") {
                return "You are online";
            }
            if(exp === "Verbindung zum Chatserver abgelehnt") {
                return "Connection to Chatserver denied";
            }
            if(exp === "Alle") {
                return "All";
            }
            if(exp === "wollte den Dildo kontrollieren, aber der ist nicht aktiviert") {
                return "wanted to start Dildo-Control, but that is not activated";
            }
            if(exp === "wollte Lovense kontrollieren, aber das ist nicht aktiviert") {
                return "wanted to control Lovense, but that is not activated";
            }
            if(exp === "Sound wird kostenlos angeboten") {
                return "Sound will be free for users";
            }
            if(exp === "Sound kostenlos") {
                return "Sound free";
            }
            if(exp === "SocketConnecting") {
                return "Verbindung wird hergestellt...";
            }
            if(exp === "YourChatIsBeingInitialized") {
                return "Chatraum wird eingerichtet...";
            }
            if(exp === "betritt den Chat") {
                return "enters the room";
            }
            if(exp === "hat den Chat verlassen") {
                return "has left the room";
            }
            if(exp === "Bitte initialisiere Deine Kamera") {
                return "Please initialize your WebCam";
            }
            if(exp === "Kamera initialisieren") {
                return "Initialize WebCam";
            }
            if(exp === "Leider konnte keine angeschlossene Webcam gefunden werden") {
                return "Sorry, no webcam found connected to your PC";
            }
            if(exp === "Suche erneut nach WebCams") {
                return "Search for WebCams again";
            }
            if(exp === "Fehler beim Zugriff auf die WebCam") {
                return "Error accessing webcam";
            }
            if(exp === "PleaseSelectYourDevices") {
                return "Please select camera/microphone...";
            }
            if(exp === "YourBroadcastIsBeingInitialized") {
                return "Your livestream is being initialized...please wait a second...";
            }
            if(exp === "YourBroadcastHasFailed") {
                return "Sorry, we could not initialize your livestream, please restart the app or reload the browser page and try again...";
            }
            if(exp === "TryAgain") {
                return "Try again";
            }
        }
    
        return exp;
    }

    render() {
        return (
            <div id="chatInterfaceContainer" className="container-fluid" style={{backgroundColor: this.state.mainBackgroundColor,color: this.state.mainTextColor}}>
                { this.state.dildoEnabled ? <div id="localDildoParent"><div id="localDildoBar" ref={this.dildoBarRef}></div></div> : null }
                { this.state.dildoEnabled ? <div id="localDildoOff">Dildo-Control</div> : null }
                { this.state.dildoEnabled ? <div id="localDildoActive"></div> : null }
                
                { this.state.lovenseEnabled ? <div id="localLovenseParent"><div id="localLovenseBar" ref={this.lovenseBarRef}></div></div> : null }
                { this.state.lovenseEnabled ? <div id="localLovenseActive"></div> : null }
                { this.state.lovenseEnabled ? <div id="localLovenseOff">Lovense</div> : null }

                {this.state.cam2CamInfos.map((obj,index) => <Cam2CamVideo key={obj.id} orderIndex={obj.orderIndex} sysMessage={this.sysMessage.bind(this)} client={this.Client!} closeCallback={this.closeCam2Cam.bind(this)} userPseudo={obj.pseudo} flashChatSessionID={obj.flashChatSessionID} streamname={obj.guid} index={index} /> )}
                {this.state.cam2CamInfosSoup.map((obj,index) => <Cam2CamVideoSoup key={obj.id} orderIndex={obj.orderIndex} sysMessage={this.sysMessage.bind(this)} peerId={obj.guid} soupServer={obj.soupServer} closeCallback={this.closeCam2Cam.bind(this)} userPseudo={obj.pseudo} flashChatSessionID={obj.flashChatSessionID} index={index} /> )}
                {this.state.cam2CamInfosSoupCentral1.map((obj,index) => <Cam2CamVideoSoupCentral key={obj.id} orderIndex={obj.orderIndex} logBroadcastAction={this.logBroadcastActionProxy.bind(this)} sysMessage={this.sysMessage.bind(this)} userPeerId={obj.guid} soupServer={obj.soupServer} closeCallback={this.closeCam2Cam.bind(this)} userPseudo={obj.pseudo} flashChatSessionID={obj.flashChatSessionID} index={index} device={this.cam2camDevice1} girlPeerId={this.cam2camPeerId1} recvTransport={this.recvTransport1} signaling={this.cam2camSignaling1} /> )}
                {this.state.cam2CamInfosSoupCentral2.map((obj,index) => <Cam2CamVideoSoupCentral key={obj.id} orderIndex={obj.orderIndex} logBroadcastAction={this.logBroadcastActionProxy.bind(this)} sysMessage={this.sysMessage.bind(this)} userPeerId={obj.guid} soupServer={obj.soupServer} closeCallback={this.closeCam2Cam.bind(this)} userPseudo={obj.pseudo} flashChatSessionID={obj.flashChatSessionID} index={index} device={this.cam2camDevice2} girlPeerId={this.cam2camPeerId2} recvTransport={this.recvTransport2} signaling={this.cam2camSignaling2} /> )}
                {this.state.userMicInfos.map((obj,index) => <UserMicAudio key={obj.id} sysMessage={this.sysMessage.bind(this)} client={this.Client} closeCallback={this.closeUserMic.bind(this)} userPseudo={obj.pseudo} flashChatSessionID={obj.flashChatSessionID} streamname={obj.guid} index={index} />)}
                {this.state.userMicInfosSoup.map((obj,index) => <UserMicAudioSoup key={obj.id} sysMessage={this.sysMessage.bind(this)} closeCallback={this.closeUserMic.bind(this)} userPseudo={obj.pseudo} flashChatSessionID={obj.flashChatSessionID} peerId={obj.guid} soupServer={obj.soupServer} index={index} />)}
                {this.state.userMicInfosSoupCentral1.map((obj,index) => <UserMicAudioSoupCentral key={obj.id} logBroadcastAction={this.logBroadcastActionProxy.bind(this)} sysMessage={this.sysMessage.bind(this)} userPeerId={obj.guid} closeCallback={this.closeUserMic.bind(this)} userPseudo={obj.pseudo} flashChatSessionID={obj.flashChatSessionID} index={index} device={this.cam2camDevice1} girlPeerId={this.cam2camPeerId1} recvTransport={this.recvTransport1} signaling={this.cam2camSignaling1} /> )}
                {this.state.userMicInfosSoupCentral2.map((obj,index) => <UserMicAudioSoupCentral key={obj.id} logBroadcastAction={this.logBroadcastActionProxy.bind(this)} sysMessage={this.sysMessage.bind(this)} userPeerId={obj.guid} closeCallback={this.closeUserMic.bind(this)} userPseudo={obj.pseudo} flashChatSessionID={obj.flashChatSessionID} index={index} device={this.cam2camDevice2} girlPeerId={this.cam2camPeerId2} recvTransport={this.recvTransport2} signaling={this.cam2camSignaling2} /> )}
                <LivelyCallContext.Provider value={this.videoCall}>
                    {this.state.cam2CamInfosLively.map((obj,index) => <Cam2CamVideoLively playerUI={obj.playerUI} orderIndex={obj.orderIndex} key={obj.id} sysMessage={this.sysMessage.bind(this)} closeCallback={this.closeCam2Cam.bind(this)} userPseudo={obj.pseudo} flashChatSessionID={obj.flashChatSessionID} callId={obj.guid} index={index} /> )}
                    {this.state.userMicInfosLively.map((obj,index) => <UserMicAudioLively playerUI={obj.playerUI} key={obj.id} sysMessage={this.sysMessage.bind(this)} closeCallback={this.closeUserMic.bind(this)} userPseudo={obj.pseudo} flashChatSessionID={obj.flashChatSessionID} callId={obj.guid} index={index} />)}
                </LivelyCallContext.Provider>

                { this.state.userNoteGuid === "" ? null : <UserNotesModal closeCallback={this.closeNotesCallback.bind(this)} updateNotesCallback={this.updateNotesCallback.bind(this)} pseudo={this.state.userNotePseudo} guid={this.state.userNoteGuid}></UserNotesModal> }
                { !this.state.chatOptionsModal ? null : <ChatOptionsModal closeCallback={this.closeChatOptionsCallback.bind(this)} updateCallback={this.updateChatOptionsCallback.bind(this)}></ChatOptionsModal> }
                { !this.state.chatOfflineModal ? null : <ChatOfflineModal closeCallback={this.closeChatOfflineCallback.bind(this)} updateCallback={this.updateChatOfflineCallback.bind(this)}></ChatOfflineModal> }

                { this.state.dildoSelectOpen ? <DildoPortSelect selectDildoPort={this.selectDildoPort.bind(this)} dildoPorts={this.state.dildoPorts} /> : null }
                { this.state.lovenseSelectOpen ? <LovenseModal allToysMode={this.state.lovenseAllToysMode} producerID={this.context.loginData.producerID} pseudo={this.context.loginData.pseudo} closeCallback={this.closeLovenseModal.bind(this)} onSelect={this.selectLovenseDevice.bind(this)} onChangeAllToysMode={this.changeAllToysMode.bind(this)} language={this.state.language} /> : null }

                <Row>
                    <Col>
                        <div id="btnToolbar">
                            <Button id="btnConnect" onClick={this.handleConnectButton.bind(this)} variant={this.state.socketConnected ? "secondary" : "primary"} disabled={this.state.socketConnecting || !this.state.encoderDevicesSelected} size="sm">{ this.state.socketConnecting ? <FontAwesomeIcon icon={["fas", "spinner"]} spin /> : null }{ !this.state.socketConnected && !this.state.socketConnecting ? <FontAwesomeIcon icon={["fas", "hand-point-right"] } /> : null } { this.state.socketConnected ? this.getText("Disconnect") : this.getText("StartSession") }</Button>
                            <Button id="btnPause" onClick={this.handlePauseButton.bind(this)} className="ml-1" variant={this.state.pauseMode ? "success" : "secondary"} disabled={this.state.socketConnecting || !this.state.encoderDevicesSelected} size="sm">{ this.state.pauseMode ? this.getText("Weitermachen") : this.getText("Pause") }</Button>
                            <Button id="btnSound" onClick={this.handleSoundButton.bind(this)} className="ml-1" disabled={!this.state.chatConnected} variant="secondary" size="sm">{this.state.soundEnabled ? <span className='oncircle'></span> : <span className='offcircle'></span>} Sound</Button>
                            { this.context.chatOptions.dildoAllowed && this.state.isElectron ? <Button id="btnDildo" onClick={this.handleDildoButton.bind(this)} className="ml-1" variant="secondary" size="sm">{this.state.dildoEnabled ? <span className='oncircle'></span> : <span className='offcircle'></span>} Dildo-Control</Button> : null }
                            { this.state.dildoEnabled ? <Button id="btnDildoTest" onClick={this.handleDildoTestButton.bind(this)} className="ml-1" variant="secondary" size="sm">{this.state.dildoTestRunning ? this.getText("Stop Test") : this.getText("Test DildoControl")}</Button> : null }
                            <Button id="btnLovense" onClick={this.handleLovenseButton.bind(this)} className="ml-1" variant="secondary" size="sm">{this.state.lovenseEnabled ? <span className="oncircle"></span> : <span className="offcircle"></span>} Lovense</Button>
                            { this.state.lovenseEnabled ? <Button id="btnLovenseTest" onClick={this.handleLovenseTestButton.bind(this)} className="ml-1" variant="secondary" size="sm">{this.state.lovenseTestRunning ? this.getText("Stop Test") : this.getText("Test Lush")}</Button> : null }
                            <Button id="btnSettings" onClick={this.handleSettingsButton.bind(this)} className="ml-1" variant="secondary" size="sm">{this.getText("Settings")}</Button>
                            <Button id="btnOptions" onClick={this.handleOptionsButton.bind(this)} className="ml-1" variant="secondary" size="sm">{this.getText("Options")}</Button>
                            <Button id="btnOffline" onClick={this.handleOfflineButton.bind(this)} className="ml-1" variant="secondary" size="sm">{this.getText("OfflineMessage")}</Button>
                            <Button id="btnSwitchLang" onClick={this.handleLanguageButton.bind(this)} className="ml-1" variant="secondary" size="sm">{this.getText("ChangeLanguage")}</Button>
                            { this.state.showDebug ? <Button id="btnDebug" onClick={this.handleDebugButton.bind(this)} className="ml-1" variant="secondary" size="sm">Debug</Button> : null }
                            { this.state.showDebug ? <Button id="btnShowOwn" onClick={this.showOwnPlayer.bind(this)} className="ml-1" variant="secondary" size="sm">Test</Button> : null }
                            { this.state.showDebug ? <Button id="btnBroadcast" onClick={this.handleBroadcastButton.bind(this)} className="ml-1" variant="secondary" size="sm">{this.state.broadcasting ? <span className='oncircle'></span> : <span className='offcircle'></span>} Broadcasting</Button> : null }
                            <DropdownButton id="colordropdownbutton" title="Color" className="ml-1" variant="secondary" size="sm">
                                <Dropdown.Item onClick={this.switchToDarkMode.bind(this)}>Dark Mode</Dropdown.Item>
                                <Dropdown.Item onClick={this.switchToWhiteMode.bind(this)}>White Mode</Dropdown.Item>
                                <Dropdown.Item onClick={this.switchToGrayMode.bind(this)}>Gray Mode</Dropdown.Item>
                                <Dropdown.Item onClick={this.switchToSepiaMode.bind(this)}>Sepia Mode</Dropdown.Item>
                            </DropdownButton>
                            <Button id="btnExit" onClick={this.exitChat.bind(this)} className="ml-1" variant="secondary" size="sm">{this.getText("Exit")}</Button>
                        </div>
                    </Col>
                </Row>
                <Row id="chatInterfaceMainRow">
                    <Col xs={this.state.videoPaneCols > 0 ? this.state.videoPaneCols : 12} className={this.state.videoPaneCols === 0 ? 'chatInterfaceMainRowChild d-none' : 'chatInterfaceMainRowChild'}>
                        <div id="chatVideoContainer" style={{height: "calc(100% - " + (this.state.userListHeight + 10) + "px)"}}>
                            {this.state.pauseMode ? <div id="pauseHintDiv">Pause</div> : null }
                            {!this.state.socketConnected ? 
                            <div id="streamStatusIndicatorDIV">
                                {!this.state.encoderDevicesSelected ? this.getText("PleaseSelectYourDevices") : null}
                                {this.state.encoderDevicesSelected && !this.state.socketConnected ? <Alert variant="primary" className="mb-0" onClick={this.handleConnectButton.bind(this)} style={{cursor: 'pointer'}}><FontAwesomeIcon icon={["fas", "hand-point-right"] } /> {this.getText("YouAreNotConnectedClickStartChat")}</Alert> : null}
                                {this.state.socketConnecting ? <Alert variant="warning" className="mb-0"><FontAwesomeIcon icon={["fas", "spinner"] } spin /> {this.getText("SocketConnecting")}</Alert> : null}
                                {this.state.socketConnected && !this.state.chatConnected ? <Alert variant="warning" className="mb-0"><FontAwesomeIcon icon={["fas", "spinner"] } spin /> {this.getText("YourChatIsBeingInitialized")}</Alert> : null}
                            </div> : null }
                            <div id="chatVideoButtons">
                                { this.state.encoderDevicesSelected ? <Button id="btnMirror" onClick={this.handleMirrorButton.bind(this)} variant="secondary" size="sm"><FontAwesomeIcon icon={["fas", "exchange"]} /></Button> : null }
                                <Button id="btnVideoMoveLeft" onClick={this.videoMoveLeft.bind(this)} className="ml-1" variant="secondary" size="sm"><FontAwesomeIcon icon={["fas", "arrow-square-left"]} /></Button>
                                <Button id="btnVideoMoveRight" onClick={this.videoMoveRight.bind(this)} className="ml-1" variant="secondary" size="sm"><FontAwesomeIcon icon={["fas", "arrow-square-right"]} /></Button>
                            </div>
                            <div id="voyeurInfoDIV">
                                <div id="voyeurInfoDIVInner">
                                    { this.getNumVoyeurUsers() > 0 ? <FontAwesomeIcon icon={["fas", "eye"]} /> : null } { this.state.voyeurInfoText }
                                </div>
                            </div>
                            <ModularEncoder stagingMode={this.state.stagingMode} createBroadcastLog={this.createBroadcastLog.bind(this)} logBroadcastAction={this.logBroadcastAction.bind(this)} girlDisconnected={this.state.girlDisconnected} girlDisconnectedCallback={this.girlDisconnectedCallback.bind(this)} broadcastFailed={this.state.broadcastFailed} restartEncoder={this.state.restartEncoder} restartBroadcastCallback={this.restartBroadcastCallback.bind(this)} restartEncoderCallback={this.restartEncoderCallback.bind(this)} userLanguage={this.state.language} sendEncoderUI={this.sendEncoderUI.bind(this)} sendVideoClient={this.sendVideoClient.bind(this)} broadcasting={this.state.broadcasting} resolutionWidth={this.state.resolutionWidth} resolutionHeight={this.state.resolutionHeight} sendCallId={this.sendCallId.bind(this)} sendVideoDeviceId={this.sendVideoDeviceId.bind(this)} sysMessage={this.sysMessage.bind(this)} chatMessage={this.chatMessage.bind(this)} onCameraSelect={this.handleOnCameraSelect.bind(this)} />
                        </div>
                        <Row>
                            <Col xs={!this.state.showOwnPlayer || this.state.manifestUrl === "" ? 12 : 8}>
                                <div id="userListContainer" style={{fontSize: this.state.usersFontSize,height: this.state.userListHeight + "px",backgroundColor: this.state.userListBackgroundColor,color: this.state.userListTextColor}}>
                                    <div id="userListHintsDIV" style={{display: this.state.users.length >= 4 ? "none" : "initial"}}>{this.getText("UserListHints")}</div>
                                    <div id="userListButtons">
                                        { this.state.showDebug ? <Button id="btnUsersTestPlus" onClick={this.usersTestPlus.bind(this)}  className="mr-1"variant="secondary" size="sm"><FontAwesomeIcon icon={["fas", "user-plus"]} /></Button> : null }
                                        { this.state.showDebug ? <Button id="btnUsersTestMinus" onClick={this.usersTestMinus.bind(this)} className="mr-1" variant="secondary" size="sm"><FontAwesomeIcon icon={["fas", "user-minus"]} /></Button> : null }
                                        <Button id="btnVideoMoveUp" onClick={this.videoMoveUp.bind(this)} variant="secondary" size="sm"><FontAwesomeIcon icon={["fas", "arrow-square-up"]} /></Button>
                                        <Button id="btnVideoMoveDown" onClick={this.videoMoveDown.bind(this)} className="ml-1" variant="secondary" size="sm"><FontAwesomeIcon icon={["fas", "arrow-square-down"]} /></Button>
                                        <Button id="btnUsersFontPlus" onClick={this.usersFontPlus.bind(this)}  className="ml-1"variant="secondary" size="sm"><FontAwesomeIcon icon={["fas", "text-height"]} /></Button>
                                        <Button id="btnUsersFontMinus" onClick={this.usersFontMinus.bind(this)} className="ml-1" variant="secondary" size="sm"><FontAwesomeIcon icon={["fas", "text"]} /></Button>
                                    </div>
                                    <div id="featureRequestsContainer">
                                        {this.state.featureRequests.map((obj,index) => <FeatureRequest key={obj.id} acceptCallback={this.acceptFeature.bind(this)} denyCallback={this.denyFeature.bind(this)} feature={obj.feature} userPseudo={obj.userPseudo} guid={obj.guid} />)}
                                    </div>
                                    {this.state.users.map((obj,index) => <UserListEntry key={obj.id} openNotesCallback={this.openNotesCallback.bind(this)} selectCallback={this.selectUser.bind(this)} guid={obj.guid} pseudo={obj.pseudo} userType={obj.userType} isFromCMS={obj.isFromCMS} updateNotes={obj.updateNotes} sound={obj.sound} lovense={obj.lovense} privateSession={obj.privateSession} dildo={obj.dildo} multiPrivate={obj.multiPrivate} cam2cam={obj.cam2cam} userMic={obj.userMic} /> )}
                                </div>
                            </Col>
                            <Col xs={!this.state.showOwnPlayer || this.state.manifestUrl === "" ? 0 : 4}>
                                {!this.state.showOwnPlayer || this.state.manifestUrl === "" ? null : <ModularPlayer manifestUrl={this.state.manifestUrl} />}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12 - this.state.videoPaneCols} className="chatInterfaceMainRowChild">
                        <div id="chatMessageContainer" style={{fontSize: this.state.chatFontSize,backgroundColor: this.state.chatBackgroundColor,color: this.state.chatTextColor}} ref={this.chatMessageContainerRef}>
                            <div id="chatMessageButtons">
                                { this.state.videoPaneCols === 0 ? <Button id="btnVideoMoveRight2" onClick={this.videoMoveRight.bind(this)} className="mr-1" variant="secondary" size="sm"><FontAwesomeIcon icon={["fas", "arrow-square-right"]} /></Button> : null }
                                <Button id="btnFontPlus" onClick={this.chatFontPlus.bind(this)} variant="secondary" size="sm"><FontAwesomeIcon icon={["fas", "text-height"]} /></Button>
                                <Button id="btnFontMinus" onClick={this.chatFontMinus.bind(this)} className="ml-1" variant="secondary" size="sm"><FontAwesomeIcon icon={["fas", "text"]} /></Button>
                            </div>
                            { this.state.showAutoTexts ? <div id="autoTextContainer">#1: {this.context.chatOptions.autotext1}<br />#2: {this.context.chatOptions.autotext2}<br />#3: {this.context.chatOptions.autotext3}<br />#4: {this.context.chatOptions.autotext4}<br />#5: {this.context.chatOptions.autotext5}</div> : null }
                            {this.state.chatMessages.map((msg:chatMessageStateObject,index) => <ChatMessage key={msg.id} className={msg.className} color={this.getColorFromClassName(msg.className)} msg={msg.msg} fromPart={msg.fromPart} messageClass={msg.messageClass} messageColor={this.getColorFromClassName(msg.messageClass)} /> )}
                        </div>
                        <div id="chatInputContainer">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{this.state.chatTargetPseudo === '' ? this.getText("ToAll") : this.getText("To") + " " + this.state.chatTargetPseudo}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl ref={this.chatInput} disabled={!this.state.canWrite} value={this.state.currentChatMessage} onKeyDown={this.handleChatMessageKeyDown.bind(this)} onChange={this.handleChatMessageChange.bind(this)} placeholder={this.getText("Chatmessage")} />
                                <InputGroup.Append>
                                    <Button variant="secondary" disabled={!this.state.canWrite} onClick={this.sendChatText}>Send</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <small>
                            Chat: {this.state.chatServer} {this.state.socketConnected ? <span className='oncircle'></span> : <span className='offcircle'></span>}{this.state.chatConnected ? <span className='oncircle'></span> : <span className='offcircle'></span>}&nbsp;
                            { this.state.cam2camTransportConnState1 !== "null" ? <span>Cam-2-Cam1: {this.state.cam2camTransportConnState1 === "connected" ? <span className='oncircle'></span> : <span className='offcircle'></span>}</span> : null }&nbsp;
                            { this.state.cam2camTransportConnState2 !== "null" ? <span>Cam-2-Cam2: {this.state.cam2camTransportConnState2 === "connected" ? <span className='oncircle'></span> : <span className='offcircle'></span>}</span> : null }
                            <br></br>
                            Stream: {this.state.broadcasting && !this.state.girlDisconnected && !this.state.broadcastFailed ? <span className='oncircle'></span> : <span className='offcircle'></span>}{this.state.manifestCheckState === "Manifest Success" || this.state.manifestCheckState === "CallID Success" ? <span className='oncircle'></span> : <span className='offcircle'></span>}&nbsp;
                            VideoBackup: {this.state.soup1VideoProducing ? <span className='oncircle'></span> : <span className='offcircle'></span>}&nbsp;
                            AudioBackup: {this.state.soup1AudioProducing ? <span className='oncircle'></span> : <span className='offcircle'></span>}
                        </small>
                    </Col>
                    <Col><div id="mysysmsg">{this.state.sysMessages.map((msg,index) => <div key={index}>{msg}</div> )}</div></Col>
                    <Col>
                        <div id="chatStatusContainer">
                            { this.state.chatConnected ? this.getText("YouAreOnline") : this.getText("YouAreOffline") }
                        </div>
                        <div id="mydebug">{this.state.debugMessages.map((msg,index) => <div key={index}>{msg}</div> )}</div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default chatInterface;