import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { ListGroup } from 'react-bootstrap';

const LovenseDeviceSelectPre = (props) => {
    const [discovering, setDiscovering] = React.useState(false);
    const [showOldMethods, setShowOldMethods] = React.useState(false);

    const queryLovenseDevicesLAN = () => {
        props.onChoice("LAN");
    }

    const queryLovenseDevicesWebAPI = () => {
        props.onChoice("WebAPI");
    }

    const queryLovenseDevices = () => {
        props.onChoice("local");
    }

    const clickShowOldMethods = () => {
        setShowOldMethods(!showOldMethods);
    }

    const getText = (exp) => {
        if(props.language === "de") {
            if(exp === "Select") {
                return "Wähle";
            }
            if(exp === "Lovense device detection") {
                return "Lovense Gerät ermitteln";
            }
            if(exp === "Discovering lovense devices via LAN") {
                return "Lovense Gerät per LAN-Anschluss ermitteln";
            }
            if(exp === "Discovering locally connected lovense devices") {
                return "Lokal angeschlossenes Lovense Gerät ermitteln";
            }
            if(exp === "Change device") {
                return "Gerät wechseln";
            }
            if(exp === "Locally Connected") {
                return "Lokal per Dongle";
            }
            if(exp === "Connected via LAN") {
                return "Suche im Netzwerk";
            }
            if(exp === "You need Lovense Connect for this to work") {
                return "Du benötigst die Lovense Connect Software und der Lush muss in der Lovense Connect Software als Connected angezeigt werden.";
            }
            if(exp === "Show Devices") {
                return "Geräte anzeigen";
            }
            if(exp === "Old methods to connect") {
                return "Falls Dein Lush nicht angezeigt wird, wenn Du auf den Button Geräte anzeigen klickst, kannst Du die Buttons unten versuchen um Dich zu verbinden."
            }
            if(exp === "Show old methods") {
                return "Mein Lush wird nicht angezeigt, wenn ich auf Geräte anzeigen klicke";
            }

            return exp;
        } else {
            if(exp === "You need Lovense Connect for this to work") {
                return "You need the Lovense Connect software for this to work, and the Lush must be connected in the Lovense Connect software.";
            }
            if(exp === "Old methods to connect") {
                return "If your Lush is not shown when you click on the button Show Devices, you can try the buttons below to connect."
            }
            if(exp === "Show old methods") {
                return "My Lush is not shown when clicking on Show Devices";
            }

            return exp;
        }
    }

    return(
        <ListGroup>
            <Row>
                <Col>{getText("You need Lovense Connect for this to work")}</Col>
            </Row>
            <Row>
                <Col><Button className='w-100' variant="secondary" onClick={queryLovenseDevicesWebAPI} size="sm">{getText("Show Devices")}</Button></Col>
            </Row>
            { !showOldMethods ? 
            <Row>
                <Col><hr></hr><Button className='w-100' variant="secondary" onClick={clickShowOldMethods} size="sm">{getText("Show old methods")}</Button></Col>
            </Row> : null }
            { showOldMethods ? 
            <Row>
                <Col>
                    <hr></hr>
                    {getText("Old methods to connect")}
                </Col>
            </Row> : null }
            { showOldMethods ? 
            <Row>
                <Col><Button className='w-100' variant="secondary" onClick={queryLovenseDevices} size="sm">{getText("Locally Connected")}</Button></Col>
                <Col><Button className='w-100' variant="secondary" onClick={queryLovenseDevicesLAN} size="sm">{getText("Connected via LAN")}</Button></Col>
            </Row> : null }
        </ListGroup>
    )
}

const LovenseDeviceSelectResultsDevice = (props) => {
    const device = props.device;

    const doSelectDevice = (domain,httpPort,httpsPort,toyID) => {
        props.onSelect(domain,httpPort,httpsPort,toyID);
    }

    return(
        <ListGroup.Item>
            <span className='text-white'>{device.name} {device.id} {device.appVersion} {device.platform} {typeof(device.battery) != "undefined" ? "Battery: " + device.battery : null}</span> <Button variant="secondary" onClick={doSelectDevice.bind(this,device.domain,device.httpPort,device.httpsPort,device.id)} size="sm">Select</Button>
        </ListGroup.Item>
    )
}

const LovenseDeviceSelectResults = (props) => {
    const lovenseDevices = props.lovenseDevices;

    return(
        <ListGroup>{lovenseDevices.map((obj,index) => <LovenseDeviceSelectResultsDevice key={index} onSelect={props.onSelect.bind(this)} json={JSON.stringify(obj)} device={obj} />)}</ListGroup>
    )
}

const LovenseDeviceSelect = (props) => {
    const [showDeviceList, setShowDeviceList] = React.useState(false);
    const [deviceList, setDeviceList] = React.useState(false);
    const [queryError, setQueryError] = React.useState("");
    const [queryRunning, setQueryRunning] = React.useState(false);
    const [querySuccess, setQuerySuccess] = React.useState(false);
    const [queryInfo, setQueryInfo] = React.useState("");
    const [language, setLanguage] = React.useState(props.language);

    React.useEffect(() => {
        onChoice(props.choice);
    }, [props.choice]);

    const onChoice = (method) => {
        setQueryError("");
        setQueryInfo("Querying devices " + method);
        setDeviceList([]);
        setQueryRunning(true);
        setQuerySuccess(false);

        const axios = require('axios').default;

        if(method === "WebAPI") {
            setQueryError("");
            setQueryInfo("");
            setQueryRunning(true);
            setQuerySuccess(false);

            let toysArray = window.lovense.getToys();

            if(toysArray == null) {
                setQueryRunning(false);
                setQuerySuccess(false);
                setQueryError("No Toys found");
                console.log("Lovense WebAPI empty toys array");
            } else {
                setQueryRunning(false);
                setQuerySuccess(true);

                console.log("Lovense WebAPI success");

                setQueryInfo("Found " + toysArray.length + " devices");

                let newDeviceList = [];

                for(let i = 0; i < toysArray.length; i++) {
                    console.log(toysArray[i]);

                    let newLovenseObject = {
                        platform: toysArray[i].fVersion,
                        name: toysArray[i].name,
                        id: toysArray[i].id,
                        battery: toysArray[i].battery,
                        status: toysArray[i].status,
                        nickName: toysArray[i].nickName,
                        domain: "127-0-0-1.lovense.club",
                        httpsPort: 30010
                    }

                    newDeviceList.push(newLovenseObject);
                }

                setDeviceList(newDeviceList);
                setShowDeviceList(true);
            }

        } else if(method === "LAN") {
            let jsonurl = "https://api.lovense.com/api/lan/getToys";

            axios.get(jsonurl).then((response) => {
                let data = null;

                setQueryInfo("");
                setQueryRunning(false);

                try {
                    data = response.data;
                } catch(e) {
                    setQueryError("Error converting data: " + e.toString());
                    setQueryInfo("");
                    setQueryRunning(false);
                    setQuerySuccess(false);
                    return;
                }

                if(data == null) {
                    setQueryError("No Data received");
                    setQueryInfo("");
                    setQueryRunning(false);
                    setQuerySuccess(false);

                    return;
                }

                var objArr = Object.entries(data);
    
                console.log("Lovense LAN success");
                console.log(data);
                setQuerySuccess(true);
    
                if(typeof(objArr) === "undefined") {
                    setQueryError("Data not parseable - " + JSON.stringify(data));
                    setQueryInfo("");
                    setQueryRunning(false);
                    setQuerySuccess(false);
                    return;
                }
            
                if(objArr.length === 0) {
                    setQueryError("No devices found");
                } else {
                    let newDeviceList = [];

                    for (var i = 0; i < objArr.length; i++) {
                        var curObject = objArr[i][1];
                        var Toys = Object.entries(curObject.toys);
            
                        for (var itoy = 0; itoy < Toys.length; itoy++) {
                            var curToy = Toys[itoy][1];
                            
                            let newLovenseObject = {
                                platform: curObject.platform,
                                name: curToy.name,
                                id: curToy.id,
                                status: curToy.status,
                                nickName: curToy.nickName,
                                domain: curObject.domain,
                                httpsPort: curObject.httpsPort
                            }
    
                            newDeviceList.push(newLovenseObject);
                        }
                    }

                    setDeviceList(newDeviceList);
                    setShowDeviceList(true);
                }
            }).catch(function(e) {
                setQueryError("Query Error: " + e.toString());
                setQueryInfo("");
                setQueryRunning(false);
                setQuerySuccess(false);
            });
        } else {
            let jsonurl = "https://127-0-0-1.lovense.club:30010/GetToys";

            axios.get(jsonurl).then((response) => {
                let data = null;

                setQueryInfo("");
                setQueryRunning(false);

                try {
                    data = response.data;
                } catch(e) {
                    setQueryError("Error converting data: " + e.toString());
                    setQueryInfo("");
                    setQueryRunning(false);
                    setQuerySuccess(false);
                    return;
                }

                if(data == null) {
                    setQueryError("No Data received");
                    setQueryInfo("");
                    setQueryRunning(false);
                    setQuerySuccess(false);
                    return;
                }

                console.log("Lovense success");
                console.log(data);
                setQuerySuccess(true);

                if(typeof(data) === "undefined") {
                    setQueryError("Data not parseable - " + JSON.stringify(data));
                    setQueryInfo("");
                    setQueryRunning(false);
                    setQuerySuccess(false);
                    return;
                }

                let objArr = Object.entries(data);

                if(objArr.length === 0) {
                    setQueryError("No Devices found");
                } else {
                    let newDeviceList = [];

                    for (var i = 0; i < objArr.length; i++) {
                        var curObject = objArr[i][1];
                            
                        let newLovenseObject = {
                            platform: "local",
                            name: curObject.name,
                            id: curObject.id,
                            status: curObject.status,
                            nickName: curObject.nickName,
                            domain: "127-0-0-1.lovense.club",
                            httpsPort: 30010
                        }

                        newDeviceList.push(newLovenseObject);
                    }

                    setShowDeviceList(true);
                }
            }).catch(function(e) {
                setQueryError("Query Error: " + e.toString());
                setQueryInfo("");
                setQueryRunning(false);
                setQuerySuccess(false);
            });
        }
    }

    function getText(exp) {
        if(language === "de") {
            if(exp === "You need Lovense Connect for this to work") {
                return "Du benötigst die Lovense Connect Software und der Lush muss in der Lovense Connect Software als Connected angezeigt werden.";
            }
            if(exp === "DevicesCollected") {
                return "Toys ermittelt";
            }

            return exp;
        } else {
            if(exp === "You need Lovense Connect for this to work") {
                return "You need the Lovense Connect software for this to work, and the Lush must be connected in the Lovense Connect software.";
            }

            return exp;
        }
    }

    return(
        <div>
            <p>{getText("You need Lovense Connect for this to work")}</p>
            <p>{queryInfo} {queryRunning ? <div class="meter" style={{width: "20%"}}><span style={{width: "100%"}}></span></div> : null}  { queryError != "" ? <span><span className="offcircle"></span> {queryError}</span> : null } {querySuccess ? <span><span className="oncircle"></span> {getText("DevicesCollected")}</span> : null}</p>
            { showDeviceList ? <LovenseDeviceSelectResults lovenseDevices={deviceList} onSelect={props.onSelect.bind(this)} /> : <LovenseDeviceSelectPre onChoice={onChoice} language={language} />}
        </div>
    )
}

export default LovenseDeviceSelect;