import React, { FunctionComponent,useEffect, useState } from "react";
import { PlayerUiState, LivelyPlayerUiContext, types, VideoClient } from "@livelyvideo/video-client-web";
import Player from "./Player";

type ModularPlayerProps = {
  manifestUrl: string
}
interface ModularPlayer {
  props: ModularPlayerProps;
}

export const ModularPlayer: FunctionComponent<ModularPlayerProps> = ({ manifestUrl }) => {
    const yourDomain = "https://guppy-prod-euw1d.livelyvideo.tv";

    const [vc, setVc] = useState<types.VideoClient | null>(null);
    const [playerUi, setPlayerUi] = useState<PlayerUiState | null>(null);
    const [audioEnabled, setAudioEnabled] = useState<boolean|null>(null);

    const debuglog = (msg:string) => {
      if(typeof(console) != "undefined") {
        console.log(msg);
      }
    }

    useEffect(() => {
      /*
      * 4.3a) Setup a VideoClient instance. Be sure to include a state check to avoid
      * an infinite loop.
      */
      if (vc == null) {
        const opts: types.VideoClientOptions = {
          livelyEndpoints: [yourDomain]
        };
      /*
      * 4.3b) Set local state.
      */
        const newVc = new VideoClient(opts);
        setVc(newVc);
      }
      /*
      * 4.3c) On unmount, be sure to dispose the VideoClient and set state to `null`.
      */
      return () => {
        if (vc != null) {
          vc.dispose();
          setVc(null);
        }
      };
    }, [vc]);

    useEffect(() => {
      /*
      * 7.2a. Check if playerUi is null to avoid a loop.
      */
      if (vc != null && playerUi == null && manifestUrl) {
          const options = {}; // Your PlayerOptions
          const player = vc.requestPlayer(manifestUrl, options);
          setPlayerUi(new PlayerUiState(player));
          return () => {
            if (playerUi != null) {
              let myPlayerUI:PlayerUiState = playerUi as PlayerUiState;
              if(myPlayerUI) {
                myPlayerUI.dispose();
              }
              setPlayerUi(null);
            }
          };
      }
    }, [vc, playerUi, manifestUrl]);

    /*
    * Let's add some Ui to remind us to include a manifestUrl
    */
    if (!manifestUrl) {
      return <h3>Please input a valid manifest url.</h3>;
    }

    if (playerUi == null) {
      return <></>;
    }

    return (
    <LivelyPlayerUiContext.Provider value={playerUi}>
        <Player />
    </LivelyPlayerUiContext.Provider>
    );
};

export default ModularPlayer;