import React from 'react';
import AppContext from './AppContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import { AxiosResponse } from 'axios';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

export interface ChatTerminProps {
    id:number;
    desc:string;
    startDate:string;
    startTime:string;
    endDate:string;
    endTime:string;
    deleteCallback: (id:number) => void;
}

interface ChatTermin {
    state: ChatTerminState;
    props: ChatTerminProps;
    deleteCallback: (id:number) => void;
}

interface ChatTerminState {
    id:number;
    desc:string;
    startDate:string;
    startTime:string;
    endDate:string;
    endTime:string;
}

class ChatTermin extends React.Component {
    static contextType = AppContext;

    constructor(props:ChatTerminProps) {
        super(props);
        this.deleteCallback = props.deleteCallback;

        this.state = {
            id: props.id,
            desc: props.desc,
            startDate: props.startDate,
            startTime: props.startTime,
            endDate: props.endDate,
            endTime: props.endTime
        }

        library.add(faTimes);
    }

    getText(exp:string) {
        if(this.context.language === "en") {
            if(exp === "Kein Enddatum") {
                return "No end date";
            }
        }

        return exp;
    }

    handleDelete() {
        this.deleteCallback(this.state.id);
    }

    render() {
        return(
            <div className="ChatTermin">
                <h6 className="ChatTerminTitle">
                    {this.state.startDate} {this.state.startTime} - { this.state.endDate !== "01.01.0001" ? this.state.endDate : this.getText("Kein Enddatum") } { this.state.endTime !== "00:00" ? this.state.endTime : null }
                    <div className="ChatTerminDelete" title="Delete" onClick={this.handleDelete.bind(this)}><FontAwesomeIcon icon={["fas", "times"]} /></div>
                </h6>
                <span>{this.state.desc}</span>
            </div>
        )
    }
}

export default ChatTermin;