export interface SignalingInterface {
    constructor(myPeerId:string):SignalingInterface;
    request(endpoint:string,data:any,beacon:any):any;
    requestSync():Promise<SyncResponse>;
    myPeerId:string;
    soupServer:string;
}

export interface SyncResponse {
    peers:Peer[];
    error?:any;
}

export interface Peer {
    peerId:string;
}

export class SignalingInterface implements SignalingInterface {
    constructor(myPeerId:string,soupServer:string) {
        this.myPeerId = myPeerId;
        this.soupServer = soupServer;
    }

    public async request(endpoint:string,data:any = null,beacon:any = null) {
        //console.log('signal request(' + this.myPeerId + ') ' + endpoint + " - " + JSON.stringify(data));

        try {
            let headers = { 'Content-Type': 'application/json' };
            let body:string = JSON.stringify({ ...data, peerId: this.myPeerId });

            if (beacon) {
                navigator.sendBeacon(this.soupServer + '/signaling/' + endpoint, body);
                return null;
            }
        
            let response = await fetch(
                this.soupServer + '/signaling/' + endpoint, { method: 'POST', body, headers }
            );

            return await response.json();
        } catch (e) {
            console.error(e);
            return { error: e };
        }
    }

    public async requestSync():Promise<SyncResponse> {
        let endpoint:string = "sync";

        //console.log('signal request(' + this.myPeerId + ') ' + endpoint);

        try {
            let headers = { 'Content-Type': 'application/json' };
            let body:string = JSON.stringify({ peerId: this.myPeerId });
        
            let response = await fetch(
                this.soupServer + '/signaling/' + endpoint, { method: 'POST', body, headers }
            );

            return await response.json();
        } catch (e:any) {
            console.error(e);
            return { peers:[],error: e };
        }
    }
}

export default SignalingInterface;