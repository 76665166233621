import Button from 'react-bootstrap/Button';
import { ListGroup } from 'react-bootstrap';

const DildoPortSelect = (props) => {
    //const [dildoPorts, setDildoPorts] = React.useState(false);

    const selectDildoPort = (comName) => {
        props.selectDildoPort(comName);
    }

    return(
        <ListGroup>
            {props.dildoPorts.map((port,index) => <ListGroup.Item key={index}>Port: {port.path} <Button onClick={selectDildoPort.bind(this,port.path)} variant="secondary" size="sm">Select</Button></ListGroup.Item>)}
        </ListGroup>
    )
}

export default DildoPortSelect;