import React from 'react';
import AppContext from './AppContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import { AxiosResponse } from 'axios';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

export interface UserNoteProps {
    comment:string;
    pseudo:string;
    guid:string;
    time:string;
    deleteCallback: (guid:string) => void;
}

interface UserNote {
    state: UserNoteState;
    props: UserNoteProps;
    deleteCallback: (guid:string) => void;
}

interface UserNoteState {
    comment:string;
    pseudo:string;
    guid:string;
    time:string;
}

class UserNote extends React.Component {
    static contextType = AppContext;

    constructor(props:UserNoteProps) {
        super(props);
        this.deleteCallback = props.deleteCallback;

        this.state = {
            guid: props.guid,
            pseudo: props.pseudo,
            time: props.time,
            comment: props.comment
        }

        library.add(faTimes);
    }

    getText(exp:string) {
        return exp;
    }

    handleDelete() {
        this.deleteCallback(this.state.guid);
    }

    render() {
        return(
            <div className="userNote">
                <h6 className="userNoteTitle">
                    {this.state.time} {this.state.pseudo}
                    <div className="userNoteDelete" title="Delete" onClick={this.handleDelete.bind(this)}><FontAwesomeIcon icon={["fas", "times"]} /></div>
                </h6>
                <span>{this.state.comment}</span>
            </div>
        )
    }
}

export default UserNote;