import CryptoJS from 'crypto-js';

export class CryptoHelper {
    constructor() {
    }

    getDecryptedString(transitmessage) {
        const encpass = "guppysenderencrypt";
        const keySize = 256;
        const ivSize = 128;
        const iterations = 100;

        var salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
        var iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32))
        var encrypted = transitmessage.substring(64);
        var key;

        try {
            key = CryptoJS.PBKDF2(encpass, salt, {
            keySize: keySize/32,
            iterations: iterations
            });
        } catch(e) {
            return "error PBKDF2: " + e.toString();
        }

        try {
            var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
                iv: iv, 
                padding: CryptoJS.pad.Pkcs7,
                mode: CryptoJS.mode.CBC
            });

            return decrypted.toString(CryptoJS.enc.Utf8);    
        } catch(e) {
            return "error CryptoJS.decrpyt: " + e.toString();
        }

        return "";
    }

    getEncryptedString(exp) {
        const encpass = "guppysenderencrypt";
        const keySize = 256;
        const ivSize = 128;
        const iterations = 100;
        
        var salt = CryptoJS.lib.WordArray.random(128/8);
        
        var key = CryptoJS.PBKDF2(encpass, salt, {
            keySize: keySize/32,
            iterations: iterations
        });
        
        var iv = CryptoJS.lib.WordArray.random(128/8);
        
        var encrypted = CryptoJS.AES.encrypt(new String(exp).trim(), key, {
            iv: iv, 
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        }).toString();
        
        var transitmessage = salt.toString()+ iv.toString() + encrypted.toString();

        return transitmessage;
    }
}