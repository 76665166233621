import React from 'react';
import AppContext from './AppContext';

class UserMicAudio extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            userPseudo: props.userPseudo,
            streamname: props.streamname,
            stateMsg: '',
            flashChatSessionID: props.flashChatSessionID,
            streamFound: false,
            audioPlaying: false,
            soupNum: 0,
            userPeerId: '',
            divTop: 110 + (props.index * 20),
            divLeft: 10 + (props.index * 20),
            opacity: "1.0"
        };

        this.closeCallback = props.closeCallback;
        this.checkCounter = 0;

        this.Client = props.client;
        this.peer = null;
        this.dragStartX = 0;
        this.dragStartY = 0;
        this.userMicIntervalID = 0;
        
        this.audioElement = React.createRef();
        this.audioElementContainer = React.createRef();
        this.lastPlayTime = 0.0;
        this.sysMessage = props.sysMessage.bind(this);
    }

    componentDidMount() {
        this.pollSoupUserMic();
        this.userMicIntervalID = setInterval(this.pollSoupUserMic,5000);
    }

    componentWillUnmount() {
        if(this.userMicIntervalID !== 0) {
            clearInterval(this.userMicIntervalID);
        }
        if(this.peer) {
            if(this.peer.soupNum === 2) {
                try {
                    this.sysMessage("Unsubscribing from usermic track " + this.peer.id,"");
                    this.Client.unsubscribeFromTrackSecond(this.peer.id, "cam-audio");
                } catch(e) {
                    this.sysMessage("Error unsubscribing from usermic track " + e.toString(),"");
                    return;
                }
            } else if(this.peer.soupNum === 3) {
                try {
                    this.sysMessage("Unsubscribing from usermic track " + this.peer.id,"");
                    this.Client.unsubscribeFromTrackThird(this.peer.id, "cam-audio");
                } catch(e) {
                    this.sysMessage("Error unsubscribing from usermic track " + e.toString(),"");
                    return;
                }
            } else {
                try {
                    this.sysMessage("Unsubscribing from usermic track " + this.peer.id,"");
                    this.Client.unsubscribeFromTrack(this.peer.id, "cam-audio");
                } catch(e) {
                    this.sysMessage("Error unsubscribing from usermic track " + e.toString(),"");
                    return;
                }
            }
        }
    }

    pollSoupUserMic = async() => {
        let Client = this.Client;
        let webrtcNick = this.context.loginData.pseudo;
        let userMicInfos = this.userMicInfos;

        if(!Client.joined && this.context.enableSoup1) {
            await Client.joinRoom();
        }
        if(!Client.secondJoined && this.context.enableSoup2) {
            await Client.joinSecondRoom();
        }
        if(!Client.thirdJoined && this.context.enableSoup3) {
            await Client.joinThirdRoom();
        }

        if(this.state.audioPlaying) {
            let audio = this.audioElement.current;

            if(audio) {
                if(this.lastPlayTime === audio.currentTime) {
                    this.sysMessage("usermic audio seems stalled");
                    this.setState({audioPlaying: false});
                    this.setState({stateMsg: 'seemsstalled'});
                    /*this.sysMessage("Unsubscribing from usermic audio track " + this.state.userPeerId + " on soup " + this.state.soupNum,"");
                    if(this.state.soupNum === 2) {
                        try {
                            Client.unsubscribeFromTrackSecond(this.state.userPeerId, "cam-audio");
                        } catch(e) {
                            this.sysMessage("Error unsubscribing from usermic track " + e.toString(),"");
                            return;
                        }
                    } else if(this.state.soupNum === 3) {
                        try {
                            Client.unsubscribeFromTrackThird(this.state.userPeerId, "cam-audio");
                        } catch(e) {
                            this.sysMessage("Error unsubscribing from usermic track " + e.toString(),"");
                            return;
                        }
                    } else if(this.state.soupNum === 1) {
                        try {
                            Client.unsubscribeFromTrack(this.state.userPeerId, "cam-audio");
                        } catch(e) {
                            this.sysMessage("Error unsubscribing from usermic track " + e.toString(),"");
                            return;
                        }
                    }*/
                }
                this.lastPlayTime = audio.currentTime;
            }
        }

        let searchPeerID = new String(this.state.streamname);

        if(new String(webrtcNick).indexOf("Testsender") > -1) {
            this.sysMessage("pollUserMic looking for " + this.state.userPseudo + " | " + searchPeerID);
        }

        if(this.context.enableSoup1) {
            for (let peer of Client.sortPeers(Client.lastPollSyncData)) {
                if (peer.id === Client.myPeerId) {
                    continue;
                }
                for (let [mediaTag, info] of Object.entries(peer.media)) {
                    if(searchPeerID.localeCompare(peer.id) === 0) {
                        if(new String(webrtcNick).indexOf("Testsender") > -1) {
                            this.sysMessage("Found UserMic peer " + peer.id + " -> " + this.state.userPseudo + " on soup1");
                        }
                        // Update 10.11.2021 - der info.paused check bringt nichts
                        if(info.paused && false) {
                            this.checkCounter++;
                            if(this.checkCounter > 3) {
                                if(this.checkCounter === 4) {
                                    this.sysMessage("Leider kann das UserMic von " + this.state.userPseudo + " nicht abgespielt  werden, da er sein Mikro nicht überträgt");
                                }
                                clearInterval(this.userMicIntervalID);
                                this.userMicIntervalID = 0;
                            }
                        } else {
                            this.setState({streamFound: true});
                            if(!this.state.audioPlaying) {
                                this.addSoupUserMic({
                                    id: peer.id,
                                    nickName: this.state.userPseudo,
                                    flashChatSessionID: this.state.flashChatSessionID,
                                    soupNum: 1
                                });
                            }
                        }
                        return;
                    }
                }
            }
        }
    
        if(this.context.enableSoup2) {
            for (let peer of Client.sortPeers(Client.secondPollSyncData)) {
                if (peer.id === Client.secondPeerID) {
                    continue;
                }
                for (let [mediaTag, info] of Object.entries(peer.media)) {
                    if(searchPeerID.localeCompare(peer.id) === 0) {
                        if(new String(webrtcNick).indexOf("Testsender") > -1) {
                            this.sysMessage("Found UserMic peer " + peer.id + " -> " + this.state.userPseudo);
                        }
                        // Update 10.11.2021 - der info.paused check bringt nichts
                        if(info.paused && false) {
                            this.checkCounter++;
                            if(this.checkCounter > 3) {
                                if(this.checkCounter === 4) {
                                    this.sysMessage("Leider kann das UserMic von " + this.state.userPseudo + " nicht abgespielt  werden, da er sein Mikro nicht überträgt");
                                }
                                clearInterval(this.userMicIntervalID);
                                this.userMicIntervalID = 0;
                            }
                        } else {
                            this.setState({streamFound: true});
                            if(!this.state.audioPlaying) {
                                this.addSoupUserMic({
                                    id: peer.id,
                                    nickName: this.state.userPseudo,
                                    flashChatSessionID: this.state.flashChatSessionID,
                                    soupNum: 2
                                });
                            }
                        }
                        return;
                    }
                }
            }
        }
    
        if(this.context.enableSoup3) {
            for (let peer of Client.sortPeers(Client.thirdPollSyncData)) {
                if (peer.id === Client.thirdPeerID) {
                    continue;
                }
                for (let [mediaTag, info] of Object.entries(peer.media)) {
                    if(searchPeerID.localeCompare(peer.id) === 0) {
                        if(new String(webrtcNick).indexOf("Testsender") > -1) {
                            this.sysMessage("Found UserMic peer " + peer.id + " -> " + this.state.userPseudo);
                        }
                        // Update 10.11.2021 - der info.paused check bringt nichts
                        if(info.paused && false) {
                            this.checkCounter++;
                            if(this.checkCounter > 3) {
                                if(this.checkCounter === 4) {
                                    this.sysMessage("Leider kann das UserMic von " + this.state.userPseudo + " nicht abgespielt  werden, da er sein Mikro nicht überträgt");
                                }
                                clearInterval(this.userMicIntervalID);
                                this.userMicIntervalID = 0;
                            }
                        } else {
                            this.setState({streamFound: true});
                            if(!this.state.audioPlaying) {
                                this.addSoupUserMic({
                                    id: peer.id,
                                    nickName: this.state.userPseudo,
                                    flashChatSessionID: this.state.flashChatSessionID,
                                    soupNum: 3
                                });
                            }
                        }
                        return;
                    }
                }
            }
        }
    }

    sleep = async(ms) => {
        return new Promise((r) => setTimeout(() => r(), ms));
    }
    
    addSoupUserMic = async(peer) => {
        let Client = this.Client;
        let consumer;
        let webrtcNick = this.context.loginData.pseudo;
        
        if(peer) {
            if(typeof(peer.id) === "undefined") {
                return;
            } else {
                if(peer.id === "") {
                    return;
                }
            }
        }

        this.peer = peer;

        if(peer.soupNum === 2) {
            try {
                //consumer = await Client.subscribeToTrackSecond(peer.id, "cam-audio");
                let peerId = peer.id;
                let mediaTag = "cam-audio";

                this.sysMessage('subscribe to track ' + peerId + " " + mediaTag,"");
    
                // create a receive transport if we don't already have one
                if (Client.secondRecvTransport) {
                    if (Client.secondRecvTransport.connectionState == "disconnected") {
                        Client.secondRecvTransport = await Client.createSecondTransport('recv');
                    }
                } else {
                    Client.secondRecvTransport = await Client.createSecondTransport('recv');
                }
            
                // if we do already have a consumer, we shouldn't have called this method
                consumer = Client.findConsumerForTrackSecond(peerId, mediaTag);
                if (consumer) {
                    console.error('already have consumer for track', peerId, mediaTag);
                    await Client.resumeConsumerSecond(consumer);
                } else {
                    // ask the server to create a server-side consumer object and send
                    // us back the info we need to create a client-side consumer
                    let consumerParameters = await Client.secondSig('recv-track', {
                        mediaTag,
                        mediaPeerId: peerId,
                        rtpCapabilities: Client.secondDevice.rtpCapabilities
                    });
                    this.sysMessage('consumer parameters ' + consumerParameters,"");
                    consumer = await Client.secondRecvTransport.consume({
                        ...consumerParameters,
                        appData: { peerId, mediaTag }
                    });
                    this.sysMessage('created new consumer ' + consumer.id);
                
                    // the server-side consumer will be started in paused state. wait
                    // until we're connected, then send a resume request to the server
                    // to get our first keyframe and start displaying video
                    while (Client.secondRecvTransport.connectionState !== 'connected') {
                        this.sysMessage('  transport connstate ' + Client.secondRecvTransport.connectionState,"");
                        await this.sleep(100);
                    }

                    this.sysMessage('transport connstate ' + Client.secondRecvTransport.connectionState + " -> calling resumeConsumer","");

                    // okay, we're ready. let's ask the peer to send us media
                    await Client.resumeConsumerSecond(consumer);
                
                    // keep track of all our consumers
                    Client.secondConsumers.push(consumer);

                    this.sysMessage("consumer.id: " + consumer.id + " | consumer.closed: " + consumer.closed + " | consumer.kind: " + consumer.kind);
                    this.sysMessage("consumer.track: " + consumer.track);
                    this.sysMessage("consumer.track.readyState: " + consumer.track.readyState);
                }
            } catch(e) {
                this.sysMessage("error creating usermic consumer for peer: " + peer.id + " on soup2 |" + e.toString(),"");
                /*try {
                    Client.secondRecvTransport && await Client.secondRecvTransport.close();
                } catch (e) {
                  console.error(e);
                }*/
                return;
            }
        } else if(peer.soupNum === 3) {
            try {
                consumer = await Client.subscribeToTrackThird(peer.id, "cam-audio");
            } catch(e) {
                this.sysMessage("error creating usermic consumer for peer: " + peer.id + " on soup3 |" + e.toString(),"");
                /*try {
                    Client.thirdRecvTransport && await Client.thirdRecvTransport.close();
                } catch (e) {
                  console.error(e);
                }*/
                return;
            }
        } else {
            try {
                consumer = await Client.subscribeToTrack(peer.id, "cam-audio");
            } catch(e) {
                this.sysMessage("error creating usermic consumer for peer: " + peer.id + " on soup1 |" + e.toString(),"");
                /*try {
                    Client.recvTransport && await Client.recvTransport.close();
                } catch (e) {
                  console.error(e);
                }*/
                return;
            }
        }
    
        if (!(consumer && consumer.track)) {
            this.sysMessage("we have an invalid usermic consumer for peer: " + peer.id + "|" + peer.nickName);
            return;
        }

        this.setState({
            soupNum: peer.soupNum,
            userPeerId: peer.id
        });
        
        let audio = this.audioElement.current;

        if(!audio) {
            return;
        }
        
        if(new String(webrtcNick).indexOf("Testsender") > -1) {
            //audio.setAttribute('controls', 'controls');
        }
        audio.setAttribute('loop', '');
        audio.setAttribute('autoplay', 'true');
        audio.setAttribute('playsinline', 'true');

        audio.srcObject = new MediaStream([ consumer.track ]);
        audio.sysMessage = this.props.sysMessage.bind(this);
        audio.setState = this.proxySetState.bind(this);

        this.setState({stateMsg: 'starting'});

        // let's "yield" and return before playing, rather than awaiting on
        // play() succeeding. play() will not succeed on a producer-paused
        // track until the producer unpauses.
        audio.play()
        .then(()=>{
            if(this.setState.stateMsg !== 'failed') {
                this.setState({audioPlaying: true});
                this.setState({stateMsg: 'playing'});
            }
        })
        .catch((e) => {
            this.sysMessage("Failed to play usermic audio");
            this.setState({stateMsg: 'failed'});
            this.setState({audioPlaying: false});
            this.sysMessage("Unsubscribing from usermic audio track " + peer.id + " on soup " + peer.soupNum,"");
            if(peer.soupNum === 2) {
                try {
                    Client.unsubscribeFromTrackSecond(peer.id, "cam-audio");
                } catch(e) {
                    this.sysMessage("Error unsubscribing from usermic track " + e.toString(),"");
                    return;
                }
            } else if(peer.soupNum === 3) {
                try {
                    Client.unsubscribeFromTrackThird(peer.id, "cam-audio");
                } catch(e) {
                    this.sysMessage("Error unsubscribing from usermic track " + e.toString(),"");
                    return;
                }
            } else {
                try {
                    Client.unsubscribeFromTrack(peer.id, "cam-audio");
                } catch(e) {
                    this.sysMessage("Error unsubscribing from usermic track " + e.toString(),"");
                    return;
                }
            }
        });

        audio.addEventListener("stalled",function(e) {
            this.sysMessage("stalled event usermic");
            this.setState({audioPlaying: false});
            this.setState({stateMsg: 'stalled'});
        });
        audio.addEventListener("error",function(e) {
            this.sysMessage("error event usermic");
            this.setState({audioPlaying: false});
            this.setState({stateMsg: 'error'});
        });
    }

    handleDrag(ev) {
        this.dragStartX = ev.pageX - ev.target.offsetLeft;
        this.dragStartY = ev.pageY - ev.target.offsetTop;
        this.setState({opacity: "0.4"});
    }

    handleDragEnd(ev) {
        this.setState({divLeft: ev.pageX - this.dragStartX});
        this.setState({divTop: ev.pageY - this.dragStartY});
        this.setState({opacity: "1.0"});
    }

    proxySetState(stateObj) {
        this.setState(stateObj);
    }

    handleRestart() {
        this.setState({audioPlaying: false});
        this.setState({stateMsg: 'restart'});
        let peer = this.peer;
        let Client = this.Client;
        if(peer) {
            if(peer.soupNum === 2) {
                try {
                    Client.unsubscribeFromTrackSecond(peer.id, "cam-audio");
                } catch(e) {
                    return;
                }
            } else if(peer.soupNum === 3) {
                try {
                    Client.unsubscribeFromTrackThird(peer.id, "cam-audio");
                } catch(e) {
                    return;
                }
            } else {
                try {
                    Client.unsubscribeFromTrack(peer.id, "cam-audio");
                } catch(e) {
                    return;
                }
            }
        }
    }

    handleClose() {
        this.closeCallback(this.state.userPseudo,this.state.streamname,this.state.flashChatSessionID);
    }

    render() {
        return(
            <div draggable={true} onDragStart={this.handleDrag.bind(this)} onDragEnd={this.handleDragEnd.bind(this)} ref={this.audioElementContainer} style={{opacity: this.state.opacity,top: this.state.divTop,left: this.state.divLeft}} className="userMicAudio">
                <div className="userMicAudioLabel">
                    <span>Usermic {this.state.stateMsg} {this.state.userPseudo}</span>
                    <div className="userMicAudioLabelButtons">
                        <button className="userMicRestartButton" onClick={this.handleRestart.bind(this)}>&#xE14A;</button>
                        <button onClick={this.handleClose.bind(this)} className="userMicCloseButton">&#xE8BB;</button>
                    </div>
                </div>
                <audio className="userMicAudioElement" ref={this.audioElement} controls></audio>
            </div>
        )
    }
}

export default UserMicAudio;