import React from 'react';
import AppContext from './AppContext';
import { Device } from 'mediasoup-client';
import { types } from 'mediasoup-client';
import SignalingInterface, { SyncResponse } from './SignalingInterface'; // Our own signaling stuff.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMicrophone,faPlay } from '@fortawesome/pro-solid-svg-icons';

interface UserMicAudioSoup {
    state:UserMicAudioSoupState;
    props:UserMicAudioSoupProps;
    closeCallback(userPseudo:string,streamname:string,flashChatSessionID:string):void;
    checkCounter:number;
    sizeChangeTrackCounter:number;
    dragStartX:number;
    dragStartY:number;
    cam2CamPollIntervalID:number;
    audioElementContainer:any;
    sysMessage(msg:string):void;
    index:number;
    checkUserMicTimerEnabled:boolean;
    checkUserMicTimer:NodeJS.Timer;
    mySignaling: SignalingInterface;
    device: Device;
    syncTimer:NodeJS.Timer;
    recvTransport: types.Transport;
    audioConsumer: types.Consumer;
    myPeerId: string;
    audioElementRef: React.RefObject<HTMLAudioElement>;
}

interface UserMicAudioSoupState {
    userPseudo:string;
    peerId:string,
    soupServer:string,
    stateMsg:string;
    flashChatSessionID:string;
    divTop:number;
    divLeft:number;
    opacity:string;
    initialized: boolean;
    debugmsg: string;
    connstate: string;
    peers: any[];
}

interface UserMicAudioSoupProps {
    userPseudo:string,
    peerId:string,
    soupServer:string,
    flashChatSessionID:string,
    closeCallback(userPseudo:string,streamname:string,flashChatSessionID:string):void,
    sysMessage(msg:string):void,
    index:number
}

class UserMicAudioSoup extends React.Component {
    static contextType = AppContext;

    constructor(props:UserMicAudioSoupProps) {
        super(props);

        this.state = {
            userPseudo: props.userPseudo,
            peerId: props.peerId,
            soupServer: props.soupServer,
            stateMsg: '',
            flashChatSessionID: props.flashChatSessionID,
            divTop: 110 + (props.index * 20),
            divLeft: 10 + (props.index * 20),
            opacity: "1.0",
            initialized: false,
            debugmsg: '',
            connstate: 'Not initialized',
            peers: []
        };

        library.add(faMicrophone,faPlay);

        this.closeCallback = props.closeCallback;
        this.checkCounter = 0;
        this.sizeChangeTrackCounter = 0;

        this.dragStartX = 0;
        this.dragStartY = 0;
        this.cam2CamPollIntervalID = 0;
        this.audioElementContainer = React.createRef();
        this.sysMessage = props.sysMessage.bind(this);
        this.index = props.index;

        this.audioElementRef = React.createRef();
    }

    componentDidMount() {
        if(!this.checkUserMicTimerEnabled) {
            this.checkUserMicTimer = setInterval(this.checkUserMicStatus.bind(this), 250);
            this.checkUserMicTimerEnabled = true;
        }

        this.init();
    }

    componentWillUnmount() {
        if(this.checkUserMicTimerEnabled) {
            clearInterval(this.checkUserMicTimer);
        }

        clearInterval(this.syncTimer);

        try {
            if(this.audioConsumer) {
                this.audioConsumer.close();
            }
        } catch(e:any) {
            console.log("Error closing audioConsumer: " + e);
        }

        this.cleanupSoup();
    }

    cleanupSoup = async() => {
        try {
            if(this.recvTransport) {
                this.recvTransport.close();
            }
        } catch(e:any) {
            console.log("Error closing recvTransport: " + e);
        }

        try {
            await this.mySignaling.request('leave');
        } catch(e:any) {
            console.log("Error leaving as soup peer: " + e);
        }
    }

    sync = async() => {
        if (this.state.initialized) {
            const mySyncResponse:SyncResponse = await this.mySignaling.requestSync();
            if (mySyncResponse) {
                if(mySyncResponse.error) {
                    this.setState({debugmessage: console.error(mySyncResponse.error.toString())});
                } else {
                    this.setState({peers: mySyncResponse.peers});
                }
            }
        }
    }

    uuidv4():string {
        return ('111-111-1111').replace(/[018]/g, () =>
               (crypto.getRandomValues(new Uint8Array(1))[0] & 15).toString(16));
    }

    init = async() => {
        this.setState({encoderDevicesSelectable: true});
        this.setState({initializing: true});

        this.myPeerId = this.uuidv4();

        this.setState({peerID: this.myPeerId});

        this.mySignaling = new SignalingInterface(this.myPeerId,"https://soup01.guppy.live:3000");

        // Create a device (use browser auto-detection).
        this.device = new Device();

        // Communicate with our server app to retrieve router RTP capabilities.
        let {routerRtpCapabilities} = await this.mySignaling.request('join-as-new-peer');

        // Load the device with the router RTP capabilities.
        await this.device.load({ routerRtpCapabilities });

        // Check whether we can produce video to the router.
        if (this.device.loaded)
        { 
            this.setState({debugmsg: 'device loaded'});
        } else {
            console.warn('device not loaded');
            this.setState({debugmsg: 'device not loaded'});
            // Abort next steps.
            return;
        }

        this.setState({initialized: true});
        this.syncTimer = setInterval(this.sync.bind(this), 1000);

        const {transportOptions} = await this.mySignaling.request('create-transport', { 'direction':'recv' });

        console.log(transportOptions);
        this.setState({debugmsg: 'created transport on server: ' + transportOptions.id});

        // Create the local representation of our server-side transport.
        this.recvTransport = this.device.createRecvTransport(transportOptions);

        // Set transport "connect" event handler.
        this.recvTransport.on('connect', this.onRecvTransportConnect.bind(this));
        this.recvTransport.on('connectionstatechange', this.onRecvTransportConnectionStateChange.bind(this));

        this.setState({debugmsg: 'trying to consume audio'});

        // this.state.peerId is the target peerId of the user we want to consume audio from
        let {producerId,id,kind,rtpParameters,type,producerPaused,error} = await this.mySignaling.request('recv-track', {
            peerId: this.myPeerId,
            mediaTag: "cam-audio",
            mediaPeerId: this.state.peerId,
            rtpCapabilities: this.device.rtpCapabilities
        });

        if(typeof(error) != 'undefined') {
            if(error) {
                if(error !== '') {
                    console.error("Error receiving usermic consumer from soupserver: " + error);
                    this.setState({debugmsg: "Error receiving usermic consumer from soupserver: " + error});
                    this.setState({stateMsg: "Consume Error"});
                    return;
                }
            }
        }

        this.setState({debugmsg: "got consumer params"});

        console.log("received consumerParameters - id:" + id);
        console.log("received consumerParameters - producerId:" + producerId);
        console.log("received consumerParameters - kind:" + kind);
        console.log("received consumerParameters - type:" + type);
        console.log("received consumerParameters - producerPaused:" + producerPaused);
        console.log("received consumerParameters: " + JSON.stringify(rtpParameters));

        try {
            this.audioConsumer = await this.recvTransport.consume({
                id: id,
                producerId: producerId,
                rtpParameters: rtpParameters,
                kind: kind,
                appData: { mediaTag: "cam-audio", mediaPeerId: this.state.peerId }
            });
            this.mySignaling.request('resume-consumer', { consumerId: id });

            if(this.audioElementRef.current) {
                this.audioElementRef.current.srcObject = new MediaStream([this.audioConsumer.track]);
                this.audioElementRef.current.play();
            } else {
                this.setState({debugmsg: "no audioElementRef"});
                console.error("no audioElementRef");
            }
            
        } catch(e:any) {
            this.setState({debugmsg: "ConsumeException: " + e.toString() + "|" + producerId + "|" + id + "|" + kind + "|" + type});
            this.setState({stateMsg: "Consume Exception"});
        }
    }

    onRecvTransportConnect = async ({ dtlsParameters }: any, callback: () => void, errback: (arg0: unknown) => void) =>{
        this.setState({debugmsg: 'sendTransport connected'});

        // Here we must communicate our local parameters to our remote transport.
        try
        {
            await this.mySignaling.request('connect-transport', 
            {
                transportId: this.recvTransport.id,
                dtlsParameters
            });

            // Done in the server, tell our transport.
            callback();
        }
        catch (error)
        {
            // Something was wrong in server side.
            errback(error);
        }
    }

    onRecvTransportConnectionStateChange = async (state:string) => {
        this.setState({connstate: state});
        if (state === 'closed' || state === 'failed' || state === 'disconnected') {
            this.setState({debugmsg: 'recvTransport closed'});
        }
    }

    handleDrag(ev: React.DragEvent<HTMLDivElement>):void {
        const target = (ev.target as HTMLDivElement);
        this.dragStartX = ev.pageX - target.offsetLeft;
        this.dragStartY = ev.pageY - target.offsetTop;
        this.setState({opacity: "0.4"});
    }

    handleDragEnd(ev: React.DragEvent<HTMLDivElement>):void {
        this.setState({divLeft: ev.pageX - this.dragStartX});
        this.setState({divTop: ev.pageY - this.dragStartY});
        this.setState({opacity: "1.0"});
    }

    handleClose() {
        this.closeCallback(this.state.userPseudo,this.state.peerId,this.state.flashChatSessionID);
    }

    checkUserMicStatus() {
        let audioEl:HTMLAudioElement|null = this.audioElementRef.current;
        
        if(audioEl !== null) {
            if(audioEl.readyState === 4) {
                this.setState({stateMsg: "Playing"});
            } else {
                this.setState({stateMsg: "Loading " + audioEl.readyState.toString()});
            }
        } else {
            this.setState({stateMsg: "No AudioElement"});
        }
    }

    render() {
        return(
            <div className="userMicAudio" draggable={true} onDragStart={this.handleDrag.bind(this)} onDragEnd={this.handleDragEnd.bind(this)} ref={this.audioElementContainer} style={{opacity: this.state.opacity,top: this.state.divTop,left: this.state.divLeft, height: "60px", paddingTop: "20px"}}>
                <div className="userMicAudioLabel">
                    <span className="userMicAudioLabelSpan">
                        <FontAwesomeIcon icon={["fas", "microphone"]} />&nbsp;
                        {this.state.connstate == 'connected' ? <span className='oncircle'></span> : <span className='offcircle'></span>}&nbsp;
                        {this.state.userPseudo} UserMic
                        {this.state.stateMsg == 'playing' ? <FontAwesomeIcon icon={["fas", "play"]} /> : this.state.stateMsg}
                    </span>
                    <div className="userMicAudioLabelButtons">
                        <button onClick={this.handleClose.bind(this)} className="userMicCloseButton">&#xE8BB;</button>
                    </div>
                </div>
                <p style={{fontSize: "8pt",display: "none"}}>{this.state.debugmsg}</p>
                <audio ref={this.audioElementRef} controls></audio>
            </div>
        )
    }
}

export default UserMicAudioSoup;