import React from 'react';
import AppContext from './AppContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar,faGem,faPhone,faVolumeUp,faWebcam,faMicrophone,faUserLock,faStickyNote } from '@fortawesome/pro-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import { AxiosResponse } from 'axios';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

export interface UserListEntryProps {
    userType:string;
    pseudo:string;
    isFromCMS:boolean;
    guid:string;
    sound:boolean;
    lovense:boolean;
    dildo:boolean;
    cam2cam:boolean;
    userMic:boolean;
    privateSession: boolean;
    multiPrivate:boolean;
    updateNotes:boolean;
    selectCallback: (pseudo:string,guid:string) => void;
    openNotesCallback: (pseudo:string,guid:string) => void;
}

interface UserListEntry {
    state: UserListEntryState;
    props: UserListEntryProps;
    checkUpdateNotesIntervalID: NodeJS.Timeout;
    selectCallback: (pseudo:string,guid:string) => void;
    openNotesCallback: (pseudo:string,guid:string) => void;
}

interface UserListEntryState {
    userType:string;
    guid:string;
    pseudo:string;
    isFromCMS:boolean;
    sound:boolean;
    lovense:boolean;
    dildo:boolean;
    cam2cam:boolean;
    userMic:boolean;
    privateSession: boolean;
    multiPrivate:boolean;
    numberOfNotes: number;
    estatus:string;
    updateNotes: boolean;
}

class UserListEntry extends React.Component {
    static contextType = AppContext;

    constructor(props:UserListEntryProps) {
        super(props);
        this.selectCallback = props.selectCallback;
        this.openNotesCallback = props.openNotesCallback;

        this.state = {
            userType: props.userType,
            guid: props.guid,
            pseudo: props.pseudo,
            isFromCMS: props.isFromCMS,
            sound: props.sound,
            lovense: props.lovense,
            dildo: props.dildo,
            cam2cam: props.cam2cam,
            userMic: props.userMic,
            privateSession: props.privateSession,
            multiPrivate: props.multiPrivate,
            numberOfNotes: -1,
            estatus: "",
            updateNotes: props.updateNotes
        }

        library.add(faStar,faGem,faPhone,faVolumeUp,faWebcam,faMicrophone,faUserLock,faStickyNote);
    }

    componentDidMount() {
        if(this.props.userType !== "sender") {
            this.checkUpdateNotesIntervalID = setInterval(this.checkUpdateNotes.bind(this),5000);
        }
    }

    componentWillUnmount() {
        if(this.props.userType !== "sender") {
            clearInterval(this.checkUpdateNotesIntervalID);
        }
    }

    checkUpdateNotes() {
        if(this.props.updateNotes) {
            this.fetchNumNotes();
        }
    }

    getUserPseudo() {
        if(this.props.userType === "voyeur" || this.props.userType === "chatvoyeur" || this.props.userType === "voyeurcns24") {
            return "Voyeur";
        }

        return this.props.pseudo;
    }

    handleSelectUser() {
        if(this.props.userType === "sender") {
            this.selectCallback("","");
        } else {
            this.selectCallback(this.props.pseudo,this.props.guid);
        }
    }

    openNotes() {
        if(this.props.userType === "sender") {
            return;
        } else {
            this.openNotesCallback(this.props.pseudo,this.props.guid);
        }
    }

    fetchNumNotes = async() => {
        const axios = require('axios').default;

        let url:string = "https://streamauth.guppy.live/FetchUserNotes.aspx?action=getnum&guid=" + encodeURIComponent(this.props.guid);
    
        try {
            const response = await axios.get(url);
            this.setState({numberOfNotes:response.data.numnotes});
        } catch (error) {
            this.setState({estatus:"error fetching notes"});
        }
    }

    render() {
        return(
            <div className={this.props.userType === "voyeur" || this.props.userType === "chatvoyeur" || this.props.userType === "voyeurcns24" ? "userListEntryInvisible" : "userListEntry"}>
                {this.props.userType === "freeaccount" ? <FontAwesomeIcon icon={["fas", "star"]} /> : null }
                {this.props.isFromCMS ? <FontAwesomeIcon icon={["fas", "gem"]} /> : null }
                {this.props.userType === "cns24pbcuser" || this.props.userType === "pbcuser" || this.props.userType === "pbctime" ? <FontAwesomeIcon icon={["fas", "phone"]} /> : null }
                <span onClick={this.handleSelectUser.bind(this)}>{this.props.pseudo}</span>
                {this.props.sound ? <FontAwesomeIcon icon={["fas", "volume-up"]} /> : null }
                {this.props.lovense ? "[lovense]" : null }
                {this.props.dildo ? <img src={"dildo_control_icon.png"} width="32" height="32" /> : null }
                {this.props.cam2cam ? <FontAwesomeIcon icon={["fas", "webcam"]} /> : null }
                {this.props.userMic ? <FontAwesomeIcon icon={["fas", "microphone"]} /> : null }
                {this.props.privateSession ? <FontAwesomeIcon icon={["fas", "user-lock"]} /> : null }
                {this.props.multiPrivate ? <FontAwesomeIcon icon={["fas", "volume-up"]} /> : null }
                {this.state.numberOfNotes >= 0 ? <Badge onClick={this.openNotes.bind(this)}>{ this.state.numberOfNotes } <FontAwesomeIcon icon={["fas", "sticky-note"]} /></Badge> : null }
                {this.state.estatus !== "" ? <Badge>{ this.state.estatus }</Badge> : null }
            </div>
        )
    }
}

export default UserListEntry;