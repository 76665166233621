import React from 'react';
import AppContext from './AppContext';
import { Device } from 'mediasoup-client';
import { types } from 'mediasoup-client';
import SignalingInterface, { SyncResponse } from './SignalingInterface'; // Our own signaling stuff.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMicrophone,faPlay } from '@fortawesome/pro-solid-svg-icons';

interface UserMicAudioSoupCentral {
    state:UserMicAudioSoupCentralState;
    props:UserMicAudioSoupCentralProps;
    closeCallback(userPseudo:string,streamname:string,flashChatSessionID:string):void;
    checkCounter:number;
    sizeChangeTrackCounter:number;
    dragStartX:number;
    dragStartY:number;
    cam2CamPollIntervalID:number;
    audioElementContainer:any;
    sysMessage(msg:string):void;
    logBroadcastAction(action:string):void;
    index:number;
    checkUserMicTimerEnabled:boolean;
    checkUserMicTimer:NodeJS.Timer;
    audioConsumer: types.Consumer;
    mySignaling: SignalingInterface;
    device: Device;
    recvTransport: types.Transport|null;
    videoConsumer: types.Consumer;
    girlPeerId: string
    audioElementRef: React.RefObject<HTMLAudioElement>;
}

interface UserMicAudioSoupCentralState {
    userPseudo:string;
    peerId:string,
    stateMsg:string;
    flashChatSessionID:string;
    divTop:number;
    divLeft:number;
    opacity:string;
    initialized: boolean;
    debugmsg: string;
    connstate: string;
    peers: any[];
}

interface UserMicAudioSoupCentralProps {
    userPseudo:string,
    userPeerId:string,
    flashChatSessionID:string,
    closeCallback(userPseudo:string,streamname:string,flashChatSessionID:string):void,
    sysMessage(msg:string):void,
    index:number,
    signaling: SignalingInterface,
    device: Device,
    recvTransport: types.Transport|null,
    girlPeerId: string,
    logBroadcastAction(msg:string):void
}

class UserMicAudioSoupCentral extends React.Component {
    static contextType = AppContext;

    constructor(props:UserMicAudioSoupCentralProps) {
        super(props);

        this.state = {
            userPseudo: props.userPseudo,
            peerId: props.userPeerId,
            stateMsg: '',
            flashChatSessionID: props.flashChatSessionID,
            divTop: 110 + (props.index * 20),
            divLeft: 10 + (props.index * 20),
            opacity: "1.0",
            initialized: false,
            debugmsg: '',
            connstate: 'Not initialized',
            peers: []
        };

        library.add(faMicrophone,faPlay);

        this.closeCallback = props.closeCallback;
        this.checkCounter = 0;
        this.sizeChangeTrackCounter = 0;

        this.dragStartX = 0;
        this.dragStartY = 0;
        this.cam2CamPollIntervalID = 0;
        this.audioElementContainer = React.createRef();
        this.sysMessage = props.sysMessage.bind(this);
        this.logBroadcastAction = props.logBroadcastAction.bind(this);
        this.index = props.index;

        this.mySignaling = props.signaling;
        this.device = props.device;
        this.recvTransport = props.recvTransport;
        this.girlPeerId = props.girlPeerId;

        this.audioElementRef = React.createRef();
    }

    componentDidMount() {
        if(!this.checkUserMicTimerEnabled) {
            this.checkUserMicTimer = setInterval(this.checkUserMicStatus.bind(this), 250);
            this.checkUserMicTimerEnabled = true;
        }

        setTimeout(this.init.bind(this), 3000);
    }

    componentWillUnmount() {
        if(this.checkUserMicTimerEnabled) {
            clearInterval(this.checkUserMicTimer);
        }

        try {
            if(this.audioConsumer) {
                this.audioConsumer.close();
            }
        } catch(e:any) {
            console.log("Error closing audioConsumer: " + e);
        }
    }

    sync = async() => {
        if (this.state.initialized) {
            const mySyncResponse:SyncResponse = await this.mySignaling.requestSync();
            if (mySyncResponse) {
                if(mySyncResponse.error) {
                    this.setState({debugmessage: console.error(mySyncResponse.error.toString())});
                } else {
                    this.setState({peers: mySyncResponse.peers});
                }
            }
        }
    }

    uuidv4():string {
        return ('111-111-1111').replace(/[018]/g, () =>
               (crypto.getRandomValues(new Uint8Array(1))[0] & 15).toString(16));
    }

    init = async() => {
        this.setState({initializing: true});

        // Check whether we can produce video to the router.
        if (this.device.loaded)
        { 
            this.setState({debugmsg: 'device loaded'});
        } else {
            console.warn('device not loaded');
            this.setState({debugmsg: 'device not loaded'});
            // Abort next steps.
            return;
        }

        this.setState({initialized: true});

        this.setState({debugmsg: 'trying to consume audio'});

        this.logBroadcastAction("Trying to consume usermic audio " + this.state.peerId + " from soupserver " + this.mySignaling.soupServer);

        // this.state.peerId is the target peerId of the user we want to consume audio from
        let {producerId,id,kind,rtpParameters,type,producerPaused,error} = await this.mySignaling.request('recv-track', {
            peerId: this.girlPeerId,
            mediaTag: "cam-audio",
            mediaPeerId: this.state.peerId,
            rtpCapabilities: this.device.rtpCapabilities
        });

        if(typeof(error) != 'undefined') {
            if(error) {
                if(error !== '') {
                    console.error("Error receiving usermic consumer from soupserver: " + error);
                    this.setState({debugmsg: "Error receiving usermic consumer from soupserver: " + error});
                    this.setState({stateMsg: "Consume Error"});
                    this.logBroadcastAction("Error consuming usermic audio " + this.state.peerId + " from soupserver " + this.mySignaling.soupServer + " : " + error);
                    setTimeout(this.init.bind(this), 6000);
                    return;
                }
            }
        }

        this.setState({debugmsg: "got consumer params"});

        console.log("received consumerParameters - id:" + id);
        console.log("received consumerParameters - producerId:" + producerId);
        console.log("received consumerParameters - kind:" + kind);
        console.log("received consumerParameters - type:" + type);
        console.log("received consumerParameters - producerPaused:" + producerPaused);
        console.log("received consumerParameters: " + JSON.stringify(rtpParameters));

        this.logBroadcastAction("Continuing to consume usermic audio " + this.state.peerId + " from soupserver " + this.mySignaling.soupServer);

        try {
            if(this.recvTransport) {
                this.audioConsumer = await this.recvTransport.consume({
                    id: id,
                    producerId: producerId,
                    rtpParameters: rtpParameters,
                    kind: kind,
                    appData: { mediaTag: "cam-audio", mediaPeerId: this.state.peerId }
                });
                this.mySignaling.request('resume-consumer', { consumerId: id });
    
                if(this.audioElementRef.current) {
                    this.audioElementRef.current.srcObject = new MediaStream([this.audioConsumer.track]);
                    this.logBroadcastAction("Trying to play usermic audio " + this.state.peerId + " from soupserver " + this.mySignaling.soupServer);
                    this.audioElementRef.current.play();
                } else {
                    this.setState({debugmsg: "no audioElementRef"});
                    console.error("no audioElementRef");
                }
            }
            
        } catch(e:any) {
            this.setState({debugmsg: "ConsumeException: " + e.toString() + "|" + producerId + "|" + id + "|" + kind + "|" + type});
            this.setState({stateMsg: "Consume Exception"});
            this.logBroadcastAction("Failed to play usermic audio " + this.state.peerId + " from soupserver " + this.mySignaling.soupServer + " : " + e.toString());
        }
    }

    handleDrag(ev: React.DragEvent<HTMLDivElement>):void {
        const target = (ev.target as HTMLDivElement);
        this.dragStartX = ev.pageX - target.offsetLeft;
        this.dragStartY = ev.pageY - target.offsetTop;
        this.setState({opacity: "0.4"});
    }

    handleDragEnd(ev: React.DragEvent<HTMLDivElement>):void {
        this.setState({divLeft: ev.pageX - this.dragStartX});
        this.setState({divTop: ev.pageY - this.dragStartY});
        this.setState({opacity: "1.0"});
    }

    handleClose() {
        this.closeCallback(this.state.userPseudo,this.state.peerId,this.state.flashChatSessionID);
    }

    checkUserMicStatus() {
        let audioEl:HTMLAudioElement|null = this.audioElementRef.current;
        
        if(audioEl !== null) {
            if(audioEl.readyState === 4) {
                this.setState({stateMsg: "Playing"});
            }
        } else {
            this.setState({stateMsg: "No AudioElement"});
        }
    }

    render() {
        return(
            <div className="userMicAudio" draggable={true} onDragStart={this.handleDrag.bind(this)} onDragEnd={this.handleDragEnd.bind(this)} ref={this.audioElementContainer} style={{opacity: this.state.opacity,top: this.state.divTop,left: this.state.divLeft, height: "60px", paddingTop: "20px"}}>
                <div className="userMicAudioLabel">
                    <span className="userMicAudioLabelSpan">
                        <FontAwesomeIcon icon={["fas", "microphone"]} />&nbsp;
                        {this.state.connstate == 'connected' ? <span className='oncircle'></span> : <span className='offcircle'></span>}&nbsp;
                        {this.state.userPseudo} UserMic
                        {this.state.stateMsg == 'playing' ? <FontAwesomeIcon icon={["fas", "play"]} /> : this.state.stateMsg}
                    </span>
                    <div className="userMicAudioLabelButtons">
                        <button onClick={this.handleClose.bind(this)} className="userMicCloseButton">&#xE8BB;</button>
                    </div>
                </div>
                <p style={{fontSize: "8pt",display: "none"}}>{this.state.debugmsg}</p>
                <audio ref={this.audioElementRef} controls></audio>
            </div>
        )
    }
}

export default UserMicAudioSoupCentral;