import React from 'react';
import AppContext from './AppContext';
import {
    LivelyCallContext,
    LivelyPlayerUiContext,
    PlayerUiState,
    VideoClient,
    types,
  } from "@livelyvideo/video-client-web";

  

  import {
    ControlBar,
    MediaContainer,
    PlayerAudioButton,
    PlayerBitrateButton,
    PlayerFullscreenButton,
    PlayerGetSoundButton,
    PlayerOverlayButton,
    PlayerPlayButton,
    PlayerVideo,
    PlayerVolumeRange,
  } from "@livelyvideo/video-client-web";

interface Cam2CamVideoLively {
    state:Cam2CamVideoLivelyState;
    props:Cam2CamVideoLivelyProps;
    closeCallback(userPseudo:string,streamname:string,flashChatSessionID:string):void;
    checkCounter:number;
    sizeChangeTrackCounter:number;
    dragStartX:number;
    dragStartY:number;
    cam2CamPollIntervalID:number;
    videoElementContainerRef:React.RefObject<HTMLDivElement>;
    sysMessage(msg:string):void;
    index:number;
    checkCam2CamStatusTimerEnabled:boolean;
    checkCam2CamStatusTimer:NodeJS.Timer;
}

interface Cam2CamVideoLivelyState {
    userPseudo:string;
    callId:string;
    stateMsg:string;
    flashChatSessionID:string;
    vc:types.VideoClient | null;
    playerUI:PlayerUiState | null;
    manifestCheckState:string;
    manifestCheckCount:number;
    manifestFailCount:number;
    orderIndex:number;
    videoWidth:number;
    videoHeight:number;
    divTop:number;
    divLeft:number;
    opacity:string;
    mobileCam:boolean;
}

interface Cam2CamVideoLivelyProps {
    userPseudo:string,
    callId:string,
    flashChatSessionID:string,
    closeCallback(userPseudo:string,streamname:string,flashChatSessionID:string):void,
    sysMessage(msg:string):void,
    index:number,
    playerUI:PlayerUiState | null;
    orderIndex:number;
}

class Cam2CamVideoLively extends React.Component {
    static contextType = AppContext;

    constructor(props:Cam2CamVideoLivelyProps) {
        super(props);

        this.state = {
            userPseudo: props.userPseudo,
            callId: props.callId,
            stateMsg: '',
            flashChatSessionID: props.flashChatSessionID,
            vc:null,
            playerUI:props.playerUI,
            manifestCheckState:"",
            manifestCheckCount:0,
            manifestFailCount:0,
            orderIndex: props.orderIndex,
            divTop: 180 + (props.orderIndex * 20),
            divLeft: 10 + (props.orderIndex * 20),
            videoWidth: 240,
            videoHeight: 180,
            opacity: "1.0",
            mobileCam: false
        };

        this.closeCallback = props.closeCallback;
        this.checkCounter = 0;
        this.sizeChangeTrackCounter = 0;

        this.dragStartX = 0;
        this.dragStartY = 0;
        this.cam2CamPollIntervalID = 0;
        this.sysMessage = props.sysMessage.bind(this);
        this.index = props.index;
        this.videoElementContainerRef = React.createRef();
    }

    componentDidMount() {
        if(this.state.callId !== "" && this.state.playerUI === null) {
            this.fetchToken(this.state.callId,this.state.userPseudo);
        } else {
            if(!this.checkCam2CamStatusTimerEnabled) {
                this.checkCam2CamStatusTimer = setInterval(this.checkCam2CamStatus.bind(this), 250);
                this.checkCam2CamStatusTimerEnabled = true;
            }
        }
    }

    handleDrag(ev: React.DragEvent<HTMLDivElement>):void {
        const target = (ev.target as HTMLDivElement);
        this.dragStartX = ev.pageX - target.offsetLeft;
        this.dragStartY = ev.pageY - target.offsetTop;
        this.setState({opacity: "0.4"});
    }

    handleDragEnd(ev: React.DragEvent<HTMLDivElement>):void {
        this.setState({divLeft: ev.pageX - this.dragStartX});
        this.setState({divTop: ev.pageY - this.dragStartY});
        this.setState({opacity: "1.0"});
    }

    componentWillUnmount() {
        if(this.checkCam2CamStatusTimerEnabled) {
            clearInterval(this.checkCam2CamStatusTimer);
        }
        if (this.state.playerUI != null) {
            this.state.playerUI.dispose();
            this.setState({playerUI:null});
        }
        if (this.state.vc != null) {
            this.state.vc.dispose();
            this.setState({vc:null});
        }
    }

    handleShrink() {
        let videoWidth:number = 0;
        let videoHeight:number = 0;
        if(this.sizeChangeTrackCounter === 0) {
            videoWidth = 220;
            if(this.state.mobileCam) {
                videoHeight = Math.round(videoWidth * 1.33);
            } else {
                videoHeight = Math.round(videoWidth * 0.75);
            }
        } else {
            videoWidth = this.state.videoWidth - 20;
            if(this.state.mobileCam) {
                videoHeight = Math.round(videoWidth * 1.33);
            } else {
                videoHeight = Math.round(videoWidth * 0.75);
            }
        }
        this.sizeChangeTrackCounter--;
        this.setState({videoWidth: videoWidth});
        this.setState({videoHeight: videoHeight});
        this.sysMessage("c2c shrink " + this.sizeChangeTrackCounter + " | newwidth: " + videoWidth);
    }

    handleEnlarge() {
        let videoWidth:number = 0;
        let videoHeight:number = 0;
        if(this.sizeChangeTrackCounter === 0) {
            videoWidth = 260;
            if(this.state.mobileCam) {
                videoHeight = Math.round(videoWidth * 1.33);
            } else {
                videoHeight = Math.round(videoWidth * 0.75);
            }
        } else {
            videoWidth = this.state.videoWidth + 20;
            if(this.state.mobileCam) {
                videoHeight = Math.round(videoWidth * 1.33);
            } else {
                videoHeight = Math.round(videoWidth * 0.75);
            }
        }
        this.sizeChangeTrackCounter++;
        this.setState({videoWidth: videoWidth});
        this.setState({videoHeight: videoHeight});
        this.sysMessage("c2c enlarge " + this.sizeChangeTrackCounter + " | newwidth: " + videoWidth);
    }

    handleClose() {
        this.closeCallback(this.state.userPseudo,this.state.callId,this.state.flashChatSessionID);
    }

    buildManifestUrl(callId:string,token:string) {
        let manifestUrl = "https://guppy-prod-euw1d-manifest2.generflow.com/live/" + callId + ".json?accessToken=" + encodeURIComponent(token) + "&time=" + encodeURIComponent(new Date().getMilliseconds());
        this.setState({manifestUrl:manifestUrl});

        if (this.state.vc == null) {
            const yourDomain:string = "https://guppy-prod-euw1d.generflow.com";
            const axios = require('axios').default;
            let callId:string = this.state.callId;
            let pseudo = this.state.userPseudo;
            let tokenURL:string;
            tokenURL = "https://streamauth.guppy.live/FetchPlayerToken.aspx?SessionID=" + encodeURIComponent(callId) + "&Pseudo=" + encodeURIComponent(pseudo);

            let myTokenRefresher:any = axios.get(tokenURL);

            myTokenRefresher.toJSON = () => {
                return JSON.stringify(this.refreshToken.bind(this));
            }

            const opts: types.VideoClientOptions = {
              livelyEndpoints: [yourDomain],
              token: myTokenRefresher
            };
            const newVc = new VideoClient(opts);
            this.setState({vc: newVc});
            this.setState({stateMsg:"Created VideoClient"});

            let playerVolume:number = 0;
            let playerMuted:boolean = true;

            const options:Partial<types.PlayerOptions> = {
                autoPlay: true,
                volume: playerVolume,
                muted: playerMuted
            };

            const player = newVc.requestPlayer(manifestUrl, options);
            this.setState({stateMsg:"Created Player"});

            this.setState({playerUI: new PlayerUiState(player)});

            if(!this.checkCam2CamStatusTimerEnabled) {
                this.checkCam2CamStatusTimer = setInterval(this.checkCam2CamStatus.bind(this), 250);
                this.checkCam2CamStatusTimerEnabled = true;
            }
        }
    }

    checkCam2CamStatus() {
        if(this.props.playerUI !== null && this.state.playerUI === null) {
            this.setState({playerUI:this.props.playerUI});
        }

        if(this.state.playerUI !== null) {
            let myPlayer:PlayerUiState = this.state.playerUI as PlayerUiState;

            if(myPlayer.videoElement) {
                if(myPlayer.videoElement.current) {
                    myPlayer.videoElement.current.muted = true;
                    if(myPlayer.videoElement.current.style) {
                        myPlayer.videoElement.current.style.objectFit = "contain";
                    }

                    let videoWidth:number = 0;
                    let videoHeight:number = 0;

                    if(myPlayer.videoElement.current.videoHeight > myPlayer.videoElement.current.videoWidth) {
                        if(!this.state.mobileCam) {
                            videoWidth = this.state.videoWidth;
                            videoHeight = Math.round(videoWidth * 1.33);
                            this.setState({videoWidth: videoWidth});
                            this.setState({videoHeight: videoHeight});
                        }
                        this.setState({mobileCam: true});
                    } else {
                        if(this.state.mobileCam) {
                            videoWidth = this.state.videoWidth;
                            videoHeight = Math.round(videoWidth * 0.75);
                            this.setState({videoWidth: videoWidth});
                            this.setState({videoHeight: videoHeight});
                        }
                        this.setState({mobileCam: false});
                    }
                    this.setState({stateMsg: "Playing " + myPlayer.videoElement.current.videoWidth + "x" + myPlayer.videoElement.current.videoHeight});
                } else {
                    this.setState({stateMsg: "No VideoElement"});
                }
            } else {
                this.setState({stateMsg: "No VideoElement"});
            }
        } else {
            this.setState({stateMsg: "No PlayerUI"});
        }
    }

    refreshToken():Promise<string> {
        let callId:string = this.state.callId;
        let pseudo = this.state.userPseudo;
        const axios = require('axios').default;
        let url:string;

        url = "https://streamauth.guppy.live/FetchPlayerToken.aspx?SessionID=" + encodeURIComponent(callId) + "&Pseudo=" + encodeURIComponent(pseudo);

        if(typeof(console) != "undefined") {
            console.log("guppyplayer -> refreshTokenURL: '" + url + "'");
        }
        return axios.get(url);
    }

    fetchToken = async(callId:string,pseudo:string) => {
        const axios = require('axios').default;
        let url:string;

        this.setState({manifestCheckState: "Fetching Token"});

        this.setState({stateMsg: "Fetching Token"});

        url = "https://streamauth.guppy.live/FetchPlayerToken.aspx?SessionID=" + encodeURIComponent(callId) + "&Pseudo=" + encodeURIComponent(pseudo);

        try {
            if(typeof(console) != "undefined") {
                console.log("guppyplayer -> fetchTokenURL: '" + url + "'");
            }
            const response = await axios.get(url);
            if(typeof(console) != "undefined") {
                console.log("guppyplayer -> token: '" + response.data.token + "'");
            }
            
            this.setState({stateMsg: "Initializing Player"});
            this.buildManifestUrl(callId,response.data.token);
        } catch (error) {
            console.error('ERROR:');
            console.error(error);
            this.setState({stateMsg: "Token Error"});
            this.setState({manifestCheckState: "Token Error"});
            this.setState({manifestCheckCount: this.state.manifestFailCount + 1});
            if(this.state.manifestFailCount <= 6) {
                this.setState({manifestUrl: ""});
                //setTimeout(this.checkBroadcast.bind(this),4000);
            } else {
                this.setState({stateMsg: "Token Failed"});
                this.setState({manifestCheckState: "Token Error Permanent"});
            }
        }
    }

    render() {
        return(
            <div draggable={true} onDragStart={this.handleDrag.bind(this)} onDragEnd={this.handleDragEnd.bind(this)} ref={this.videoElementContainerRef} style={{opacity: this.state.opacity,top: this.state.divTop,left: this.state.divLeft,width: this.state.videoWidth,height: this.state.videoHeight}} className="cam2camVideo">
                <div className="cam2camVidLabel">
                    <span>Cam2Cam {this.state.stateMsg} {this.state.userPseudo}</span>
                    <div className="cam2camVidLabelButtons">
                        <button className="cam2camShrinkButton" onClick={this.handleShrink.bind(this)}>&#xF16E;</button><button onClick={this.handleEnlarge.bind(this)} className="cam2camEnlargeButton">&#xF16D;</button><button onClick={this.handleClose.bind(this)} className="cam2camCloseButton">&#xE8BB;</button>
                    </div>                    
                </div>
                { this.state.playerUI !== null ? 
                <LivelyPlayerUiContext.Provider value={this.state.playerUI}>
                    <MediaContainer>
                        <PlayerVideo />
                    </MediaContainer>
                </LivelyPlayerUiContext.Provider> : null }
            </div>
        )
    }
}

export default Cam2CamVideoLively;