import React from 'react';
import AppContext from './AppContext';
import Button from 'react-bootstrap/Button';
import { LovenseDevice } from './lovenseSdkTypes';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBattery } from '@fortawesome/pro-solid-svg-icons';

interface LovenseDeviceDisplayProps {
    selectCallback: (device:LovenseDevice) => void;
    language: string;
    device: LovenseDevice;
    selectable: boolean;
}

interface LovenseDeviceDisplay {
    state: LovenseDeviceDisplayState;
    props: LovenseDeviceDisplayProps;
}

interface LovenseDeviceDisplayState {
    language: string;
    device: LovenseDevice;
}

class LovenseDeviceDisplay extends React.Component {
    static contextType = AppContext;

    constructor(props:LovenseDeviceDisplayProps) {
        super(props);

        this.state = {
            language: props.language,
            device: props.device
        }

        library.add(faBattery);
    }

    componentDidMount() {

    }

    selectToy() {
        this.props.selectCallback(this.state.device);
    }

    getText(exp:string) {
        if(this.state.language === "de") {
            if(exp === "Select") {
                return "Wähle";
            }

            return exp;
        } else {
            if(exp === "Select") {
                return "Select";
            }

            return exp;
        }
    }

    render() {
        return (
            <tr>
                <td className="lovense-device-display-name">{this.state.device.name}</td>
                <td className="lovense-device-display-nickname">{this.state.device.nickname}</td>
                <td className="lovense-device-display-connected">{this.state.device.connected ? "connected" : null}</td>
                <td className="lovense-device-display-battery"><FontAwesomeIcon icon={["fas", "battery"]}></FontAwesomeIcon> {this.state.device.battery}</td>
                <td>{this.props.selectable ? <Button variant="primary" size='sm' onClick={this.selectToy.bind(this)}>{this.getText("Select")}</Button> : null }</td>
            </tr>
        );
    }
}

export default LovenseDeviceDisplay;