import React from 'react';

export class ChatOptionsData {
  constructor() {
    this.price = 0;
  }

  public price: number;
}

export default React.createContext({loginData: null,chatOptions: null,language: "de",encoderUi: null,streamKey: '',enableSoup1: true,enableSoup2: true,enableSoup3: false});