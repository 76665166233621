import React, { FormEvent } from 'react';
import AppContext from './AppContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

export interface ChatOptionsModalProps {
    closeCallback: () => void;
    updateCallback: () => void;
}

interface ChatOptionsModal {
    state: ChatOptionsModalState;
    props: ChatOptionsModalProps;
    closeCallback: () => void;
    updateCallback: () => void;
    drpPriceRef: React.RefObject<HTMLSelectElement>|null;
}

interface ChatOptionsModalState {
    estatus:string;
    formstatus:string;
    welcomeMessage:string;
    vCard:string;
    vCardAnyone:string;
    autoEnableSound:boolean;
    allowFreeChat:boolean;
    offerFreeSound:boolean;
    autoSoundAccept:boolean;
    autoCam2CamAccept:boolean;
    autoDildoAccept:boolean;
    autoUserMicAccept:boolean;
    autotext1:string;
    autotext2:string;
    autotext3:string;
    autotext4:string;
    autotext5:string;
    errorMessage:string;
    noUserEnterSound:boolean;
    noUserLeaveSound:boolean;
    noChatMessageSound:boolean;
    noFeatureRequestSound:boolean;
    price: number;
    maxPrice: number;
    multiPrivatePrice: number;
}

class ChatOptionsModal extends React.Component {
    static contextType = AppContext;

    constructor(props:ChatOptionsModalProps) {
        super(props);
        this.closeCallback = props.closeCallback;
        this.updateCallback = props.updateCallback;

        this.state = {
            estatus: "",
            formstatus: "loading",
            price: 1,
            maxPrice: 2,
            multiPrivatePrice: 2,
            welcomeMessage: "",
            vCard: "",
            vCardAnyone: "",
            autoEnableSound: false,
            allowFreeChat: false,
            offerFreeSound: false,
            autoSoundAccept: false,
            autoCam2CamAccept: false,
            autoDildoAccept: false,
            autoUserMicAccept: false,
            autotext1: "",
            autotext2: "",
            autotext3: "",
            autotext4: "",
            autotext5: "",
            errorMessage: "",
            noUserEnterSound: false,
            noUserLeaveSound: false,
            noChatMessageSound: false,
            noFeatureRequestSound: false
        }

        this.drpPriceRef = React.createRef();
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({formstatus: "loading"});
    
        var jsonurl = "https://guppy.site/chatSetupDataGet.aspx?ProducerID=" + encodeURIComponent(this.context.loginData.producerID);
    
        this.setState({loading: true});
    
        const axios = require('axios').default;
        const cryptoRandomString = require('crypto-random-string');
    
        axios.get(jsonurl).then((response:any) => {
            this.setState({loading: false});
            let data = response.data;

            if(data.result === "ok") {
                this.setState({formstatus: "ready"});

                this.setState({
                    price: data.price,
                    welcomeMessage: data.welcomeMessage,
                    vCardForAdults: data.vCardForAdults,
                    vCardForAnyone: data.vCardForAnyone,
                    maxPrice: data.maxPrice,
                    allowFreeChat: data.allowFreeChat,
                    allowMultiPrivate: data.allowMultiPrivate,
                    maxPriceMultiPrivate: data.maxPriceMultiPrivate,
                    privatePrice: data.privatePrice,
                    autotext1: data.autotext1,
                    autotext2: data.autotext2,
                    autotext3: data.autotext3,
                    autotext4: data.autotext4,
                    autotext5: data.autotext5,
                    autoSoundAccept: data.autoSoundAccept === "True" ? true : false,
                    autoDildoAccept: data.autoDildoAccept === "True" ? true : false,
                    autoUserMicAccept: data.autoUserMicAccept === "True" ? true : false,
                    autoEnableSound: data.autoEnableSound === "True" ? true : false,
                    offerFreeSound: data.offerFreeSound === "True" ? true : false,
                    autoCam2CamAccept: data.autoCam2CamAccept === "True" ? true : false,
                    noUserEnterSound: data.noUserEnterSound,
                    noUserLeaveSound: data.noUserLeaveSound,
                    noChatMessageSound: data.noChatMessageSound,
                    noFeatureRequestSound: data.noFeatureRequestSound
                });

                if(this.drpPriceRef !== null) {
                    if(this.drpPriceRef.current !== null) {
                        this.drpPriceRef.current.value = data.price;
                    }
                }
            } else {
                this.setState({formstatus: "error"});
                this.setState({eStatus: "Error loading data: " + data.error});
            }
        })
        .catch((error:any) => {
          this.setState({errorMessage: error.toString()});
          this.setState({loading: false});
        });
    }

    handleSubmit() {
        console.log("Submit clicked");
    
        var produderid = "";
    
        var jsonurl = "https://guppy.site/chatSetupDataSave.aspx?ProducerID=" + encodeURIComponent(this.context.loginData.producerID);

        jsonurl += "&WelcomeMessage=" + encodeURIComponent(this.state.welcomeMessage);
        jsonurl += "&vCardForAdults=" + encodeURIComponent(this.state.vCard);
        jsonurl += "&vCardForAnyone=" + encodeURIComponent(this.state.vCardAnyone);
        jsonurl += "&Price=" + encodeURIComponent(this.state.price);
        jsonurl += "&MultiPrivatePrice=" + encodeURIComponent(this.state.multiPrivatePrice);

        jsonurl += "&autotext1=" + encodeURIComponent(this.state.autotext1);
        jsonurl += "&autotext2=" + encodeURIComponent(this.state.autotext2);
        jsonurl += "&autotext3=" + encodeURIComponent(this.state.autotext3);
        jsonurl += "&autotext4=" + encodeURIComponent(this.state.autotext4);
        jsonurl += "&autotext5=" + encodeURIComponent(this.state.autotext5);

        jsonurl += "&autoEnableSound=" + (this.state.autoEnableSound ? "1" : "0");
        jsonurl += "&autoSoundAccept=" + (this.state.autoSoundAccept ? "1" : "0");

        jsonurl += "&autoDildoAccept=" + (this.state.autoDildoAccept ? "1" : "0");
        jsonurl += "&autoUserMicAccept=" + (this.state.autoUserMicAccept ? "1" : "0");
        jsonurl += "&offerFreeSound=" + (this.state.offerFreeSound ? "1" : "0");
        jsonurl += "&autoCam2CamAccept=" + (this.state.autoCam2CamAccept ? "1" : "0");
        jsonurl += "&noUserEnterSound=" + (this.state.noUserEnterSound ? "1" : "0");
        jsonurl += "&noUserLeaveSound=" + (this.state.noUserLeaveSound ? "1" : "0");
        jsonurl += "&noChatMessageSound=" + (this.state.noChatMessageSound ? "1" : "0");
        jsonurl += "&noFeatureRequestSound=" + (this.state.noFeatureRequestSound ? "1" : "0");
    
        this.setState({loading: true});
    
        const axios = require('axios').default;
        const cryptoRandomString = require('crypto-random-string');
    
        axios.get(jsonurl).then((response:any) => {
            let data = response.data;
            console.log("login result: " + data.result + " | error: " + data.error);
            if(data.result === "ok") {
                
            } else {
                this.setState({errorMessage: data.error});
                this.setState({loading: false});
            }
        })
        .catch((error:any) => {
          this.setState({errorMessage: error.toString()});
          this.setState({loading: false});
        });
    }

    handlePriceChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({price: event.target.value});
    }

    handleWelcomeMessageChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({welcomeMessage: event.target.value});
    }

    handleVCardForAdultsChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({vCard: event.target.value});
    }

    handleVCardForAnyoneChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({vCardAnyone: event.target.value});
    }

    handleAutoEnableSoundChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autoEnableSound: event.target.checked});
    }

    handleAutoAcceptSoundChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autoSoundAccept: event.target.checked});
    }

    handleAutoAcceptDildoChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autoDildoAccept: event.target.checked});
    }

    handleAutoAcceptCam2CamChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autoCam2CamAccept: event.target.checked});
    }

    handleAutoAcceptUsermicChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autoUserMicAccept: event.target.checked});
    }

    handleNoUserEnterSoundChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({noUserEnterSound: event.target.checked});
    }

    handleNoUserLeaveSoundChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({noUserLeaveSound: event.target.checked});
    }

    handleNoChatMessageSoundChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({noChatMessageSound: event.target.checked});
    }

    handleNoFeatureRequestSoundChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({noFeatureRequestSound: event.target.checked});
    }

    handleAutoText1Change(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autotext1: event.target.value});
    }

    handleAutoText2Change(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autotext2: event.target.value});
    }

    handleAutoText3Change(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autotext3: event.target.value});
    }

    handleAutoText4Change(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autotext4: event.target.value});
    }

    handleAutoText5Change(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autotext5: event.target.value});
    }

    getText(exp:string) {
        if(this.context.language === "de") {
            if(exp === "AutoAccept Sound") {
                return "Sound automatisch annehmen";
            }
            if(exp === "AutoAccept Dildo/Lovense") {
                return "Dildo/Lush automatisch annehmen";
            }
            if(exp === "AutoAccept Usermic") {
                return "Usermikrofon automatisch annehmen";
            }
            if(exp === "AutoAccept Cam2Cam") {
                return "Cam-2-Cam automatisch annehmen";
            }
            if(exp === "AutoTextsHint") {
                return "Hier kann man sich Autotexte anlegen, die dann automatisch erscheinen, wenn man im Eingefeld für Chatnachrichten # und die dazugehörige Ziffer eintippt";
            }
            if(exp === "Price") {
                return "Preis";
            }
            if(exp === "Offer sound for free") {
                return "Die User können den Sound kostenlos aktivieren";
            }
            if(exp === "Sound free") {
                return "Sound kostenlos";
            }
            if(exp === "AutoEnable Sound") {
                return "Sound gleich für User anbieten";
            }
            if(exp === "WelcomeMessage") {
                return "Automatische Chatnachricht an User beim Betreten";
            }
            if(exp === "vCardForAdults") {
                return "InfoText für altersverifizierte User";
            }
            if(exp === "vCardForAnyone") {
                return "InfoText soft";
            }
            if(exp === "Submit") {
                return "Abschicken";
            }
            if(exp === "NoUserEnterSound") {
                return "Kein Hinweiston wenn User den Chat betritt";
            }
            if(exp === "NoUserLeaveSound") {
                return "Kein Hinweiston wenn User den Chat verlässt";
            }
            if(exp === "NoChatMessageSound") {
                return "Kein Hinweiston wenn User bei Chatnachricht";
            }
            if(exp === "NoFeatureRequestSound") {
                return "Kein Hinweiston wenn Feature angefragt wird";
            }
            if(exp === "ChatOptionsFormSubtitle") {
                return "Du bist als {0} eingeloggt, nimm hier Deine Einstellungen vor, mit denen Du online gehen möchtest";
            }
            if(exp === "KostenloserBasischat") {
                return "Kostenloser Basischat";
            }
        } else if(this.context.language === "en") {
            if(exp === "AutoTextsHint") {
                return "These auto-texts help you reduce your typing, you can fill in the auto-texts as confgured below by entering # and the corresponding number in the chat input textbox";
            }
            if(exp === "Willkommensnachricht im Chat") {
                return "Welcome message for users entering your room";
            }
            if(exp === "AutoEnable Sound") {
                return "Allow Users to enable Sound";
            }
            if(exp === "WelcomeMessage") {
                return "Automatic chat message to users entering chat";
            }
            if(exp === "vCardForAdults") {
                return "InfoText for age-verified users (can contain porn-language)";
            }
            if(exp === "vCardForAnyone") {
                return "InfoText for non age-verified users (soft, no porn-language)";
            }
            if(exp === "Price") {
                return "Price";
            }
            if(exp === "NoUserEnterSound") {
                return "Disable notification sound on user enter";
            }
            if(exp === "NoUserLeaveSound") {
                return "Disable notification sound on user leave";
            }
            if(exp === "NoChatMessageSound") {
                return "Disable notification sound on chat message";
            }
            if(exp === "NoFeatureRequestSound") {
                return "Disable not. sound on feature request";
            }
            if(exp === "ChatOptionsFormSubtitle") {
                return "You are logged in as {0}, here you can set options and settings for your chat session";
            }
            if(exp === "KostenloserBasischat") {
                return "Free chat base functionality";
            }
        }
    
        return exp;
    }

    render() {
        return(
            <Modal show={true} onHide={this.closeCallback.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.getText("Optionen")}</Modal.Title>
                </Modal.Header>
          
                <Modal.Body>
                    { this.state.estatus === "" ? null : <Alert variant="danger">{this.state.estatus}</Alert>}
                    { this.state.formstatus === "ready" ?
                    <Form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="row">
                            <div className="col-6">
                                <Form.Group>
                                    <Form.Label>{this.getText("Price")}</Form.Label>
                                    <Form.Control id="drpPrice" ref={this.drpPriceRef} as="select" onChange={this.handlePriceChange.bind(this)}>
                                        { this.state.allowFreeChat ? <option value="0">{this.getText("KostenloserBasischat")}</option> : null }
                                        <option value="1">1 Coin/min</option>
                                        <option value="2">2 Coins/min</option>
                                        { this.state.maxPrice > 2 ? <option value="3">3 Coins/min</option> : null }
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group>
                                    <Form.Check type="checkbox" label={this.getText("AutoEnable Sound")} checked={this.state.autoEnableSound} onChange={this.handleAutoEnableSoundChange.bind(this)} />
                                </Form.Group>
                            </div>
                        </div>
                        <Form.Group>
                            <Form.Label>{this.getText("WelcomeMessage")}</Form.Label>
                            <Form.Control type="text" placeholder={this.getText("WelcomeMessage")} value={this.state.welcomeMessage} onChange={this.handleWelcomeMessageChange.bind(this)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{this.getText("vCardForAdults")}</Form.Label>
                            <Form.Control type="text" placeholder={this.getText("vCardForAdults")} value={this.state.vCard} onChange={this.handleVCardForAdultsChange.bind(this)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{this.getText("vCardForAnyone")}</Form.Label>
                            <Form.Control type="text" placeholder={this.getText("vCardForAnyone")} value={this.state.vCardAnyone} onChange={this.handleVCardForAnyoneChange.bind(this)} />
                        </Form.Group>

                        <div className="row">
                            <div className="col-6">
                            <Form.Group>
                                <Form.Check type="checkbox" label={this.getText("AutoAccept Sound")} checked={this.state.autoSoundAccept} onChange={this.handleAutoAcceptSoundChange.bind(this)} />
                            </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group>
                                    <Form.Check type="checkbox" label={this.getText("AutoAccept Dildo/Lovense")} checked={this.state.autoDildoAccept} onChange={this.handleAutoAcceptDildoChange.bind(this)} />
                                </Form.Group>  
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Form.Group>
                                    <Form.Check type="checkbox" label={this.getText("AutoAccept Cam2Cam")} checked={this.state.autoCam2CamAccept} onChange={this.handleAutoAcceptCam2CamChange.bind(this)} />
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group>
                                    <Form.Check type="checkbox" label={this.getText("AutoAccept UserMic")} checked={this.state.autoUserMicAccept} onChange={this.handleAutoAcceptUsermicChange.bind(this)} />
                                </Form.Group>
                            </div>
                        </div>

                        <hr></hr>

                        <div className="row">
                            <div className="col-6">
                                <Form.Group>
                                    <Form.Check type="checkbox" label={this.getText("NoUserEnterSound")} checked={this.state.noUserEnterSound} onChange={this.handleNoUserEnterSoundChange.bind(this)} />
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group>
                                    <Form.Check type="checkbox" label={this.getText("NoUserLeaveSound")} checked={this.state.noUserLeaveSound} onChange={this.handleNoUserLeaveSoundChange.bind(this)} />
                                </Form.Group>  
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Form.Group>
                                    <Form.Check type="checkbox" label={this.getText("NoChatMessageSound")} checked={this.state.noChatMessageSound} onChange={this.handleNoChatMessageSoundChange.bind(this)} />
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group>
                                    <Form.Check type="checkbox" label={this.getText("NoFeatureRequestSound")} checked={this.state.noFeatureRequestSound} onChange={this.handleNoFeatureRequestSoundChange.bind(this)} />
                                </Form.Group>
                            </div>
                        </div>

                        <Button variant="primary" type="submit" disabled={ this.state.formstatus === "ready" ? false : true }>{this.getText("Submit")}</Button>

                        <hr></hr>

                        <Form.Group>
                            <Form.Label>{this.getText("Autotext1")}</Form.Label>
                            <Form.Control type="text" placeholder={this.getText("Autotext1")} value={this.state.autotext1} onChange={this.handleAutoText1Change.bind(this)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{this.getText("Autotext2")}</Form.Label>
                            <Form.Control type="text" placeholder={this.getText("Autotext2")} value={this.state.autotext2} onChange={this.handleAutoText2Change.bind(this)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{this.getText("Autotext3")}</Form.Label>
                            <Form.Control type="text" placeholder={this.getText("Autotext3")} value={this.state.autotext3} onChange={this.handleAutoText3Change.bind(this)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{this.getText("Autotext4")}</Form.Label>
                            <Form.Control type="text" placeholder={this.getText("Autotext4")} value={this.state.autotext4} onChange={this.handleAutoText4Change.bind(this)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{this.getText("Autotext5")}</Form.Label>
                            <Form.Control type="text" placeholder={this.getText("Autotext5")} value={this.state.autotext5} onChange={this.handleAutoText5Change.bind(this)} />
                        </Form.Group>
                    </Form> : null
                    }
                    { this.state.formstatus === "loading" ? <div>{this.getText("Loading")}</div> : null }
                </Modal.Body>
            
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeCallback.bind(this)}>Close</Button>
                    <Button variant="primary" onClick={this.handleSubmit.bind(this)}>{this.getText("Speichern")}</Button>
                </Modal.Footer>
          </Modal>
        )
    }
}

export default ChatOptionsModal;