import { useEffect,useState,useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import {
  EncoderUiState,
  LivelyCallContext,
  LivelyEncoderUiContext,
  VideoClient,
  mediaController,
  types
} from "@livelyvideo/video-client-web";
import LoggerCore from "@livelyvideo/logger";
import AppContext from './AppContext';
import ChatInterface from './ChatInterface';
import { LocalStorageWorker } from './StorageHelper';

interface LoginData {
  producerID:string;
}
interface ChatOptionsData {
  // todo
}
interface ChatInterfaceParentProps {
  gotoOptions(): void;
  exitChat(): void;
}
interface PropsDictionary {
  [index: string]:any;
}

const ChatInterfaceParent = (props:ChatInterfaceParentProps) => {
    return (
      <ChatInterface exitChat={props.exitChat.bind(this)} gotoOptions={props.gotoOptions.bind(this)} />
    );
  };

  

  export default ChatInterfaceParent;